.form-error-text
  color: $red
  font-family: $family-sans-serif

.hamburger-bar
  background: url('/public/svg/icons/hamburger-menu-spacer-by-cbre.svg') no-repeat
  border: none
  height: 1rem
  width: 1rem
  margin-left: 1rem

.hamburger-bar-open
  width: 1rem
  height: 1rem
  margin-left: 1rem
  padding-bottom: 1.5rem
  background-color: transparent
  border: none

.hamburger-bar-open:before,
.hamburger-bar-open:after
  position: absolute
  left: 25px
  content: ' '
  height: 23px
  width: 2px
  background-color: $green-forest-dark

.hamburger-bar-open:before
  transform: rotate(45deg)

.hamburger-bar-open:after
  transform: rotate(-45deg)

.hamburger-bar-menu
  position: absolute
  top: 100%
  right: -25%
  z-index: 1

  a
    padding: 0.5rem 8rem
    background-color: $white
    box-shadow: 0 10px 6px -6px $grey-light
  a:first-child
    border-bottom: 1px solid $grey-lightest
