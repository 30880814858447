.program-stats

  .program-stat-content
    height: 100%
    padding: 1.5rem
    background-color: $white-bis
    box-shadow: 0 0.125rem 0.25rem 0 rgba($grey, 0.3)
    border-radius: $radius

  .program-stat-title
    color: $grey-darker
    font-size: $size-5

  .title, .subtitle
    word-break: initial

  .program-stat-value
    margin-bottom: 2.3rem

+tablet-only
  .program-stats
    display: flex
    align-items: flex-start
    flex-wrap: wrap
    padding: 0 4rem

    .program-stat-content
      width: 13rem
      min-height: 177.5px

    .program-stat-subtitle
      white-space: pre-wrap

    .subtitle
      font-size: $size-7

    .program-stat-title
      font-size: $size-6

    .program-stat-value
      font-size: $size-4

+mobile
  .program-stats
    margin-left: 5%

    .program-stat-content
      width: 90%
      min-height: 224.5px
