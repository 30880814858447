@font-face {
  font-family: Calibre Regular;
  src: url("CalibreWeb-Regular.f38b070f.woff2") format("woff2"), url("CalibreWeb-Regular.833aec0f.woff") format("woff");
}

@font-face {
  font-family: Calibre Medium;
  src: url("CalibreWeb-Medium.826fb72e.woff2") format("woff2"), url("CalibreWeb-Medium.17f480f5.woff") format("woff");
}

@font-face {
  font-family: Calibre Semibold;
  src: url("CalibreWeb-Semibold.436ff0f4.woff2") format("woff2"), url("CalibreWeb-Semibold.86c7ca2f.woff") format("woff");
}

@font-face {
  font-family: Financier Medium;
  src: url("financier-display-web-medium.1c75a619.eot") format("eot"), url("financier-display-web-medium.80c07a1c.woff") format("woff"), url("financier-display-web-medium.0c6f6b55.woff2") format("woff2");
}

@font-face {
  font-family: Financier Regular;
  src: url("financier-display-web-medium.1c75a619.eot") format("eot"), url("financier-display-web-medium.80c07a1c.woff") format("woff"), url("financier-display-web-medium.0c6f6b55.woff2") format("woff2");
}

@font-face {
  font-family: Financier Semibold;
  src: url("financier-display-web-semibold.74f30e2d.eot") format("eot"), url("financier-display-web-semibold.05571b99.woff") format("woff"), url("financier-display-web-semibold.442ec791.woff2") format("woff2");
}

@font-face {
  font-family: "Financier", sans-serif;
  src: url("financier-display-web-medium-italic.14b5c480.eot") format("eot"), url("financier-display-web-medium-italic.dd08e73f.woff") format("woff"), url("financier-display-web-medium-italic.b1d25820.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Financier", sans-serif;
  src: url("financier-display-web-medium-italic.14b5c480.eot") format("eot"), url("financier-display-web-medium-italic.dd08e73f.woff") format("woff"), url("financier-display-web-medium-italic.b1d25820.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Financier", sans-serif;
  src: url("financier-display-web-semibold-italic.168ae084.eot") format("eot"), url("financier-display-web-semibold-italic.a2767991.woff") format("woff"), url("financier-display-web-semibold-italic.8d8ddc7b.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: fallback;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.flex {
  display: flex;
}

.force-flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-align-items-start {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-end {
  align-items: flex-end;
}

.flex-align-items-baseline {
  align-items: baseline;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-center-all {
  justify-content: center;
  align-items: center;
}

.pull-start {
  align-self: flex-start;
}

.pull-end {
  align-self: flex-end;
}

.pull-center {
  align-self: center;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, embed, object, audio, video {
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  text-align: left;
  padding: 0;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fcfcfc;
  min-width: 300px;
  font-size: 18px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

button, input, select, textarea {
  font-family: Calibre Regular;
  font-weight: 500;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #1a1a1a;
  font-family: Calibre Regular;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #003f2d;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #17e88f;
}

.heap-size-my-office-button:hover, .submitBtn:hover, .find-space-button:hover {
  background-color: #144e3e !important;
}

code {
  color: #fc5328;
  background-color: #fcfcfc;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #dbdbdb;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #434a48;
  font-weight: 700;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #1a1a1a;
  white-space: pre;
  word-wrap: normal;
  background-color: #fcfcfc;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  text-align: left;
  vertical-align: top;
}

table th {
  color: #434a48;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-fullheight {
  min-height: 100vh !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-ellipsized {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.is-overlay {
  position: absolute;
  inset: 0;
}

.has-letter-spacing-small {
  letter-spacing: .2px;
}

.has-letter-spacing-medium {
  letter-spacing: .4px;
}

.has-letter-spacing-large {
  letter-spacing: .6px;
}

.is-size-1 {
  font-size: 4rem !important;
}

.has-padding-1 {
  padding: 4rem !important;
}

.has-padding-top-1 {
  padding-top: 4rem !important;
}

.has-padding-right-1 {
  padding-right: 4rem !important;
}

.has-padding-bottom-1 {
  padding-bottom: 4rem !important;
}

.has-padding-left-1 {
  padding-left: 4rem !important;
}

.has-margin-1 {
  margin: 4rem !important;
}

.has-margin-top-1 {
  margin-top: 4rem !important;
}

.has-margin-right-1 {
  margin-right: 4rem !important;
}

.has-margin-bottom-1 {
  margin-bottom: 4rem !important;
}

.has-margin-left-1 {
  margin-left: 4rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-2 {
  font-size: 3rem !important;
}

.has-padding-2 {
  padding: 3rem !important;
}

.has-padding-top-2 {
  padding-top: 3rem !important;
}

.has-padding-right-2 {
  padding-right: 3rem !important;
}

.has-padding-bottom-2 {
  padding-bottom: 3rem !important;
}

.has-padding-left-2 {
  padding-left: 3rem !important;
}

.has-margin-2 {
  margin: 3rem !important;
}

.has-margin-top-2 {
  margin-top: 3rem !important;
}

.has-margin-right-2 {
  margin-right: 3rem !important;
}

.has-margin-bottom-2 {
  margin-bottom: 3rem !important;
}

.has-margin-left-2 {
  margin-left: 3rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-3 {
  font-size: 2.5rem !important;
}

.has-padding-3 {
  padding: 2.5rem !important;
}

.has-padding-top-3 {
  padding-top: 2.5rem !important;
}

.has-padding-right-3 {
  padding-right: 2.5rem !important;
}

.has-padding-bottom-3 {
  padding-bottom: 2.5rem !important;
}

.has-padding-left-3 {
  padding-left: 2.5rem !important;
}

.has-margin-3 {
  margin: 2.5rem !important;
}

.has-margin-top-3 {
  margin-top: 2.5rem !important;
}

.has-margin-right-3 {
  margin-right: 2.5rem !important;
}

.has-margin-bottom-3 {
  margin-bottom: 2.5rem !important;
}

.has-margin-left-3 {
  margin-left: 2.5rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.has-padding-4 {
  padding: 1.5rem !important;
}

.has-padding-top-4 {
  padding-top: 1.5rem !important;
}

.has-padding-right-4 {
  padding-right: 1.5rem !important;
}

.has-padding-bottom-4 {
  padding-bottom: 1.5rem !important;
}

.has-padding-left-4 {
  padding-left: 1.5rem !important;
}

.has-margin-4 {
  margin: 1.5rem !important;
}

.has-margin-top-4 {
  margin-top: 1.5rem !important;
}

.has-margin-right-4 {
  margin-right: 1.5rem !important;
}

.has-margin-bottom-4 {
  margin-bottom: 1.5rem !important;
}

.has-margin-left-4 {
  margin-left: 1.5rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.has-padding-5 {
  padding: 1.25rem !important;
}

.has-padding-top-5 {
  padding-top: 1.25rem !important;
}

.has-padding-right-5 {
  padding-right: 1.25rem !important;
}

.has-padding-bottom-5 {
  padding-bottom: 1.25rem !important;
}

.has-padding-left-5 {
  padding-left: 1.25rem !important;
}

.has-margin-5 {
  margin: 1.25rem !important;
}

.has-margin-top-5 {
  margin-top: 1.25rem !important;
}

.has-margin-right-5 {
  margin-right: 1.25rem !important;
}

.has-margin-bottom-5 {
  margin-bottom: 1.25rem !important;
}

.has-margin-left-5 {
  margin-left: 1.25rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-6 {
  font-size: 1rem !important;
}

.has-padding-6 {
  padding: 1rem !important;
}

.has-padding-top-6 {
  padding-top: 1rem !important;
}

.has-padding-right-6 {
  padding-right: 1rem !important;
}

.has-padding-bottom-6 {
  padding-bottom: 1rem !important;
}

.has-padding-left-6 {
  padding-left: 1rem !important;
}

.has-margin-6 {
  margin: 1rem !important;
}

.has-margin-top-6 {
  margin-top: 1rem !important;
}

.has-margin-right-6 {
  margin-right: 1rem !important;
}

.has-margin-bottom-6 {
  margin-bottom: 1rem !important;
}

.has-margin-left-6 {
  margin-left: 1rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-7 {
  font-size: .75rem !important;
}

.has-padding-7 {
  padding: .75rem !important;
}

.has-padding-top-7 {
  padding-top: .75rem !important;
}

.has-padding-right-7 {
  padding-right: .75rem !important;
}

.has-padding-bottom-7 {
  padding-bottom: .75rem !important;
}

.has-padding-left-7 {
  padding-left: .75rem !important;
}

.has-margin-7 {
  margin: .75rem !important;
}

.has-margin-top-7 {
  margin-top: .75rem !important;
}

.has-margin-right-7 {
  margin-right: .75rem !important;
}

.has-margin-bottom-7 {
  margin-bottom: .75rem !important;
}

.has-margin-left-7 {
  margin-left: .75rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-8 {
  font-size: .5rem !important;
}

.has-padding-8 {
  padding: .5rem !important;
}

.has-padding-top-8 {
  padding-top: .5rem !important;
}

.has-padding-right-8 {
  padding-right: .5rem !important;
}

.has-padding-bottom-8 {
  padding-bottom: .5rem !important;
}

.has-padding-left-8 {
  padding-left: .5rem !important;
}

.has-margin-8 {
  margin: .5rem !important;
}

.has-margin-top-8 {
  margin-top: .5rem !important;
}

.has-margin-right-8 {
  margin-right: .5rem !important;
}

.has-margin-bottom-8 {
  margin-bottom: .5rem !important;
}

.has-margin-left-8 {
  margin-left: .5rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-size-9 {
  font-size: .2rem !important;
}

.has-padding-9 {
  padding: .2rem !important;
}

.has-padding-top-9 {
  padding-top: .2rem !important;
}

.has-padding-right-9 {
  padding-right: .2rem !important;
}

.has-padding-bottom-9 {
  padding-bottom: .2rem !important;
}

.has-padding-left-9 {
  padding-left: .2rem !important;
}

.has-margin-9 {
  margin: .2rem !important;
}

.has-margin-top-9 {
  margin-top: .2rem !important;
}

.has-margin-right-9 {
  margin-right: .2rem !important;
}

.has-margin-bottom-9 {
  margin-bottom: .2rem !important;
}

.has-margin-left-9 {
  margin-left: .2rem !important;
}

@media screen and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }

  .is-size-1-mobile {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-2-mobile {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-3-mobile {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-7-mobile {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-8-mobile {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 767px) {
  .is-size-9-mobile {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width <= 767px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-1-tablet {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-2-tablet {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-3-tablet {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-7-tablet {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-8-tablet {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 768px), print {
  .is-size-9-tablet {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 768px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-1-touch {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-2-touch {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-3-touch {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-7-touch {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-8-touch {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width <= 989px) {
  .is-size-9-touch {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width <= 989px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-1-desktop {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-2-desktop {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-3-desktop {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-7-desktop {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-8-desktop {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 990px) {
  .is-size-9-desktop {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 990px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-1-widescreen {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-2-widescreen {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-3-widescreen {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-7-widescreen {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-8-widescreen {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1182px) {
  .is-size-9-widescreen {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 1182px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-1-fullhd {
    font-size: 4rem !important;
  }

  .has-padding-1 {
    padding: 4rem !important;
  }

  .has-padding-top-1 {
    padding-top: 4rem !important;
  }

  .has-padding-right-1 {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1 {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1 {
    padding-left: 4rem !important;
  }

  .has-margin-1 {
    margin: 4rem !important;
  }

  .has-margin-top-1 {
    margin-top: 4rem !important;
  }

  .has-margin-right-1 {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1 {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1 {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-1-mobile {
    padding: 4rem !important;
  }

  .has-padding-top-1-mobile {
    padding-top: 4rem !important;
  }

  .has-padding-right-1-mobile {
    padding-right: 4rem !important;
  }

  .has-padding-bottom-1-mobile {
    padding-bottom: 4rem !important;
  }

  .has-padding-left-1-mobile {
    padding-left: 4rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-1-mobile {
    margin: 4rem !important;
  }

  .has-margin-top-1-mobile {
    margin-top: 4rem !important;
  }

  .has-margin-right-1-mobile {
    margin-right: 4rem !important;
  }

  .has-margin-bottom-1-mobile {
    margin-bottom: 4rem !important;
  }

  .has-margin-left-1-mobile {
    margin-left: 4rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-2-fullhd {
    font-size: 3rem !important;
  }

  .has-padding-2 {
    padding: 3rem !important;
  }

  .has-padding-top-2 {
    padding-top: 3rem !important;
  }

  .has-padding-right-2 {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2 {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2 {
    padding-left: 3rem !important;
  }

  .has-margin-2 {
    margin: 3rem !important;
  }

  .has-margin-top-2 {
    margin-top: 3rem !important;
  }

  .has-margin-right-2 {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2 {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2 {
    margin-left: 3rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-2-mobile {
    padding: 3rem !important;
  }

  .has-padding-top-2-mobile {
    padding-top: 3rem !important;
  }

  .has-padding-right-2-mobile {
    padding-right: 3rem !important;
  }

  .has-padding-bottom-2-mobile {
    padding-bottom: 3rem !important;
  }

  .has-padding-left-2-mobile {
    padding-left: 3rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-2-mobile {
    margin: 3rem !important;
  }

  .has-margin-top-2-mobile {
    margin-top: 3rem !important;
  }

  .has-margin-right-2-mobile {
    margin-right: 3rem !important;
  }

  .has-margin-bottom-2-mobile {
    margin-bottom: 3rem !important;
  }

  .has-margin-left-2-mobile {
    margin-left: 3rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-3-fullhd {
    font-size: 2.5rem !important;
  }

  .has-padding-3 {
    padding: 2.5rem !important;
  }

  .has-padding-top-3 {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3 {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3 {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3 {
    padding-left: 2.5rem !important;
  }

  .has-margin-3 {
    margin: 2.5rem !important;
  }

  .has-margin-top-3 {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3 {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3 {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3 {
    margin-left: 2.5rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-3-mobile {
    padding: 2.5rem !important;
  }

  .has-padding-top-3-mobile {
    padding-top: 2.5rem !important;
  }

  .has-padding-right-3-mobile {
    padding-right: 2.5rem !important;
  }

  .has-padding-bottom-3-mobile {
    padding-bottom: 2.5rem !important;
  }

  .has-padding-left-3-mobile {
    padding-left: 2.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-3-mobile {
    margin: 2.5rem !important;
  }

  .has-margin-top-3-mobile {
    margin-top: 2.5rem !important;
  }

  .has-margin-right-3-mobile {
    margin-right: 2.5rem !important;
  }

  .has-margin-bottom-3-mobile {
    margin-bottom: 2.5rem !important;
  }

  .has-margin-left-3-mobile {
    margin-left: 2.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .has-padding-4 {
    padding: 1.5rem !important;
  }

  .has-padding-top-4 {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4 {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4 {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4 {
    padding-left: 1.5rem !important;
  }

  .has-margin-4 {
    margin: 1.5rem !important;
  }

  .has-margin-top-4 {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4 {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4 {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4 {
    margin-left: 1.5rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-4-mobile {
    padding: 1.5rem !important;
  }

  .has-padding-top-4-mobile {
    padding-top: 1.5rem !important;
  }

  .has-padding-right-4-mobile {
    padding-right: 1.5rem !important;
  }

  .has-padding-bottom-4-mobile {
    padding-bottom: 1.5rem !important;
  }

  .has-padding-left-4-mobile {
    padding-left: 1.5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-4-mobile {
    margin: 1.5rem !important;
  }

  .has-margin-top-4-mobile {
    margin-top: 1.5rem !important;
  }

  .has-margin-right-4-mobile {
    margin-right: 1.5rem !important;
  }

  .has-margin-bottom-4-mobile {
    margin-bottom: 1.5rem !important;
  }

  .has-margin-left-4-mobile {
    margin-left: 1.5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .has-padding-5 {
    padding: 1.25rem !important;
  }

  .has-padding-top-5 {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5 {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5 {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5 {
    padding-left: 1.25rem !important;
  }

  .has-margin-5 {
    margin: 1.25rem !important;
  }

  .has-margin-top-5 {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5 {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5 {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5 {
    margin-left: 1.25rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-5-mobile {
    padding: 1.25rem !important;
  }

  .has-padding-top-5-mobile {
    padding-top: 1.25rem !important;
  }

  .has-padding-right-5-mobile {
    padding-right: 1.25rem !important;
  }

  .has-padding-bottom-5-mobile {
    padding-bottom: 1.25rem !important;
  }

  .has-padding-left-5-mobile {
    padding-left: 1.25rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-5-mobile {
    margin: 1.25rem !important;
  }

  .has-margin-top-5-mobile {
    margin-top: 1.25rem !important;
  }

  .has-margin-right-5-mobile {
    margin-right: 1.25rem !important;
  }

  .has-margin-bottom-5-mobile {
    margin-bottom: 1.25rem !important;
  }

  .has-margin-left-5-mobile {
    margin-left: 1.25rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .has-padding-6 {
    padding: 1rem !important;
  }

  .has-padding-top-6 {
    padding-top: 1rem !important;
  }

  .has-padding-right-6 {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6 {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6 {
    padding-left: 1rem !important;
  }

  .has-margin-6 {
    margin: 1rem !important;
  }

  .has-margin-top-6 {
    margin-top: 1rem !important;
  }

  .has-margin-right-6 {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6 {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6 {
    margin-left: 1rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-6-mobile {
    padding: 1rem !important;
  }

  .has-padding-top-6-mobile {
    padding-top: 1rem !important;
  }

  .has-padding-right-6-mobile {
    padding-right: 1rem !important;
  }

  .has-padding-bottom-6-mobile {
    padding-bottom: 1rem !important;
  }

  .has-padding-left-6-mobile {
    padding-left: 1rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-6-mobile {
    margin: 1rem !important;
  }

  .has-margin-top-6-mobile {
    margin-top: 1rem !important;
  }

  .has-margin-right-6-mobile {
    margin-right: 1rem !important;
  }

  .has-margin-bottom-6-mobile {
    margin-bottom: 1rem !important;
  }

  .has-margin-left-6-mobile {
    margin-left: 1rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-7-fullhd {
    font-size: .75rem !important;
  }

  .has-padding-7 {
    padding: .75rem !important;
  }

  .has-padding-top-7 {
    padding-top: .75rem !important;
  }

  .has-padding-right-7 {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7 {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7 {
    padding-left: .75rem !important;
  }

  .has-margin-7 {
    margin: .75rem !important;
  }

  .has-margin-top-7 {
    margin-top: .75rem !important;
  }

  .has-margin-right-7 {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7 {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7 {
    margin-left: .75rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-7-mobile {
    padding: .75rem !important;
  }

  .has-padding-top-7-mobile {
    padding-top: .75rem !important;
  }

  .has-padding-right-7-mobile {
    padding-right: .75rem !important;
  }

  .has-padding-bottom-7-mobile {
    padding-bottom: .75rem !important;
  }

  .has-padding-left-7-mobile {
    padding-left: .75rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-7-mobile {
    margin: .75rem !important;
  }

  .has-margin-top-7-mobile {
    margin-top: .75rem !important;
  }

  .has-margin-right-7-mobile {
    margin-right: .75rem !important;
  }

  .has-margin-bottom-7-mobile {
    margin-bottom: .75rem !important;
  }

  .has-margin-left-7-mobile {
    margin-left: .75rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-8-fullhd {
    font-size: .5rem !important;
  }

  .has-padding-8 {
    padding: .5rem !important;
  }

  .has-padding-top-8 {
    padding-top: .5rem !important;
  }

  .has-padding-right-8 {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8 {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8 {
    padding-left: .5rem !important;
  }

  .has-margin-8 {
    margin: .5rem !important;
  }

  .has-margin-top-8 {
    margin-top: .5rem !important;
  }

  .has-margin-right-8 {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8 {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8 {
    margin-left: .5rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-8-mobile {
    padding: .5rem !important;
  }

  .has-padding-top-8-mobile {
    padding-top: .5rem !important;
  }

  .has-padding-right-8-mobile {
    padding-right: .5rem !important;
  }

  .has-padding-bottom-8-mobile {
    padding-bottom: .5rem !important;
  }

  .has-padding-left-8-mobile {
    padding-left: .5rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-8-mobile {
    margin: .5rem !important;
  }

  .has-margin-top-8-mobile {
    margin-top: .5rem !important;
  }

  .has-margin-right-8-mobile {
    margin-right: .5rem !important;
  }

  .has-margin-bottom-8-mobile {
    margin-bottom: .5rem !important;
  }

  .has-margin-left-8-mobile {
    margin-left: .5rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

@media screen and (width >= 1374px) {
  .is-size-9-fullhd {
    font-size: .2rem !important;
  }

  .has-padding-9 {
    padding: .2rem !important;
  }

  .has-padding-top-9 {
    padding-top: .2rem !important;
  }

  .has-padding-right-9 {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9 {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9 {
    padding-left: .2rem !important;
  }

  .has-margin-9 {
    margin: .2rem !important;
  }

  .has-margin-top-9 {
    margin-top: .2rem !important;
  }

  .has-margin-right-9 {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9 {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9 {
    margin-left: .2rem !important;
  }
}

@media screen and (width >= 1374px) and (width <= 767px) {
  .has-padding-9-mobile {
    padding: .2rem !important;
  }

  .has-padding-top-9-mobile {
    padding-top: .2rem !important;
  }

  .has-padding-right-9-mobile {
    padding-right: .2rem !important;
  }

  .has-padding-bottom-9-mobile {
    padding-bottom: .2rem !important;
  }

  .has-padding-left-9-mobile {
    padding-left: .2rem !important;
  }

  .has-no-padding-mobile {
    padding: 0 !important;
  }

  .has-no-padding-top-mobile {
    padding-top: 0 !important;
  }

  .has-no-padding-right-mobile {
    padding-right: 0 !important;
  }

  .has-no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }

  .has-no-padding-left-mobile {
    padding-left: 0 !important;
  }

  .has-margin-9-mobile {
    margin: .2rem !important;
  }

  .has-margin-top-9-mobile {
    margin-top: .2rem !important;
  }

  .has-margin-right-9-mobile {
    margin-right: .2rem !important;
  }

  .has-margin-bottom-9-mobile {
    margin-bottom: .2rem !important;
  }

  .has-margin-left-9-mobile {
    margin-left: .2rem !important;
  }

  .has-no-margin-mobile {
    margin: 0 !important;
  }

  .has-no-margin-top-mobile {
    margin-top: 0 !important;
  }

  .has-no-margin-right-mobile {
    margin-right: 0 !important;
  }

  .has-no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .has-no-margin-left-mobile {
    margin-left: 0 !important;
  }
}

.is-sans-regular {
  font-family: Calibre Regular, Arial, Helvetica, sans-serif !important;
}

.is-sans-semibold {
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif !important;
}

.is-sans-medium {
  font-family: Calibre Medium, Arial, Helvetica, sans-serif !important;
}

.is-serif {
  font-family: Archer A, Archer B, Garamond, serif !important;
}

.is-calibre {
  font-family: Calibre Regular !important;
}

.is-financier-regular {
  font-family: Financier Regular, sans-serif !important;
}

.is-financier-semibold {
  font-family: Financier Semibold, sans-serif !important;
}

.has-text-centered {
  text-align: center !important;
}

@media screen and (width <= 767px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

.has-text-justified {
  text-align: justify !important;
}

@media screen and (width <= 767px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

.has-text-left {
  text-align: left !important;
}

@media screen and (width <= 767px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 767px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 768px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 989px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 990px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1182px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1374px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.has-text-white {
  color: #fcfcfc !important;
}

a.has-text-white:hover, a.has-text-white:focus, .has-text-white.is-hoverable:hover, .has-text-white.is-hoverable:focus {
  color: #e3e3e3 !important;
}

.has-text-black {
  color: #1a1a1a !important;
}

a.has-text-black:hover, a.has-text-black:focus, .has-text-black.is-hoverable:hover, .has-text-black.is-hoverable:focus {
  color: #010101 !important;
}

a.has-text-light:hover, a.has-text-light:focus, .has-text-light.is-hoverable:hover, .has-text-light.is-hoverable:focus {
  color: #d6d6d6 !important;
}

.has-text-dark {
  color: #434a48 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus, .has-text-dark.is-hoverable:hover, .has-text-dark.is-hoverable:focus {
  color: #2b2f2e !important;
}

.has-text-primary {
  color: #003f2d !important;
}

a.has-text-primary:hover, a.has-text-primary:focus, .has-text-primary.is-hoverable:hover, .has-text-primary.is-hoverable:focus {
  color: #000c09 !important;
}

.has-text-secondary {
  color: #d6e3df !important;
}

a.has-text-secondary:hover, a.has-text-secondary:focus, .has-text-secondary.is-hoverable:hover, .has-text-secondary.is-hoverable:focus {
  color: #b8cec7 !important;
}

.has-text-tertiary {
  color: #0d8070 !important;
}

a.has-text-tertiary:hover, a.has-text-tertiary:focus, .has-text-tertiary.is-hoverable:hover, .has-text-tertiary.is-hoverable:focus {
  color: #085247 !important;
}

.has-text-primary-invert {
  color: #fcfcfc !important;
}

a.has-text-primary-invert:hover, a.has-text-primary-invert:focus, .has-text-primary-invert.is-hoverable:hover, .has-text-primary-invert.is-hoverable:focus {
  color: #e3e3e3 !important;
}

.has-text-link {
  color: #003f2d !important;
}

a.has-text-link:hover, a.has-text-link:focus, .has-text-link.is-hoverable:hover, .has-text-link.is-hoverable:focus {
  color: #000c09 !important;
}

.has-text-info {
  color: #00b2dd !important;
}

a.has-text-info:hover, a.has-text-info:focus, .has-text-info.is-hoverable:hover, .has-text-info.is-hoverable:focus {
  color: #0089aa !important;
}

.has-text-pale-green {
  color: #d6e3df !important;
}

a.has-text-pale-green:hover, a.has-text-pale-green:focus, .has-text-pale-green.is-hoverable:hover, .has-text-pale-green.is-hoverable:focus {
  color: #b8cec7 !important;
}

.has-text-success {
  color: #50e3c2 !important;
}

a.has-text-success:hover, a.has-text-success:focus, .has-text-success.is-hoverable:hover, .has-text-success.is-hoverable:focus {
  color: #24dcb3 !important;
}

.has-text-warning {
  color: #ffdd57 !important;
}

a.has-text-warning:hover, a.has-text-warning:focus, .has-text-warning.is-hoverable:hover, .has-text-warning.is-hoverable:focus {
  color: #ffd324 !important;
}

.has-text-danger {
  color: #fc5328 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus, .has-text-danger.is-hoverable:hover, .has-text-danger.is-hoverable:focus {
  color: #ee3303 !important;
}

.has-text-me {
  color: #00d2a0 !important;
}

a.has-text-me:hover, a.has-text-me:focus, .has-text-me.is-hoverable:hover, .has-text-me.is-hoverable:focus {
  color: #009f79 !important;
}

.has-text-we {
  color: #00b2dd !important;
}

a.has-text-we:hover, a.has-text-we:focus, .has-text-we.is-hoverable:hover, .has-text-we.is-hoverable:focus {
  color: #0089aa !important;
}

.has-text-amenity {
  color: #7d7ccf !important;
}

a.has-text-amenity:hover, a.has-text-amenity:focus, .has-text-amenity.is-hoverable:hover, .has-text-amenity.is-hoverable:focus {
  color: #5857c1 !important;
}

.has-text-light-pale-green {
  color: #eaf1ef !important;
}

a.has-text-light-pale-green:hover, a.has-text-light-pale-green:focus, .has-text-light-pale-green.is-hoverable:hover, .has-text-light-pale-green.is-hoverable:focus {
  color: #cbddd8 !important;
}

.has-text-green-forest-darker {
  color: #1d4837 !important;
}

a.has-text-green-forest-darker:hover, a.has-text-green-forest-darker:focus, .has-text-green-forest-darker.is-hoverable:hover, .has-text-green-forest-darker.is-hoverable:focus {
  color: #0e241b !important;
}

.has-text-green-forest-dark {
  color: #003f2d !important;
}

a.has-text-green-forest-dark:hover, a.has-text-green-forest-dark:focus, .has-text-green-forest-dark.is-hoverable:hover, .has-text-green-forest-dark.is-hoverable:focus {
  color: #000c09 !important;
}

.has-text-grey-darker {
  color: #434a48 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-text-grey {
  color: #666 !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-text-grey-lightest {
  color: #f3f3f3 !important;
}

.has-text-white-ter {
  color: #f0f0f0 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-text-light {
  color: #666 !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.text-on-is-me, .text-on-is-we, .text-on-is-amenity {
  color: #fcfcfc !important;
}

.is-me {
  background-color: #00d2a0;
}

.is-we {
  background-color: #00b2dd;
}

.is-amenity {
  background-color: #7d7ccf;
}

.is-grey-lighter {
  background-color: #dbdbdb;
}

.is-pale-green {
  background-color: #dbdbdb33;
}

.is-light-gray {
  background-color: #e6eaea;
}

.is-pale-green-new {
  background-color: #c0d4cb;
}

.is-white {
  background-color: #f5f7f7;
}

.is-block {
  display: block !important;
}

@media screen and (width <= 767px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 768px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 989px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 990px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1182px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1374px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (width <= 767px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 768px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 989px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 990px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1182px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1374px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (width <= 767px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 768px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 989px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 990px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1182px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1374px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 767px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 768px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 989px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 990px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1182px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1374px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 767px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 768px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 989px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 990px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1182px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1374px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-invisible {
  visibility: hidden;
}

@media screen and (width <= 767px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media print {
  .is-hidden-print {
    display: none !important;
  }
}

.is-print-only {
  display: none;
}

@media print {
  .is-print-only {
    display: block;
  }
}

@media screen and (width >= 768px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 989px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 990px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 990px) and (width <= 1181px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1182px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1182px) and (width <= 1373px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1374px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-position-relative {
  position: relative !important;
}

.is-position-absolute {
  position: absolute !important;
}

.is-position-fixed {
  position: fixed !important;
}

.is-position-inherit {
  position: inherit !important;
}

.is-position-initial {
  position: initial !important;
}

.is-position-sticky {
  position: sticky !important;
}

.break-after {
  break-after: page;
}

.break-before {
  break-before: page;
}

.break-inside-avoid {
  break-inside: avoid;
}

.no-outline:focus {
  outline: none;
}

.no-pointer {
  pointer-events: none !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.is-borderless {
  border: none !important;
}

.is-scroll-link-target {
  color: inherit;
}

.has-margin-0-auto {
  margin: 0 auto !important;
}

.has-margin-left-auto {
  margin-left: auto;
}

.has-margin-right-auto {
  margin-right: auto;
}

.is-selectable {
  cursor: pointer;
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility linear, opacity .5s;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 500s linear, opacity .5s, left 0s .5s;
  left: -9999px !important;
}

.is-invisible-to-be-faded-in {
  visibility: hidden;
  opacity: 0;
}

.visually-hidden, .blog-tag-filter input, .radio-spectrum input {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.breadcrumb {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow: auto hidden;
}

.breadcrumb:not(:last-child) {
  margin-bottom: 1.5rem;
}

.breadcrumb a {
  color: #003f2d;
  justify-content: center;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.breadcrumb a:hover {
  color: #17e88f;
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-left: 0;
}

.breadcrumb li.is-active a {
  color: #434a48;
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: #1a1a1a;
  content: "/";
}

.breadcrumb ul, .breadcrumb ol {
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-right: .5em;
}

.breadcrumb .icon:last-child {
  margin-left: .5em;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul {
  justify-content: flex-end;
}

.breadcrumb.is-small {
  font-size: .75rem;
}

.breadcrumb.is-medium {
  font-size: 1.25rem;
}

.breadcrumb.is-large {
  font-size: 1.5rem;
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  color: #1a1a1a;
  background-color: #fcfcfc;
  max-width: 100%;
  position: relative;
  box-shadow: 0 2px 3px #1a1a1a1a, 0 0 0 1px #1a1a1a1a;
}

.card-header {
  align-items: stretch;
  display: flex;
  box-shadow: 0 1px 2px #1a1a1a1a;
}

.card-header-title {
  color: #434a48;
  flex-grow: 1;
  align-items: center;
  padding: .75rem;
  font-weight: 700;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-content {
  padding: 1.5rem;
}

.card-footer {
  border-top: 1px solid #dbdbdb;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #dbdbdb;
}

.card .media:not(:last-child) {
  margin-bottom: .75rem;
}

.copy-to-clipboard-box {
  background-color: #fcfcfc;
  border-radius: .1875rem;
  width: 18.3rem;
  height: 4.3rem;
  margin: .625rem 0 0;
  position: absolute;
  right: 0;
  box-shadow: 0 .125rem .25rem #66666680;
}

@media screen and (width <= 767px) {
  .copy-to-clipboard-box {
    margin: 2.5rem 0 0 -10rem;
  }
}

.copy-to-clipboard-box-text {
  letter-spacing: .4px;
  color: #6c6d6d;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 290px;
  height: 1.625rem;
  margin-top: 2px;
  margin-left: 12px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: .77rem;
  overflow: hidden;
}

.copy-to-clipboard-box-input {
  float: left;
  letter-spacing: .4px;
  text-align: center;
  color: #666;
  border: .0625rem solid #dbdbdb;
  border-radius: .1875rem;
  width: 67%;
  height: 1.8rem;
  margin: .4rem 0 0 .8rem;
  padding: .5625rem .7rem .4375rem .5rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: .8rem;
  font-weight: 500;
}

.copy-to-clipboard-box-clipboard {
  float: right;
  letter-spacing: .4px;
  text-align: center;
  color: #1a1a1a;
  border: .0625rem solid #434a48;
  border-radius: .1875rem;
  width: 4.2rem;
  height: 1.7rem;
  margin: .4375rem .4375rem 0 0;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: 500;
  display: inline;
}

.copy-to-clipboard-box-clipboard:hover {
  cursor: pointer;
  background-color: #6666661a;
}

.copy-to-clipboard-box-clipboard:focus {
  outline: 0;
}

@media screen and (width <= 767px) {
  .copy-to-clipboard-box-clipboard {
    background-color: #fcfcfc;
  }
}

.copy-to-clipboard-box-checkmark {
  float: right;
  background: url("confirm-icon-no-background-spacer-by-cbre.236a7922.svg");
  width: 1rem;
  height: 1rem;
  margin: .8125rem 3.6rem 0 0;
  display: inline;
}

.copy-to-clipboard-box-clipboard.copied {
  color: #12b8a2;
  cursor: auto;
  background-color: #0000;
  border: none;
  width: 3.2rem;
  margin-top: -1.5rem;
}

.copy-to-clipboard-box-clipboard.copied:hover {
  background-color: #0000;
}

.copy-to-clipboard-box-container {
  padding-top: 1.2rem;
  padding-left: 0;
  display: block;
  overflow: visible;
}

.copy-to-clipboard-box-background {
  opacity: 0;
  background-color: #fcfcfc;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.dropdown {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  visibility: visible;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: 4px;
  padding-top: unset;
  top: auto;
  bottom: 100%;
}

.dropdown:focus {
  outline: none;
}

@media screen and (width <= 767px) {
  .dropdown {
    margin: 0;
    position: static;
  }

  .dropdown .dropdown-menu {
    width: 300px;
  }

  .dropdown .dropdown-item {
    white-space: normal;
    height: auto;
    min-height: 50px;
  }
}

.dropdown-menu {
  visibility: hidden;
  z-index: 30;
  scrollbar-base-color: #fcfcfc80;
  scrollbar-track-color: #003f2d;
  scrollbar-arrow-color: #fcfcfc80;
  background-color: #fff;
  min-width: 5rem;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  box-shadow: 0 0 15px #00000040;
}

.dropdown-menu::-webkit-scrollbar {
  background-color: #e4e4e4;
  border: 1px solid #fcfcfc33;
  width: 8px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #003f2d;
  border-radius: 10px !important;
  outline: 1px solid #708090 !important;
}

@media screen and (width <= 767px) {
  .dropdown-menu {
    margin-left: -20vw;
    width: 90vw !important;
    max-width: none !important;
    left: 20% !important;
  }
}

.dropdown-content {
  background-color: #fcfcfc;
  border-radius: 2px;
  overflow: auto;
  box-shadow: 0 6px 12px #00000026;
}

.dropdown-item {
  color: #003f2d;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: .625rem .75rem;
  font-family: Calibre Regular;
  line-height: 1.88rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.dropdown-item.hover, .dropdown-item.hover.is-active {
  color: #fcfcfc;
  background-color: #003f2d;
}

.dropdown-item.is-active {
  color: #003f2d;
  background-color: #fcfcfc;
  font-weight: 700;
}

.dropdown-item:focus, .dropdown-item:active {
  outline: none;
}

@media screen and (width <= 767px) {
  .dropdown-item {
    line-height: 1;
  }
}

a.dropdown-item {
  white-space: nowrap;
  padding-right: 3rem;
}

a.dropdown-item:hover {
  color: #1a1a1a;
  background-color: #12b8a2;
}

a.dropdown-item.is-active {
  color: #fcfcfc;
  background-color: #003f2d;
}

.dropdown-item-quiz {
  color: #003f2d;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: .625rem .75rem;
  font-family: Calibre Regular;
  font-size: 18px;
  line-height: 1.2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.dropdown-item-quiz.hover, .dropdown-item-quiz.hover.is-active {
  color: #fcfcfc;
  background-color: #778f9c;
}

.dropdown-item-quiz.is-active {
  color: #003f2d;
  background-color: #fcfcfc;
  font-weight: 700;
}

.dropdown-item-quiz:focus, .dropdown-item-quiz:active {
  outline: none;
}

@media screen and (width <= 767px) {
  .dropdown-item-quiz {
    line-height: 1;
  }
}

a.dropdown-item-quiz {
  white-space: nowrap;
  padding-right: 3rem;
}

a.dropdown-item-quiz:hover {
  color: #1a1a1a;
  background-color: #12b8a2;
}

a.dropdown-item-quiz.is-active {
  color: #fcfcfc;
  background-color: #003f2d;
}

.dropdown-divider {
  background-color: #dbdbdb;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level:not(:last-child) {
  margin-bottom: 1.5rem;
}

.level code {
  border-radius: 4px;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item {
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (width >= 768px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (width <= 767px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (width >= 768px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (width <= 767px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (width >= 768px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (width >= 768px), print {
  .level-right {
    display: flex;
  }
}

.loading-page {
  background-color: #d6e3df;
  min-height: 100vh;
}

.loading-page .loader {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
}

.media {
  text-align: left;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid #dbdbdb80;
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid #dbdbdb80;
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: left;
  flex: auto;
}

.menu {
  font-size: 1rem;
}

.menu.is-small {
  font-size: .75rem;
}

.menu.is-medium {
  font-size: 1.25rem;
}

.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #1a1a1a;
  border-radius: 2px;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #434a48;
  background-color: #fcfcfc;
}

.menu-list a.is-active {
  color: #fcfcfc;
  background-color: #003f2d;
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #666;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: #fcfcfc;
  border-radius: 4px;
  font-size: 1rem;
}

.message:not(:last-child) {
  margin-bottom: 1.5rem;
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: .75rem;
}

.message.is-medium {
  font-size: 1.25rem;
}

.message.is-large {
  font-size: 1.5rem;
}

.message.is-white {
  background-color: #fcfcfc;
}

.message.is-white .message-header {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.message.is-white .message-body {
  color: #4d4d4d;
  border-color: #fcfcfc;
}

.message.is-black {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.message.is-black .message-body {
  color: #161616;
  border-color: #1a1a1a;
}

.message.is-light {
  background-color: #fafafa;
}

.message.is-light .message-header {
  color: #434a48;
  background-color: #f0f0f0;
}

.message.is-light .message-body {
  color: #515151;
  border-color: #f0f0f0;
}

.message.is-dark {
  background-color: #fafafa;
}

.message.is-dark .message-header {
  color: #f0f0f0;
  background-color: #434a48;
}

.message.is-dark .message-body {
  color: #333534;
  border-color: #434a48;
}

.message.is-primary {
  background-color: #f5fffc;
}

.message.is-primary .message-header {
  color: #fcfcfc;
  background-color: #003f2d;
}

.message.is-primary .message-body {
  color: #00281d;
  border-color: #003f2d;
}

.message.is-secondary {
  background-color: #f9fbfa;
}

.message.is-secondary .message-header {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.message.is-secondary .message-body {
  color: #515151;
  border-color: #d6e3df;
}

.message.is-tertiary {
  background-color: #f6fefd;
}

.message.is-tertiary .message-header {
  color: #fcfcfc;
  background-color: #0d8070;
}

.message.is-tertiary .message-body {
  color: #083831;
  border-color: #0d8070;
}

.message.is-primary-invert {
  background-color: #fcfcfc;
}

.message.is-primary-invert .message-header {
  color: #003f2d;
  background-color: #fcfcfc;
}

.message.is-primary-invert .message-body {
  color: #4d4d4d;
  border-color: #fcfcfc;
}

.message.is-link {
  background-color: #f5fffc;
}

.message.is-link .message-header {
  color: #fcfcfc;
  background-color: #003f2d;
}

.message.is-link .message-body {
  color: #00281d;
  border-color: #003f2d;
}

.message.is-info {
  background-color: #f5fdff;
}

.message.is-info .message-header {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.message.is-info .message-body {
  color: #043743;
  border-color: #00b2dd;
}

.message.is-pale-green {
  background-color: #f9fbfa;
}

.message.is-pale-green .message-header {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.message.is-pale-green .message-body {
  color: #515151;
  border-color: #d6e3df;
}

.message.is-success {
  background-color: #f6fefc;
}

.message.is-success .message-header {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.message.is-success .message-body {
  color: #123b32;
  border-color: #50e3c2;
}

.message.is-warning {
  background-color: #fffdf5;
}

.message.is-warning .message-header {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.message.is-warning .message-body {
  color: #3c3108;
  border-color: #ffdd57;
}

.message.is-danger {
  background-color: #fff7f5;
}

.message.is-danger .message-header {
  color: #fcfcfc;
  background-color: #fc5328;
}

.message.is-danger .message-body {
  color: #ad2c0b;
  border-color: #fc5328;
}

.message.is-me {
  background-color: #f5fffd;
}

.message.is-me .message-header {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.message.is-me .message-body {
  color: #021410;
  border-color: #00d2a0;
}

.message.is-we {
  background-color: #f5fdff;
}

.message.is-we .message-header {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.message.is-we .message-body {
  color: #043743;
  border-color: #00b2dd;
}

.message.is-amenity {
  background-color: #f8f8fc;
}

.message.is-amenity .message-header {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.message.is-amenity .message-body {
  color: #48479c;
  border-color: #7d7ccf;
}

.message.is-light-pale-green {
  background-color: #f9fbfa;
}

.message.is-light-pale-green .message-header {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.message.is-light-pale-green .message-body {
  color: #4f4f4f;
  border-color: #eaf1ef;
}

.message.is-green-forest-darker {
  background-color: #f8fcfa;
}

.message.is-green-forest-darker .message-header {
  color: #fcfcfc;
  background-color: #1d4837;
}

.message.is-green-forest-darker .message-body {
  color: #153227;
  border-color: #1d4837;
}

.message.is-green-forest-dark {
  background-color: #f5fffc;
}

.message.is-green-forest-dark .message-header {
  color: #fcfcfc;
  background-color: #003f2d;
}

.message.is-green-forest-dark .message-body {
  color: #00281d;
  border-color: #003f2d;
}

.message-header {
  color: #fcfcfc;
  background-color: #1a1a1a;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .5em .75em;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #1a1a1a;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 1em 1.25em;
}

.message-body code, .message-body pre {
  background-color: #fcfcfc;
}

.message-body pre code {
  background-color: #0000;
}

.modal {
  z-index: 50;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #1a1a1a66;
  position: absolute;
  inset: 0;
}

.modal-content {
  padding: 2.5rem;
}

.modal-content, .modal-card {
  background-color: #fcfcfc;
  min-width: 325px;
  max-width: 640px;
  min-height: 200px;
  max-height: calc(100vh - 160px);
  margin: 0 20px;
  position: relative;
  overflow: auto;
}

.modal-content button[type="submit"], .modal-card button[type="submit"] {
  margin-bottom: 1rem;
}

@media screen and (width >= 768px), print {
  .modal-content, .modal-card {
    max-height: calc(100vh - 40px);
  }

  .modal-content.is-wide, .modal-card.is-wide {
    max-width: 900px;
  }
}

.modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: -10%;
  background-color: #0000;
  border: none;
  border-radius: 50%;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;
  min-width: 20px;
  max-width: 20px;
  height: 40px;
  min-height: 20px;
  max-height: 20px;
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.modal-close:before, .modal-close:after {
  content: "";
  transform-origin: center;
  background-color: #003f2d;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.modal-close:before {
  width: 50%;
  height: 1px;
}

.modal-close:after {
  width: 1px;
  height: 50%;
}

.modal-close:hover, .modal-close:focus, .modal-close:active {
  background-color: #003f2d33;
}

.modal-close.is-small {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.modal-close.is-medium {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.modal-close.is-large {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.modal-card {
  flex-direction: column;
  max-height: calc(100vh - 40px);
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #fcfcfc;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-card-title {
  color: #434a48;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: 10px;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fcfcfc;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.success-modal {
  text-align: center;
  z-index: calc(50 + 10);
  background-color: #fcfcfc;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width <= 767px) {
  .modal {
    align-items: unset;
  }

  .modal-content {
    max-width: none;
    max-height: none;
    margin: 0;
    padding: 2.5rem 1.5rem;
  }
}

.navbar {
  background-color: #fff;
  height: 5rem;
  padding: 0 1.5rem;
  transition: box-shadow .75s cubic-bezier(.23, 1, .32, 1);
  position: relative;
}

.navbar.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #efefef;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #efefef;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #fcfcfc;
  }
}

.navbar.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #0d0d0d;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #0d0d0d;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #1a1a1a;
  }
}

.navbar.is-light {
  color: #434a48;
  background-color: #f0f0f0;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #434a48;
}

.navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #434a48;
  background-color: #0000;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #434a48;
}

@media screen and (width >= 990px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #434a48;
  }

  .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #434a48;
    background-color: #e3e3e3;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #434a48;
  }

  .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #434a48;
    background-color: #e3e3e3;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #434a48;
    background-color: #f0f0f0;
  }
}

.navbar.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #f0f0f0;
}

.navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #f0f0f0;
  background-color: #0000;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #f0f0f0;
}

@media screen and (width >= 990px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #f0f0f0;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #f0f0f0;
    background-color: #373d3b;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #f0f0f0;
  }

  .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #f0f0f0;
    background-color: #373d3b;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #f0f0f0;
    background-color: #434a48;
  }
}

.navbar.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #003f2d;
  }
}

.navbar.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.navbar.is-secondary .navbar-brand > .navbar-item, .navbar.is-secondary .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-secondary .navbar-brand > a.navbar-item:hover, .navbar.is-secondary .navbar-brand > a.navbar-item.is-active, .navbar.is-secondary .navbar-brand .navbar-link:hover, .navbar.is-secondary .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-secondary .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-secondary .navbar-start > .navbar-item, .navbar.is-secondary .navbar-start .navbar-link, .navbar.is-secondary .navbar-end > .navbar-item, .navbar.is-secondary .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-secondary .navbar-start > a.navbar-item:hover, .navbar.is-secondary .navbar-start > a.navbar-item.is-active, .navbar.is-secondary .navbar-start .navbar-link:hover, .navbar.is-secondary .navbar-start .navbar-link.is-active, .navbar.is-secondary .navbar-end > a.navbar-item:hover, .navbar.is-secondary .navbar-end > a.navbar-item.is-active, .navbar.is-secondary .navbar-end .navbar-link:hover, .navbar.is-secondary .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-secondary .navbar-start .navbar-link:after, .navbar.is-secondary .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-secondary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-secondary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-secondary .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #d6e3df;
  }
}

.navbar.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
}

.navbar.is-tertiary .navbar-brand > .navbar-item, .navbar.is-tertiary .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-tertiary .navbar-brand > a.navbar-item:hover, .navbar.is-tertiary .navbar-brand > a.navbar-item.is-active, .navbar.is-tertiary .navbar-brand .navbar-link:hover, .navbar.is-tertiary .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-tertiary .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-tertiary .navbar-start > .navbar-item, .navbar.is-tertiary .navbar-start .navbar-link, .navbar.is-tertiary .navbar-end > .navbar-item, .navbar.is-tertiary .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-tertiary .navbar-start > a.navbar-item:hover, .navbar.is-tertiary .navbar-start > a.navbar-item.is-active, .navbar.is-tertiary .navbar-start .navbar-link:hover, .navbar.is-tertiary .navbar-start .navbar-link.is-active, .navbar.is-tertiary .navbar-end > a.navbar-item:hover, .navbar.is-tertiary .navbar-end > a.navbar-item.is-active, .navbar.is-tertiary .navbar-end .navbar-link:hover, .navbar.is-tertiary .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #0b695c;
  }

  .navbar.is-tertiary .navbar-start .navbar-link:after, .navbar.is-tertiary .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-tertiary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-tertiary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #0b695c;
  }

  .navbar.is-tertiary .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #0d8070;
  }
}

.navbar.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
}

.navbar.is-primary-invert .navbar-brand > .navbar-item, .navbar.is-primary-invert .navbar-brand .navbar-link {
  color: #003f2d;
}

.navbar.is-primary-invert .navbar-brand > a.navbar-item:hover, .navbar.is-primary-invert .navbar-brand > a.navbar-item.is-active, .navbar.is-primary-invert .navbar-brand .navbar-link:hover, .navbar.is-primary-invert .navbar-brand .navbar-link.is-active {
  color: #003f2d;
  background-color: #0000;
}

.navbar.is-primary-invert .navbar-brand .navbar-link:after {
  border-color: #003f2d;
}

@media screen and (width >= 990px) {
  .navbar.is-primary-invert .navbar-start > .navbar-item, .navbar.is-primary-invert .navbar-start .navbar-link, .navbar.is-primary-invert .navbar-end > .navbar-item, .navbar.is-primary-invert .navbar-end .navbar-link {
    color: #003f2d;
  }

  .navbar.is-primary-invert .navbar-start > a.navbar-item:hover, .navbar.is-primary-invert .navbar-start > a.navbar-item.is-active, .navbar.is-primary-invert .navbar-start .navbar-link:hover, .navbar.is-primary-invert .navbar-start .navbar-link.is-active, .navbar.is-primary-invert .navbar-end > a.navbar-item:hover, .navbar.is-primary-invert .navbar-end > a.navbar-item.is-active, .navbar.is-primary-invert .navbar-end .navbar-link:hover, .navbar.is-primary-invert .navbar-end .navbar-link.is-active {
    color: #003f2d;
    background-color: #efefef;
  }

  .navbar.is-primary-invert .navbar-start .navbar-link:after, .navbar.is-primary-invert .navbar-end .navbar-link:after {
    border-color: #003f2d;
  }

  .navbar.is-primary-invert .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary-invert .navbar-item.has-dropdown.is-active .navbar-link {
    color: #003f2d;
    background-color: #efefef;
  }

  .navbar.is-primary-invert .navbar-dropdown a.navbar-item.is-active {
    color: #003f2d;
    background-color: #fcfcfc;
  }
}

.navbar.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #003f2d;
  }
}

.navbar.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #00b2dd;
  }
}

.navbar.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.navbar.is-pale-green .navbar-brand > .navbar-item, .navbar.is-pale-green .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-pale-green .navbar-brand > a.navbar-item:hover, .navbar.is-pale-green .navbar-brand > a.navbar-item.is-active, .navbar.is-pale-green .navbar-brand .navbar-link:hover, .navbar.is-pale-green .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-pale-green .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-pale-green .navbar-start > .navbar-item, .navbar.is-pale-green .navbar-start .navbar-link, .navbar.is-pale-green .navbar-end > .navbar-item, .navbar.is-pale-green .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-pale-green .navbar-start > a.navbar-item:hover, .navbar.is-pale-green .navbar-start > a.navbar-item.is-active, .navbar.is-pale-green .navbar-start .navbar-link:hover, .navbar.is-pale-green .navbar-start .navbar-link.is-active, .navbar.is-pale-green .navbar-end > a.navbar-item:hover, .navbar.is-pale-green .navbar-end > a.navbar-item.is-active, .navbar.is-pale-green .navbar-end .navbar-link:hover, .navbar.is-pale-green .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-pale-green .navbar-start .navbar-link:after, .navbar.is-pale-green .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-pale-green .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-pale-green .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #c7d9d3;
  }

  .navbar.is-pale-green .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #d6e3df;
  }
}

.navbar.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #3adfba;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #3adfba;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #50e3c2;
  }
}

.navbar.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #1a1a1ab3;
}

.navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #1a1a1ab3;
  background-color: #0000;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #1a1a1ab3;
}

@media screen and (width >= 990px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #1a1a1ab3;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #1a1a1ab3;
    background-color: #ffd83d;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #1a1a1ab3;
  }

  .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1ab3;
    background-color: #ffd83d;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1ab3;
    background-color: #ffdd57;
  }
}

.navbar.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #fc3f0f;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #fc3f0f;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #fc5328;
  }
}

.navbar.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.navbar.is-me .navbar-brand > .navbar-item, .navbar.is-me .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-me .navbar-brand > a.navbar-item:hover, .navbar.is-me .navbar-brand > a.navbar-item.is-active, .navbar.is-me .navbar-brand .navbar-link:hover, .navbar.is-me .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-me .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-me .navbar-start > .navbar-item, .navbar.is-me .navbar-start .navbar-link, .navbar.is-me .navbar-end > .navbar-item, .navbar.is-me .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-me .navbar-start > a.navbar-item:hover, .navbar.is-me .navbar-start > a.navbar-item.is-active, .navbar.is-me .navbar-start .navbar-link:hover, .navbar.is-me .navbar-start .navbar-link.is-active, .navbar.is-me .navbar-end > a.navbar-item:hover, .navbar.is-me .navbar-end > a.navbar-item.is-active, .navbar.is-me .navbar-end .navbar-link:hover, .navbar.is-me .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00b98d;
  }

  .navbar.is-me .navbar-start .navbar-link:after, .navbar.is-me .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-me .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-me .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00b98d;
  }

  .navbar.is-me .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #00d2a0;
  }
}

.navbar.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.navbar.is-we .navbar-brand > .navbar-item, .navbar.is-we .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-we .navbar-brand > a.navbar-item:hover, .navbar.is-we .navbar-brand > a.navbar-item.is-active, .navbar.is-we .navbar-brand .navbar-link:hover, .navbar.is-we .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-we .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-we .navbar-start > .navbar-item, .navbar.is-we .navbar-start .navbar-link, .navbar.is-we .navbar-end > .navbar-item, .navbar.is-we .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-we .navbar-start > a.navbar-item:hover, .navbar.is-we .navbar-start > a.navbar-item.is-active, .navbar.is-we .navbar-start .navbar-link:hover, .navbar.is-we .navbar-start .navbar-link.is-active, .navbar.is-we .navbar-end > a.navbar-item:hover, .navbar.is-we .navbar-end > a.navbar-item.is-active, .navbar.is-we .navbar-end .navbar-link:hover, .navbar.is-we .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-we .navbar-start .navbar-link:after, .navbar.is-we .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-we .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-we .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #009dc4;
  }

  .navbar.is-we .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #00b2dd;
  }
}

.navbar.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.navbar.is-amenity .navbar-brand > .navbar-item, .navbar.is-amenity .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-amenity .navbar-brand > a.navbar-item:hover, .navbar.is-amenity .navbar-brand > a.navbar-item.is-active, .navbar.is-amenity .navbar-brand .navbar-link:hover, .navbar.is-amenity .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-amenity .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-amenity .navbar-start > .navbar-item, .navbar.is-amenity .navbar-start .navbar-link, .navbar.is-amenity .navbar-end > .navbar-item, .navbar.is-amenity .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-amenity .navbar-start > a.navbar-item:hover, .navbar.is-amenity .navbar-start > a.navbar-item.is-active, .navbar.is-amenity .navbar-start .navbar-link:hover, .navbar.is-amenity .navbar-start .navbar-link.is-active, .navbar.is-amenity .navbar-end > a.navbar-item:hover, .navbar.is-amenity .navbar-end > a.navbar-item.is-active, .navbar.is-amenity .navbar-end .navbar-link:hover, .navbar.is-amenity .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #6a69c8;
  }

  .navbar.is-amenity .navbar-start .navbar-link:after, .navbar.is-amenity .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-amenity .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-amenity .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #6a69c8;
  }

  .navbar.is-amenity .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #7d7ccf;
  }
}

.navbar.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.navbar.is-light-pale-green .navbar-brand > .navbar-item, .navbar.is-light-pale-green .navbar-brand .navbar-link {
  color: #1a1a1a;
}

.navbar.is-light-pale-green .navbar-brand > a.navbar-item:hover, .navbar.is-light-pale-green .navbar-brand > a.navbar-item.is-active, .navbar.is-light-pale-green .navbar-brand .navbar-link:hover, .navbar.is-light-pale-green .navbar-brand .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #0000;
}

.navbar.is-light-pale-green .navbar-brand .navbar-link:after {
  border-color: #1a1a1a;
}

@media screen and (width >= 990px) {
  .navbar.is-light-pale-green .navbar-start > .navbar-item, .navbar.is-light-pale-green .navbar-start .navbar-link, .navbar.is-light-pale-green .navbar-end > .navbar-item, .navbar.is-light-pale-green .navbar-end .navbar-link {
    color: #1a1a1a;
  }

  .navbar.is-light-pale-green .navbar-start > a.navbar-item:hover, .navbar.is-light-pale-green .navbar-start > a.navbar-item.is-active, .navbar.is-light-pale-green .navbar-start .navbar-link:hover, .navbar.is-light-pale-green .navbar-start .navbar-link.is-active, .navbar.is-light-pale-green .navbar-end > a.navbar-item:hover, .navbar.is-light-pale-green .navbar-end > a.navbar-item.is-active, .navbar.is-light-pale-green .navbar-end .navbar-link:hover, .navbar.is-light-pale-green .navbar-end .navbar-link.is-active {
    color: #1a1a1a;
    background-color: #dbe7e3;
  }

  .navbar.is-light-pale-green .navbar-start .navbar-link:after, .navbar.is-light-pale-green .navbar-end .navbar-link:after {
    border-color: #1a1a1a;
  }

  .navbar.is-light-pale-green .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light-pale-green .navbar-item.has-dropdown.is-active .navbar-link {
    color: #1a1a1a;
    background-color: #dbe7e3;
  }

  .navbar.is-light-pale-green .navbar-dropdown a.navbar-item.is-active {
    color: #1a1a1a;
    background-color: #eaf1ef;
  }
}

.navbar.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
}

.navbar.is-green-forest-darker .navbar-brand > .navbar-item, .navbar.is-green-forest-darker .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-green-forest-darker .navbar-brand > a.navbar-item:hover, .navbar.is-green-forest-darker .navbar-brand > a.navbar-item.is-active, .navbar.is-green-forest-darker .navbar-brand .navbar-link:hover, .navbar.is-green-forest-darker .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-green-forest-darker .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-green-forest-darker .navbar-start > .navbar-item, .navbar.is-green-forest-darker .navbar-start .navbar-link, .navbar.is-green-forest-darker .navbar-end > .navbar-item, .navbar.is-green-forest-darker .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-green-forest-darker .navbar-start > a.navbar-item:hover, .navbar.is-green-forest-darker .navbar-start > a.navbar-item.is-active, .navbar.is-green-forest-darker .navbar-start .navbar-link:hover, .navbar.is-green-forest-darker .navbar-start .navbar-link.is-active, .navbar.is-green-forest-darker .navbar-end > a.navbar-item:hover, .navbar.is-green-forest-darker .navbar-end > a.navbar-item.is-active, .navbar.is-green-forest-darker .navbar-end .navbar-link:hover, .navbar.is-green-forest-darker .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #163629;
  }

  .navbar.is-green-forest-darker .navbar-start .navbar-link:after, .navbar.is-green-forest-darker .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-green-forest-darker .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-green-forest-darker .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #163629;
  }

  .navbar.is-green-forest-darker .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #1d4837;
  }
}

.navbar.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
}

.navbar.is-green-forest-dark .navbar-brand > .navbar-item, .navbar.is-green-forest-dark .navbar-brand .navbar-link {
  color: #fcfcfc;
}

.navbar.is-green-forest-dark .navbar-brand > a.navbar-item:hover, .navbar.is-green-forest-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-green-forest-dark .navbar-brand .navbar-link:hover, .navbar.is-green-forest-dark .navbar-brand .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0000;
}

.navbar.is-green-forest-dark .navbar-brand .navbar-link:after {
  border-color: #fcfcfc;
}

@media screen and (width >= 990px) {
  .navbar.is-green-forest-dark .navbar-start > .navbar-item, .navbar.is-green-forest-dark .navbar-start .navbar-link, .navbar.is-green-forest-dark .navbar-end > .navbar-item, .navbar.is-green-forest-dark .navbar-end .navbar-link {
    color: #fcfcfc;
  }

  .navbar.is-green-forest-dark .navbar-start > a.navbar-item:hover, .navbar.is-green-forest-dark .navbar-start > a.navbar-item.is-active, .navbar.is-green-forest-dark .navbar-start .navbar-link:hover, .navbar.is-green-forest-dark .navbar-start .navbar-link.is-active, .navbar.is-green-forest-dark .navbar-end > a.navbar-item:hover, .navbar.is-green-forest-dark .navbar-end > a.navbar-item.is-active, .navbar.is-green-forest-dark .navbar-end .navbar-link:hover, .navbar.is-green-forest-dark .navbar-end .navbar-link.is-active {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-green-forest-dark .navbar-start .navbar-link:after, .navbar.is-green-forest-dark .navbar-end .navbar-link:after {
    border-color: #fcfcfc;
  }

  .navbar.is-green-forest-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-green-forest-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fcfcfc;
    background-color: #00261b;
  }

  .navbar.is-green-forest-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fcfcfc;
    background-color: #003f2d;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  height: 5rem;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 4px 4px #0000001a;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 35;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom.navbar-overlay, .navbar.is-fixed-top.navbar-overlay {
  z-index: 40;
  transition: all .75s cubic-bezier(.23, 1, .32, 1);
}

.navbar.is-fixed-bottom.navbar-overlay.is-offscreen, .navbar.is-fixed-top.navbar-overlay.is-offscreen {
  visibility: hidden;
  top: -5rem;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px 3px #1a1a1a1a;
}

.navbar.is-fixed-top {
  top: 0;
}

.navbar.is-fixed-top .navbar-brand, .navbar.is-fixed-top .navbar-item, .navbar.is-fixed-bottom .navbar-brand, .navbar.is-fixed-bottom .navbar-item {
  pointer-events: auto;
  white-space: nowrap;
  color: #003f2d;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: center;
  height: 5rem;
  display: flex;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  background-color: currentColor;
  width: 15px;
  height: 1px;
  margin-left: -7px;
  transition: background 86ms ease-out, left 86ms ease-out, opacity 86ms ease-out, transform 86ms ease-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.navbar-burger span:first-child {
  margin-top: -6px;
}

.navbar-burger span:nth-child(2) {
  margin-top: -1px;
}

.navbar-burger span:nth-child(3) {
  margin-top: 4px;
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:first-child {
  transform-origin: 0 0;
  margin-left: -5px;
  transform: rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform-origin: 0 100%;
  margin-left: -5px;
  transform: rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #fcfcfc;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: .5rem 1rem;
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  transition: all .75s cubic-bezier(.23, 1, .32, 1);
  display: block;
  position: relative;
}

.navbar-item:hover, .navbar-item.is-active, .navbar-link:hover, .navbar-link.is-active {
  background-color: #0000;
  color: #17e88f !important;
}

.navbar-menu .navbar-item {
  padding-left: 1.5rem;
  padding-right: 0;
}

.navbar-menu .navbar-item svg {
  margin-left: 8px;
}

.language-dropdown-top .underline-input, .language-dropdown-top-result .underline-input {
  border-bottom: none !important;
}

.navbar.is-transparent .language-dropdown-top-result .underline-input {
  color: #003f2d;
  border-bottom: none !important;
}

.navbar.is-transparent .language-dropdown-top-result .underline-input:hover {
  color: #17e88f;
}

.navbar.is-transparent {
  background-color: #0000;
}

.navbar.is-transparent .navbar-item, .navbar.is-transparent .navbar-item a, .navbar.is-pale-green .navbar-item, .navbar.is-pale-green .navbar-item a {
  color: #003f2d;
}

.navbar.is-transparent .navbar-item:hover, .navbar.is-transparent .navbar-item a:hover, .navbar.is-pale-green .navbar-item:hover, .navbar.is-pale-green .navbar-item a:hover {
  color: #12b8a2;
}

.navbar.in-quiz {
  background-color: #003f2d;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 2.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-tab {
  border-bottom: 1px solid #0000;
  height: 5rem;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #003f2d;
}

.navbar-item.is-tab.is-active {
  color: #003f2d;
  background-color: #0000;
  border-bottom: 3px solid #003f2d;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link {
  padding-right: 2.5em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #dbdbdb;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: none;
}

.navbar, .navbar-menu, .navbar-start, .navbar-end {
  align-items: stretch;
  display: flex;
}

.navbar {
  height: 5rem;
}

.navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
  background-color: #0000 !important;
}

.navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
  color: #003f2d;
  background-color: #fcfcfc;
}

.navbar-burger {
  display: none;
}

.navbar-item, .navbar-link {
  align-items: center;
  display: flex;
}

.navbar-item.flex-align-items-start {
  align-items: start;
}

.navbar-item.has-dropdown {
  align-items: stretch;
}

.navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
  display: block;
}

.navbar-item.is-active .navbar-dropdown.is-boxed, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.navbar-link:after {
  content: " ";
  pointer-events: none;
  border-top: 0;
  border-bottom: 1px solid #003f2d;
  border-left: 1px solid #003f2d;
  border-right: 0;
  width: .5em;
  height: .5em;
  margin-top: -.375em;
  display: block;
  position: absolute;
  top: 50%;
  right: 1.125em;
  transform: rotate(-45deg);
}

.navbar-menu {
  flex-grow: 1;
  flex-shrink: 0;
}

.navbar-start {
  justify-content: flex-start;
  margin-right: auto;
}

.navbar-end {
  justify-content: flex-end;
  margin-left: auto;
}

.navbar-dropdown {
  z-index: 20;
  background-color: #fcfcfc;
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-width: 100%;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 8px 8px #1a1a1a1a;
}

.navbar-dropdown .navbar-item {
  white-space: nowrap;
  padding: .375rem 1rem;
}

.navbar-dropdown a.navbar-item {
  padding-right: 3rem;
}

.navbar-dropdown a.navbar-item:hover {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.navbar-dropdown a.navbar-item.is-active {
  color: #003f2d;
  background-color: #fcfcfc;
}

.navbar-dropdown.is-boxed {
  opacity: 0;
  pointer-events: none;
  border-top: none;
  border-radius: 5px;
  transition-property: opacity, transform;
  transition-duration: 86ms;
  display: block;
  top: calc(100% - 4px);
  transform: translateY(-5px);
  box-shadow: 0 8px 8px #1a1a1a1a, 0 0 0 1px #1a1a1a1a;
}

.navbar-dropdown.is-right {
  left: auto;
  right: 0;
}

.navbar-divider {
  display: block;
}

.container > .navbar .navbar-brand {
  margin-left: -1rem;
}

.container > .navbar .navbar-menu {
  margin-right: -1rem;
}

a.navbar-item.is-active, .navbar-link.is-active {
  color: #1a1a1a;
}

a.navbar-item.is-active:not(:hover), .navbar-link.is-active:not(:hover), .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #0000;
}

.has-navbar-fixed-top {
  padding-top: 5rem;
}

.has-navbar-fixed-bottom {
  padding-bottom: 5rem;
}

.navbar-end .cta-button {
  padding: 1.5rem 2.5rem;
}

@media screen and (width >= 768px) {
  .navbar-menu .navbar-item.is-mobile-only {
    display: none;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .language-dropdown-top, .language-dropdown-top-result {
    display: none;
  }
}

@media screen and (width <= 767px) {
  .navbar, .navbar > .container {
    height: 3.5rem;
  }

  .navbar.is-transparent .navbar-item .cta-button {
    display: none;
  }

  .navbar.is-fixed-top.navbar-overlay.is-offscreen {
    top: -3.5rem;
  }

  .navbar-menu .navbar-item:not(.is-mobile) {
    display: none;
  }

  .navbar-item.is-mobile .button {
    font-size: .75rem;
  }

  .navbar-item {
    padding: 0 !important;
  }

  .navbar-item .button {
    font-size: .75rem;
  }

  .navbar-item .button.cta-button {
    min-width: unset;
    padding: 1rem;
  }

  .navbar-brand {
    height: 3.5rem;
  }

  .navbar-brand .navbar-item {
    padding: 0;
  }

  .navbar-end .navbar-item {
    padding-right: 0;
  }

  .has-navbar-fixed-top {
    padding-top: 3.5rem;
  }
}

.cbre-spacer-logo {
  color: #003f2d;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}

.navbar-brand .cbre-spacer-logo:focus {
  background-color: #0000 !important;
}

.blx-buttonblx-primary:hover {
  background-color: #17e88f;
  border-color: #17e88f;
}

.pagination {
  margin: -.25rem;
  font-size: 1rem;
}

.pagination.is-small {
  font-size: .75rem;
}

.pagination.is-medium {
  font-size: 1.25rem;
}

.pagination.is-large {
  font-size: 1.5rem;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 2.25em;
  margin: .25rem;
  padding: calc(.375em - 1px) .5em;
  font-size: 1em;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.pagination-previous:focus, .pagination-previous.is-focused, .pagination-previous:active, .pagination-previous.is-active, .pagination-next:focus, .pagination-next.is-focused, .pagination-next:active, .pagination-next.is-active, .pagination-link:focus, .pagination-link.is-focused, .pagination-link:active, .pagination-link.is-active, .pagination-ellipsis:focus, .pagination-ellipsis.is-focused, .pagination-ellipsis:active, .pagination-ellipsis.is-active {
  outline: none;
}

.pagination-previous[disabled], .pagination-next[disabled], .pagination-link[disabled], .pagination-ellipsis[disabled] {
  pointer-events: none;
}

.pagination-previous, .pagination-next, .pagination-link {
  border-color: #dbdbdb;
  min-width: 2.25em;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover {
  color: #17e88f;
  border-color: #b5b5b5;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  border-color: #03d;
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: inset 0 1px 2px #1a1a1a33;
}

.pagination-previous[disabled], .pagination-next[disabled], .pagination-link[disabled] {
  box-shadow: none;
  color: #666;
  opacity: .5;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.pagination-previous, .pagination-next {
  white-space: nowrap;
  padding-left: .75em;
  padding-right: .75em;
}

.pagination-link.is-current {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.pagination-ellipsis {
  color: #b5b5b5;
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

@media screen and (width <= 767px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-previous, .pagination-next, .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (width >= 768px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  font-size: 1rem;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel-heading, .panel-tabs, .panel-block {
  border-bottom: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}

.panel-heading:first-child, .panel-tabs:first-child, .panel-block:first-child {
  border-top: 1px solid #dbdbdb;
}

.panel-heading {
  color: #434a48;
  background-color: #fcfcfc;
  border-radius: 4px 4px 0 0;
  padding: .5em .75em;
  font-size: 1.25em;
  font-weight: 300;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #434a48;
  border-bottom-color: #4a4a4a;
}

.panel-list a {
  color: #1a1a1a;
}

.panel-list a:hover {
  color: #003f2d;
}

.panel-block {
  color: #434a48;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-right: .75em;
}

.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #434a48;
  border-left-color: #003f2d;
}

.panel-block.is-active .panel-icon {
  color: #003f2d;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #fcfcfc;
}

.panel-icon {
  text-align: center;
  vertical-align: top;
  color: #666;
  width: 1em;
  height: 1em;
  margin-right: .75em;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fcfcfc33;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 1rem;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress:not(:last-child) {
  margin-bottom: 1.5rem;
}

.progress::-webkit-progress-bar {
  background-color: #fcfcfc33;
}

.progress::-webkit-progress-value {
  background-color: #1a1a1a;
}

.progress::-moz-progress-bar {
  background-color: #1a1a1a;
}

.progress::-ms-fill {
  background-color: #1a1a1a;
  border: none;
}

.progress.is-white::-webkit-progress-value {
  background-color: #fcfcfc;
}

.progress.is-white::-moz-progress-bar {
  background-color: #fcfcfc;
}

.progress.is-white::-ms-fill {
  background-color: #fcfcfc;
}

.progress.is-black::-webkit-progress-value {
  background-color: #1a1a1a;
}

.progress.is-black::-moz-progress-bar {
  background-color: #1a1a1a;
}

.progress.is-black::-ms-fill {
  background-color: #1a1a1a;
}

.progress.is-light::-webkit-progress-value {
  background-color: #f0f0f0;
}

.progress.is-light::-moz-progress-bar {
  background-color: #f0f0f0;
}

.progress.is-light::-ms-fill {
  background-color: #f0f0f0;
}

.progress.is-dark::-webkit-progress-value {
  background-color: #434a48;
}

.progress.is-dark::-moz-progress-bar {
  background-color: #434a48;
}

.progress.is-dark::-ms-fill {
  background-color: #434a48;
}

.progress.is-primary::-webkit-progress-value {
  background-color: #003f2d;
}

.progress.is-primary::-moz-progress-bar {
  background-color: #003f2d;
}

.progress.is-primary::-ms-fill {
  background-color: #003f2d;
}

.progress.is-secondary::-webkit-progress-value {
  background-color: #d6e3df;
}

.progress.is-secondary::-moz-progress-bar {
  background-color: #d6e3df;
}

.progress.is-secondary::-ms-fill {
  background-color: #d6e3df;
}

.progress.is-tertiary::-webkit-progress-value {
  background-color: #0d8070;
}

.progress.is-tertiary::-moz-progress-bar {
  background-color: #0d8070;
}

.progress.is-tertiary::-ms-fill {
  background-color: #0d8070;
}

.progress.is-primary-invert::-webkit-progress-value {
  background-color: #fcfcfc;
}

.progress.is-primary-invert::-moz-progress-bar {
  background-color: #fcfcfc;
}

.progress.is-primary-invert::-ms-fill {
  background-color: #fcfcfc;
}

.progress.is-link::-webkit-progress-value {
  background-color: #003f2d;
}

.progress.is-link::-moz-progress-bar {
  background-color: #003f2d;
}

.progress.is-link::-ms-fill {
  background-color: #003f2d;
}

.progress.is-info::-webkit-progress-value {
  background-color: #00b2dd;
}

.progress.is-info::-moz-progress-bar {
  background-color: #00b2dd;
}

.progress.is-info::-ms-fill {
  background-color: #00b2dd;
}

.progress.is-pale-green::-webkit-progress-value {
  background-color: #d6e3df;
}

.progress.is-pale-green::-moz-progress-bar {
  background-color: #d6e3df;
}

.progress.is-pale-green::-ms-fill {
  background-color: #d6e3df;
}

.progress.is-success::-webkit-progress-value {
  background-color: #50e3c2;
}

.progress.is-success::-moz-progress-bar {
  background-color: #50e3c2;
}

.progress.is-success::-ms-fill {
  background-color: #50e3c2;
}

.progress.is-warning::-webkit-progress-value {
  background-color: #ffdd57;
}

.progress.is-warning::-moz-progress-bar {
  background-color: #ffdd57;
}

.progress.is-warning::-ms-fill {
  background-color: #ffdd57;
}

.progress.is-danger::-webkit-progress-value {
  background-color: #fc5328;
}

.progress.is-danger::-moz-progress-bar {
  background-color: #fc5328;
}

.progress.is-danger::-ms-fill {
  background-color: #fc5328;
}

.progress.is-me::-webkit-progress-value {
  background-color: #00d2a0;
}

.progress.is-me::-moz-progress-bar {
  background-color: #00d2a0;
}

.progress.is-me::-ms-fill {
  background-color: #00d2a0;
}

.progress.is-we::-webkit-progress-value {
  background-color: #00b2dd;
}

.progress.is-we::-moz-progress-bar {
  background-color: #00b2dd;
}

.progress.is-we::-ms-fill {
  background-color: #00b2dd;
}

.progress.is-amenity::-webkit-progress-value {
  background-color: #7d7ccf;
}

.progress.is-amenity::-moz-progress-bar {
  background-color: #7d7ccf;
}

.progress.is-amenity::-ms-fill {
  background-color: #7d7ccf;
}

.progress.is-light-pale-green::-webkit-progress-value {
  background-color: #eaf1ef;
}

.progress.is-light-pale-green::-moz-progress-bar {
  background-color: #eaf1ef;
}

.progress.is-light-pale-green::-ms-fill {
  background-color: #eaf1ef;
}

.progress.is-green-forest-darker::-webkit-progress-value {
  background-color: #1d4837;
}

.progress.is-green-forest-darker::-moz-progress-bar {
  background-color: #1d4837;
}

.progress.is-green-forest-darker::-ms-fill {
  background-color: #1d4837;
}

.progress.is-green-forest-dark::-webkit-progress-value {
  background-color: #003f2d;
}

.progress.is-green-forest-dark::-moz-progress-bar {
  background-color: #003f2d;
}

.progress.is-green-forest-dark::-ms-fill {
  background-color: #003f2d;
}

.progress.is-small {
  height: .75rem;
}

.progress.is-medium {
  height: 1.25rem;
}

.progress.is-large {
  height: 1.5rem;
}

.program-bar-chart {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.program-bar-chart .title, .program-bar-chart .subtitle {
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif;
}

.program-bar-chart .program-bar-chart-segment-amount, .program-bar-chart .program-bar-chart-segment-percentage, .program-bar-chart .program-bar-chart-segment-subtitle {
  white-space: nowrap;
}

.program-bar-chart .program-bar-chart-segment-percentage {
  font-size: 2rem;
}

@media screen and (width <= 767px) {
  .program-bar-chart .program-bar-chart-segment-percentage {
    font-size: 1.8rem;
  }
}

.program-bar-chart .program-bar-chart-segment-amount {
  margin-bottom: .6rem;
}

.program-bar-chart .program-bar-chart-bar {
  width: 100%;
  height: 2.5rem;
}

.program-bar-chart .program-bar-chart-segment-subtitle {
  text-overflow: ellipsis;
  max-width: 150px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.program-bar-chart .program-bar-chart-segment-amount, .program-bar-chart .program-bar-chart-segment-explanation {
  font-weight: 100;
}

.program-bar-chart .group-space-small {
  margin-left: 15px;
}

.program-bar-chart .group-space-xs {
  margin-left: 35px;
}

.program-bar-chart .not-group-space {
  margin-left: 40px;
}

.program-bar-chart .not-group-space-small {
  margin-right: 15px;
}

.program-bar-chart .not-group-space-xs {
  margin-right: 35px;
}

.program-bar-chart-empty {
  background-color: #ecf5f1;
  border: 1px solid #aab7b2;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 0 25%;
  display: flex;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow: auto hidden;
}

.tabs:not(:last-child) {
  margin-bottom: 1.5rem;
}

.tabs a {
  color: #1a1a1a;
  vertical-align: top;
  border-bottom: 1px solid #dbdbdb;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  padding: .5em 1em;
  display: flex;
}

.tabs a:hover {
  color: #434a48;
  border-bottom-color: #434a48;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #003f2d;
  border-bottom-color: #003f2d;
}

.tabs ul {
  border-bottom: 1px solid #dbdbdb;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
}

.tabs.is-boxed a:hover {
  background-color: #fcfcfc;
  border-bottom-color: #dbdbdb;
}

.tabs.is-boxed li.is-active a {
  background-color: #fcfcfc;
  border-color: #dbdbdb;
  border-bottom-color: #0000 !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border: 1px solid #dbdbdb;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #fcfcfc;
  border-color: #b5b5b5;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-radius: 4px 0 0 4px;
}

.tabs.is-toggle li:last-child a {
  border-radius: 0 4px 4px 0;
}

.tabs.is-toggle li.is-active a {
  color: #fcfcfc;
  z-index: 1;
  background-color: #003f2d;
  border-color: #003f2d;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-small {
  font-size: .75rem;
}

.tabs.is-medium {
  font-size: 1.25rem;
}

.tabs.is-large {
  font-size: 1.5rem;
}

.tooltip {
  padding: 0 .25rem;
  position: relative;
}

.tooltip.has-icon {
  display: inline-flex;
}

.tooltip:hover:not(.is-loading):after, .tooltip.is-tooltip-active:not(.is-loading):after, .tooltip:hover:before, .tooltip.is-tooltip-active:before {
  z-index: 20;
  pointer-events: none;
  display: inline-block;
  position: absolute;
}

.tooltip:hover:not(.is-loading):after, .tooltip.is-tooltip-active:not(.is-loading):after {
  content: "";
  border-style: solid;
  border-width: .5rem;
}

.tooltip:hover:before, .tooltip.is-tooltip-active:before {
  opacity: 0;
  content: attr(data-tooltip);
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fcfcfc;
  background: #4a4a4a;
  border-radius: 4px;
  max-width: 24rem;
  padding: .4rem .8rem;
  font-size: .75rem;
  overflow: hidden;
  transform: translate(-50%, 1rem);
}

.tooltip:focus:not(.is-loading):after, .tooltip:hover:not(.is-loading):after, .tooltip.is-tooltip-active:not(.is-loading):after {
  opacity: 1;
  border-color: #4a4a4a #0000 #0000;
  margin-top: -.5rem;
  margin-left: -.5rem;
  top: 0;
  bottom: auto;
  left: 50%;
}

.tooltip:focus:before, .tooltip:hover:before, .tooltip.is-tooltip-active:before {
  opacity: 1;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -.5rem);
}

.tooltip.is-tooltip-right:before {
  inset: auto auto 50% 100%;
  transform: translate(-1rem, 50%);
}

.tooltip.is-tooltip-right:focus:not(.is-loading):after, .tooltip.is-tooltip-right:hover:not(.is-loading):after, .tooltip.is-tooltip-right.is-tooltip-active:not(.is-loading):after {
  border-color: #0000 #4a4a4a #0000 #0000;
  top: 50%;
  left: 100%;
  right: auto;
}

.tooltip.is-tooltip-right:focus:before, .tooltip.is-tooltip-right:hover:before, .tooltip.is-tooltip-right.is-tooltip-active:before {
  transform: translate(.5rem, 50%);
}

.tooltip.is-tooltip-bottom:before {
  inset: 100% auto auto 50%;
  transform: translate(-50%, -1rem);
}

.tooltip.is-tooltip-bottom:focus:not(.is-loading):after, .tooltip.is-tooltip-bottom:hover:not(.is-loading):after, .tooltip.is-tooltip-bottom.is-tooltip-active:not(.is-loading):after {
  border-color: #0000 #0000 #4a4a4a;
  inset: 100% auto auto 50%;
}

.tooltip.is-tooltip-bottom:focus:before, .tooltip.is-tooltip-bottom:hover:before, .tooltip.is-tooltip-bottom.is-tooltip-active:before {
  transform: translate(-50%, .5rem);
}

.tooltip.is-tooltip-left:before {
  inset: auto 100% 50% auto;
  transform: translate(1rem, 50%);
}

.tooltip.is-tooltip-left:focus:not(.is-loading):after, .tooltip.is-tooltip-left:hover:not(.is-loading):after, .tooltip.is-tooltip-left.is-tooltip-active:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #4a4a4a;
  top: 50%;
  left: auto;
  right: calc(100% - .5rem);
}

.tooltip.is-tooltip-left:focus:before, .tooltip.is-tooltip-left:hover:before, .tooltip.is-tooltip-left.is-tooltip-active:before {
  transform: translate(-.5rem, 50%);
}

.tooltip.is-tooltip-multiline:before {
  text-overflow: clip;
  white-space: normal;
  word-break: keep-all;
  min-width: 24rem;
}

.tooltip.is-tooltip-white:not(.is-loading):after {
  border-color: #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-white.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-white.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-white.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-white:before {
  color: #1a1a1a;
  background: #fcfcfc;
}

.tooltip.is-tooltip-black:not(.is-loading):after {
  border-color: #1a1a1a #0000 #0000;
}

.tooltip.is-tooltip-black.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #1a1a1a #0000 #0000;
}

.tooltip.is-tooltip-black.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #1a1a1a;
}

.tooltip.is-tooltip-black.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #1a1a1a;
}

.tooltip.is-tooltip-black:before {
  color: #fcfcfc;
  background: #1a1a1a;
}

.tooltip.is-tooltip-light:not(.is-loading):after {
  border-color: #f0f0f0 #0000 #0000;
}

.tooltip.is-tooltip-light.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #f0f0f0 #0000 #0000;
}

.tooltip.is-tooltip-light.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #f0f0f0;
}

.tooltip.is-tooltip-light.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #f0f0f0;
}

.tooltip.is-tooltip-light:before {
  color: #434a48;
  background: #f0f0f0;
}

.tooltip.is-tooltip-dark:not(.is-loading):after {
  border-color: #434a48 #0000 #0000;
}

.tooltip.is-tooltip-dark.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #434a48 #0000 #0000;
}

.tooltip.is-tooltip-dark.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #434a48;
}

.tooltip.is-tooltip-dark.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #434a48;
}

.tooltip.is-tooltip-dark:before {
  color: #f0f0f0;
  background: #434a48;
}

.tooltip.is-tooltip-primary:not(.is-loading):after {
  border-color: #003f2d #0000 #0000;
}

.tooltip.is-tooltip-primary.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #003f2d #0000 #0000;
}

.tooltip.is-tooltip-primary.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-primary.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-primary:before {
  color: #fcfcfc;
  background: #003f2d;
}

.tooltip.is-tooltip-secondary:not(.is-loading):after {
  border-color: #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-secondary.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-secondary.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-secondary.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-secondary:before {
  color: #1a1a1a;
  background: #d6e3df;
}

.tooltip.is-tooltip-tertiary:not(.is-loading):after {
  border-color: #0d8070 #0000 #0000;
}

.tooltip.is-tooltip-tertiary.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #0d8070 #0000 #0000;
}

.tooltip.is-tooltip-tertiary.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #0d8070;
}

.tooltip.is-tooltip-tertiary.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #0d8070;
}

.tooltip.is-tooltip-tertiary:before {
  color: #fcfcfc;
  background: #0d8070;
}

.tooltip.is-tooltip-primary-invert:not(.is-loading):after {
  border-color: #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-primary-invert.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #fcfcfc #0000 #0000;
}

.tooltip.is-tooltip-primary-invert.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-primary-invert.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #fcfcfc;
}

.tooltip.is-tooltip-primary-invert:before {
  color: #003f2d;
  background: #fcfcfc;
}

.tooltip.is-tooltip-link:not(.is-loading):after {
  border-color: #003f2d #0000 #0000;
}

.tooltip.is-tooltip-link.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #003f2d #0000 #0000;
}

.tooltip.is-tooltip-link.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-link.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-link:before {
  color: #fcfcfc;
  background: #003f2d;
}

.tooltip.is-tooltip-info:not(.is-loading):after {
  border-color: #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-info.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-info.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-info.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-info:before {
  color: #fcfcfc;
  background: #00b2dd;
}

.tooltip.is-tooltip-pale-green:not(.is-loading):after {
  border-color: #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-pale-green.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #d6e3df #0000 #0000;
}

.tooltip.is-tooltip-pale-green.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-pale-green.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #d6e3df;
}

.tooltip.is-tooltip-pale-green:before {
  color: #1a1a1a;
  background: #d6e3df;
}

.tooltip.is-tooltip-success:not(.is-loading):after {
  border-color: #50e3c2 #0000 #0000;
}

.tooltip.is-tooltip-success.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #50e3c2 #0000 #0000;
}

.tooltip.is-tooltip-success.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #50e3c2;
}

.tooltip.is-tooltip-success.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #50e3c2;
}

.tooltip.is-tooltip-success:before {
  color: #fcfcfc;
  background: #50e3c2;
}

.tooltip.is-tooltip-warning:not(.is-loading):after {
  border-color: #ffdd57 #0000 #0000;
}

.tooltip.is-tooltip-warning.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #ffdd57 #0000 #0000;
}

.tooltip.is-tooltip-warning.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #ffdd57;
}

.tooltip.is-tooltip-warning.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #ffdd57;
}

.tooltip.is-tooltip-warning:before {
  color: #1a1a1ab3;
  background: #ffdd57;
}

.tooltip.is-tooltip-danger:not(.is-loading):after {
  border-color: #fc5328 #0000 #0000;
}

.tooltip.is-tooltip-danger.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #fc5328 #0000 #0000;
}

.tooltip.is-tooltip-danger.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #fc5328;
}

.tooltip.is-tooltip-danger.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #fc5328;
}

.tooltip.is-tooltip-danger:before {
  color: #fcfcfc;
  background: #fc5328;
}

.tooltip.is-tooltip-me:not(.is-loading):after {
  border-color: #00d2a0 #0000 #0000;
}

.tooltip.is-tooltip-me.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #00d2a0 #0000 #0000;
}

.tooltip.is-tooltip-me.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #00d2a0;
}

.tooltip.is-tooltip-me.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #00d2a0;
}

.tooltip.is-tooltip-me:before {
  color: #fcfcfc;
  background: #00d2a0;
}

.tooltip.is-tooltip-we:not(.is-loading):after {
  border-color: #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-we.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #00b2dd #0000 #0000;
}

.tooltip.is-tooltip-we.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-we.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #00b2dd;
}

.tooltip.is-tooltip-we:before {
  color: #fcfcfc;
  background: #00b2dd;
}

.tooltip.is-tooltip-amenity:not(.is-loading):after {
  border-color: #7d7ccf #0000 #0000;
}

.tooltip.is-tooltip-amenity.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #7d7ccf #0000 #0000;
}

.tooltip.is-tooltip-amenity.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #7d7ccf;
}

.tooltip.is-tooltip-amenity.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #7d7ccf;
}

.tooltip.is-tooltip-amenity:before {
  color: #fcfcfc;
  background: #7d7ccf;
}

.tooltip.is-tooltip-light-pale-green:not(.is-loading):after {
  border-color: #eaf1ef #0000 #0000;
}

.tooltip.is-tooltip-light-pale-green.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #eaf1ef #0000 #0000;
}

.tooltip.is-tooltip-light-pale-green.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #eaf1ef;
}

.tooltip.is-tooltip-light-pale-green.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #eaf1ef;
}

.tooltip.is-tooltip-light-pale-green:before {
  color: #1a1a1a;
  background: #eaf1ef;
}

.tooltip.is-tooltip-green-forest-darker:not(.is-loading):after {
  border-color: #1d4837 #0000 #0000;
}

.tooltip.is-tooltip-green-forest-darker.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #1d4837 #0000 #0000;
}

.tooltip.is-tooltip-green-forest-darker.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #1d4837;
}

.tooltip.is-tooltip-green-forest-darker.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #1d4837;
}

.tooltip.is-tooltip-green-forest-darker:before {
  color: #fcfcfc;
  background: #1d4837;
}

.tooltip.is-tooltip-green-forest-dark:not(.is-loading):after {
  border-color: #003f2d #0000 #0000;
}

.tooltip.is-tooltip-green-forest-dark.is-tooltip-right:not(.is-loading):after {
  border-color: #0000 #003f2d #0000 #0000;
}

.tooltip.is-tooltip-green-forest-dark.is-tooltip-bottom:not(.is-loading):after {
  border-color: #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-green-forest-dark.is-tooltip-left:not(.is-loading):after {
  border-color: #0000 #0000 #0000 #003f2d;
}

.tooltip.is-tooltip-green-forest-dark:before {
  color: #fcfcfc;
  background: #003f2d;
}

.slider-wrapper {
  text-align: center;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 700px;
  display: flex;
}

.slider-item {
  width: 80%;
  padding-right: 3%;
}

.slider-custom {
  -webkit-appearance: none;
  -webkit-user-select: none;
  opacity: 1;
  background: none;
  border: 6px solid #00b2dd;
  border-radius: 20px;
  outline: none;
  width: 102%;
  height: 2px;
  padding: 0;
  -webkit-transition: all .2s;
}

.slider-custom::-webkit-slider-thumb {
  -webkit-appearance: none;
  object-fit: contain;
  cursor: pointer;
  background: url("headcount-slider-icon-spacer-by-cbre.3d9c70ae.svg");
  border: none;
  width: 35px;
  height: 32px;
}

.slider-custom::-moz-range-track {
  background: none;
}

.slider-custom::-moz-range-thumb {
  -moz-appearance: none;
  cursor: pointer;
  background: url("headcount-slider-icon-spacer-by-cbre.3d9c70ae.svg");
  border: 0;
  border-radius: 0;
  width: 35px;
  height: 32px;
}

.slider-custom::-moz-focus-outer {
  border: none;
  outline: none;
}

.slider-text {
  letter-spacing: .5px;
  color: #fcfcfc;
  width: 10%;
  height: 25px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.39;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif !important;
}

.box {
  color: #1a1a1a;
  background-color: #fcfcfc;
  border-radius: 5px;
  padding: 1.25rem;
  display: block;
  box-shadow: 0 2px 3px #1a1a1a1a, 0 0 0 1px #1a1a1a1a;
}

.box:not(:last-child) {
  margin-bottom: 1.5rem;
}

a.box:hover, a.box:focus {
  box-shadow: 0 2px 3px #1a1a1a1a, 0 0 0 1px #003f2d;
}

a.box:active {
  box-shadow: inset 0 1px 2px #1a1a1a33, 0 0 0 1px #003f2d;
}

.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  color: #434a48;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fcfcfc;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 2.25em;
  padding: calc(.375em - 1px) .75em;
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1;
  transition: all .75s cubic-bezier(.23, 1, .32, 1);
  display: inline-flex;
  position: relative;
  border-radius: 4px !important;
}

.button:focus, .button.is-focused, .button:active, .button.is-active {
  outline: none;
}

.button[disabled] {
  pointer-events: none;
}

.button strong {
  color: inherit;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.375em - 1px);
  margin-right: .1875em;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: calc(-.375em - 1px);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.375em - 1px);
  margin-right: calc(-.375em - 1px);
}

.button.has-icon-after:after {
  padding-left: .75em;
  line-height: 1.5;
}

.button:hover, .button.is-hovered {
  color: #17e88f;
  border-color: #b5b5b5;
}

.button:focus:not(.no-focus-style), .button.is-focused:not(.no-focus-style) {
  border-color: #03d;
}

.button:focus:not(.no-focus-style):not(:active), .button.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.button:active, .button.is-active {
  color: #434a48;
  border-color: #4a4a4a;
}

.button.is-text {
  color: #1a1a1a;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
  background-color: #fcfcfc;
}

.button.is-text:active, .button.is-text.is-active {
  background-color: #efefef;
}

.button.is-text[disabled] {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-white:hover, .button.is-white.is-hovered {
  color: #1a1a1a;
  background-color: #f6f6f6;
  border-color: #0000;
}

.button.is-white:focus:not(.no-focus-style), .button.is-white.is-focused:not(.no-focus-style) {
  color: #1a1a1a;
  border-color: #0000;
}

.button.is-white:focus:not(.no-focus-style):not(:active), .button.is-white.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #fcfcfc40;
}

.button.is-white:active, .button.is-white.is-active {
  color: #1a1a1a;
  background-color: #efefef;
  border-color: #0000;
}

.button.is-white[disabled] {
  box-shadow: none;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-white.is-inverted {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.button.is-white.is-inverted:hover {
  background-color: #0d0d0d;
}

.button.is-white.is-inverted[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #1a1a1a;
  border-color: #0000;
}

.button.is-white.is-loading:after {
  border-color: #0000 #0000 #1a1a1a #1a1a1a !important;
}

.button.is-white.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-white.is-outlined:hover, .button.is-white.is-outlined:focus {
  color: #1a1a1a;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-white.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-white.is-inverted.is-outlined {
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined:focus {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.button.is-white.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
  border-color: #0000;
}

.button.is-black:hover, .button.is-black.is-hovered {
  color: #fcfcfc;
  background-color: #141414;
  border-color: #0000;
}

.button.is-black:focus:not(.no-focus-style), .button.is-black.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-black:focus:not(.no-focus-style):not(:active), .button.is-black.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #1a1a1a40;
}

.button.is-black:active, .button.is-black.is-active {
  color: #fcfcfc;
  background-color: #0d0d0d;
  border-color: #0000;
}

.button.is-black[disabled] {
  box-shadow: none;
  background-color: #1a1a1a;
  border-color: #0000;
}

.button.is-black.is-inverted {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.button.is-black.is-inverted:hover {
  background-color: #efefef;
}

.button.is-black.is-inverted[disabled] {
  box-shadow: none;
  color: #1a1a1a;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-black.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-black.is-outlined {
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-black.is-outlined:hover, .button.is-black.is-outlined:focus {
  color: #fcfcfc;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: #0000 #0000 #1a1a1a #1a1a1a !important;
}

.button.is-black.is-outlined[disabled] {
  box-shadow: none;
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-black.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined:focus {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.button.is-black.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-light {
  color: #434a48;
  background-color: #f0f0f0;
  border-color: #0000;
}

.button.is-light:hover, .button.is-light.is-hovered {
  color: #434a48;
  background-color: #e9e9e9;
  border-color: #0000;
}

.button.is-light:focus:not(.no-focus-style), .button.is-light.is-focused:not(.no-focus-style) {
  color: #434a48;
  border-color: #0000;
}

.button.is-light:focus:not(.no-focus-style):not(:active), .button.is-light.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #f0f0f040;
}

.button.is-light:active, .button.is-light.is-active {
  color: #434a48;
  background-color: #e3e3e3;
  border-color: #0000;
}

.button.is-light[disabled] {
  box-shadow: none;
  background-color: #f0f0f0;
  border-color: #0000;
}

.button.is-light.is-inverted {
  color: #f0f0f0;
  background-color: #434a48;
}

.button.is-light.is-inverted:hover {
  background-color: #373d3b;
}

.button.is-light.is-inverted[disabled] {
  box-shadow: none;
  color: #f0f0f0;
  background-color: #434a48;
  border-color: #0000;
}

.button.is-light.is-loading:after {
  border-color: #0000 #0000 #434a48 #434a48 !important;
}

.button.is-light.is-outlined {
  color: #f0f0f0;
  background-color: #0000;
  border-color: #f0f0f0;
}

.button.is-light.is-outlined:hover, .button.is-light.is-outlined:focus {
  color: #434a48;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f0f0f0 #f0f0f0 !important;
}

.button.is-light.is-outlined[disabled] {
  box-shadow: none;
  color: #f0f0f0;
  background-color: #0000;
  border-color: #f0f0f0;
}

.button.is-light.is-inverted.is-outlined {
  color: #434a48;
  background-color: #0000;
  border-color: #434a48;
}

.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined:focus {
  color: #f0f0f0;
  background-color: #434a48;
}

.button.is-light.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #434a48;
  background-color: #0000;
  border-color: #434a48;
}

.button.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
  border-color: #0000;
}

.button.is-dark:hover, .button.is-dark.is-hovered {
  color: #f0f0f0;
  background-color: #3d4341;
  border-color: #0000;
}

.button.is-dark:focus:not(.no-focus-style), .button.is-dark.is-focused:not(.no-focus-style) {
  color: #f0f0f0;
  border-color: #0000;
}

.button.is-dark:focus:not(.no-focus-style):not(:active), .button.is-dark.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #434a4840;
}

.button.is-dark:active, .button.is-dark.is-active {
  color: #f0f0f0;
  background-color: #373d3b;
  border-color: #0000;
}

.button.is-dark[disabled] {
  box-shadow: none;
  background-color: #434a48;
  border-color: #0000;
}

.button.is-dark.is-inverted {
  color: #434a48;
  background-color: #f0f0f0;
}

.button.is-dark.is-inverted:hover {
  background-color: #e3e3e3;
}

.button.is-dark.is-inverted[disabled] {
  box-shadow: none;
  color: #434a48;
  background-color: #f0f0f0;
  border-color: #0000;
}

.button.is-dark.is-loading:after {
  border-color: #0000 #0000 #f0f0f0 #f0f0f0 !important;
}

.button.is-dark.is-outlined {
  color: #434a48;
  background-color: #0000;
  border-color: #434a48;
}

.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined:focus {
  color: #f0f0f0;
  background-color: #434a48;
  border-color: #434a48;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #434a48 #434a48 !important;
}

.button.is-dark.is-outlined[disabled] {
  box-shadow: none;
  color: #434a48;
  background-color: #0000;
  border-color: #434a48;
}

.button.is-dark.is-inverted.is-outlined {
  color: #f0f0f0;
  background-color: #0000;
  border-color: #f0f0f0;
}

.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined:focus {
  color: #434a48;
  background-color: #f0f0f0;
}

.button.is-dark.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #f0f0f0;
  background-color: #0000;
  border-color: #f0f0f0;
}

.button.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
  color: #fcfcfc;
  background-color: #003224;
  border-color: #0000;
}

.button.is-primary:focus:not(.no-focus-style), .button.is-primary.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-primary:focus:not(.no-focus-style):not(:active), .button.is-primary.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.button.is-primary:active, .button.is-primary.is-active {
  color: #fcfcfc;
  background-color: #00261b;
  border-color: #0000;
}

.button.is-primary[disabled] {
  box-shadow: none;
  background-color: #003f2d;
  border-color: #0000;
}

.button.is-primary.is-inverted {
  color: #003f2d;
  background-color: #fcfcfc;
}

.button.is-primary.is-inverted:hover {
  background-color: #efefef;
}

.button.is-primary.is-inverted[disabled] {
  box-shadow: none;
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-primary.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-primary.is-outlined {
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined:focus {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #003f2d #003f2d !important;
}

.button.is-primary.is-outlined[disabled] {
  box-shadow: none;
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined:focus {
  color: #003f2d;
  background-color: #fcfcfc;
}

.button.is-primary.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #0000;
}

.button.is-secondary:hover, .button.is-secondary.is-hovered {
  color: #1a1a1a;
  background-color: #ceded9;
  border-color: #0000;
}

.button.is-secondary:focus:not(.no-focus-style), .button.is-secondary.is-focused:not(.no-focus-style) {
  color: #1a1a1a;
  border-color: #0000;
}

.button.is-secondary:focus:not(.no-focus-style):not(:active), .button.is-secondary.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #d6e3df40;
}

.button.is-secondary:active, .button.is-secondary.is-active {
  color: #1a1a1a;
  background-color: #c7d9d3;
  border-color: #0000;
}

.button.is-secondary[disabled] {
  box-shadow: none;
  background-color: #d6e3df;
  border-color: #0000;
}

.button.is-secondary.is-inverted {
  color: #d6e3df;
  background-color: #1a1a1a;
}

.button.is-secondary.is-inverted:hover {
  background-color: #0d0d0d;
}

.button.is-secondary.is-inverted[disabled] {
  box-shadow: none;
  color: #d6e3df;
  background-color: #1a1a1a;
  border-color: #0000;
}

.button.is-secondary.is-loading:after {
  border-color: #0000 #0000 #1a1a1a #1a1a1a !important;
}

.button.is-secondary.is-outlined {
  color: #d6e3df;
  background-color: #0000;
  border-color: #d6e3df;
}

.button.is-secondary.is-outlined:hover, .button.is-secondary.is-outlined:focus {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #d6e3df;
}

.button.is-secondary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #d6e3df #d6e3df !important;
}

.button.is-secondary.is-outlined[disabled] {
  box-shadow: none;
  color: #d6e3df;
  background-color: #0000;
  border-color: #d6e3df;
}

.button.is-secondary.is-inverted.is-outlined {
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-secondary.is-inverted.is-outlined:hover, .button.is-secondary.is-inverted.is-outlined:focus {
  color: #d6e3df;
  background-color: #1a1a1a;
}

.button.is-secondary.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
  border-color: #0000;
}

.button.is-tertiary:hover, .button.is-tertiary.is-hovered {
  color: #fcfcfc;
  background-color: #0c7466;
  border-color: #0000;
}

.button.is-tertiary:focus:not(.no-focus-style), .button.is-tertiary.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-tertiary:focus:not(.no-focus-style):not(:active), .button.is-tertiary.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #0d807040;
}

.button.is-tertiary:active, .button.is-tertiary.is-active {
  color: #fcfcfc;
  background-color: #0b695c;
  border-color: #0000;
}

.button.is-tertiary[disabled] {
  box-shadow: none;
  background-color: #0d8070;
  border-color: #0000;
}

.button.is-tertiary.is-inverted {
  color: #0d8070;
  background-color: #fcfcfc;
}

.button.is-tertiary.is-inverted:hover {
  background-color: #efefef;
}

.button.is-tertiary.is-inverted[disabled] {
  box-shadow: none;
  color: #0d8070;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-tertiary.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-tertiary.is-outlined {
  color: #0d8070;
  background-color: #0000;
  border-color: #0d8070;
}

.button.is-tertiary.is-outlined:hover, .button.is-tertiary.is-outlined:focus {
  color: #fcfcfc;
  background-color: #0d8070;
  border-color: #0d8070;
}

.button.is-tertiary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #0d8070 #0d8070 !important;
}

.button.is-tertiary.is-outlined[disabled] {
  box-shadow: none;
  color: #0d8070;
  background-color: #0000;
  border-color: #0d8070;
}

.button.is-tertiary.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-tertiary.is-inverted.is-outlined:hover, .button.is-tertiary.is-inverted.is-outlined:focus {
  color: #0d8070;
  background-color: #fcfcfc;
}

.button.is-tertiary.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-primary-invert:hover, .button.is-primary-invert.is-hovered {
  color: #003f2d;
  background-color: #f6f6f6;
  border-color: #0000;
}

.button.is-primary-invert:focus:not(.no-focus-style), .button.is-primary-invert.is-focused:not(.no-focus-style) {
  color: #003f2d;
  border-color: #0000;
}

.button.is-primary-invert:focus:not(.no-focus-style):not(:active), .button.is-primary-invert.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #fcfcfc40;
}

.button.is-primary-invert:active, .button.is-primary-invert.is-active {
  color: #003f2d;
  background-color: #efefef;
  border-color: #0000;
}

.button.is-primary-invert[disabled] {
  box-shadow: none;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-primary-invert.is-inverted {
  color: #fcfcfc;
  background-color: #003f2d;
}

.button.is-primary-invert.is-inverted:hover {
  background-color: #00261b;
}

.button.is-primary-invert.is-inverted[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
}

.button.is-primary-invert.is-loading:after {
  border-color: #0000 #0000 #003f2d #003f2d !important;
}

.button.is-primary-invert.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-primary-invert.is-outlined:hover, .button.is-primary-invert.is-outlined:focus {
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.button.is-primary-invert.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-primary-invert.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-primary-invert.is-inverted.is-outlined {
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-primary-invert.is-inverted.is-outlined:hover, .button.is-primary-invert.is-inverted.is-outlined:focus {
  color: #fcfcfc;
  background-color: #003f2d;
}

.button.is-primary-invert.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
}

.button.is-link:hover, .button.is-link.is-hovered {
  color: #fcfcfc;
  background-color: #003224;
  border-color: #0000;
}

.button.is-link:focus:not(.no-focus-style), .button.is-link.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-link:focus:not(.no-focus-style):not(:active), .button.is-link.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.button.is-link:active, .button.is-link.is-active {
  color: #fcfcfc;
  background-color: #00261b;
  border-color: #0000;
}

.button.is-link[disabled] {
  box-shadow: none;
  background-color: #003f2d;
  border-color: #0000;
}

.button.is-link.is-inverted {
  color: #003f2d;
  background-color: #fcfcfc;
}

.button.is-link.is-inverted:hover {
  background-color: #efefef;
}

.button.is-link.is-inverted[disabled] {
  box-shadow: none;
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-link.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-link.is-outlined {
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-link.is-outlined:hover, .button.is-link.is-outlined:focus {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: #0000 #0000 #003f2d #003f2d !important;
}

.button.is-link.is-outlined[disabled] {
  box-shadow: none;
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-link.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined:focus {
  color: #003f2d;
  background-color: #fcfcfc;
}

.button.is-link.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #0000;
}

.button.is-info:hover, .button.is-info.is-hovered {
  color: #fcfcfc;
  background-color: #00a8d0;
  border-color: #0000;
}

.button.is-info:focus:not(.no-focus-style), .button.is-info.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-info:focus:not(.no-focus-style):not(:active), .button.is-info.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #00b2dd40;
}

.button.is-info:active, .button.is-info.is-active {
  color: #fcfcfc;
  background-color: #009dc4;
  border-color: #0000;
}

.button.is-info[disabled] {
  box-shadow: none;
  background-color: #00b2dd;
  border-color: #0000;
}

.button.is-info.is-inverted {
  color: #00b2dd;
  background-color: #fcfcfc;
}

.button.is-info.is-inverted:hover {
  background-color: #efefef;
}

.button.is-info.is-inverted[disabled] {
  box-shadow: none;
  color: #00b2dd;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-info.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-info.is-outlined {
  color: #00b2dd;
  background-color: #0000;
  border-color: #00b2dd;
}

.button.is-info.is-outlined:hover, .button.is-info.is-outlined:focus {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #00b2dd;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: #0000 #0000 #00b2dd #00b2dd !important;
}

.button.is-info.is-outlined[disabled] {
  box-shadow: none;
  color: #00b2dd;
  background-color: #0000;
  border-color: #00b2dd;
}

.button.is-info.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined:focus {
  color: #00b2dd;
  background-color: #fcfcfc;
}

.button.is-info.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #0000;
}

.button.is-pale-green:hover, .button.is-pale-green.is-hovered {
  color: #1a1a1a;
  background-color: #ceded9;
  border-color: #0000;
}

.button.is-pale-green:focus:not(.no-focus-style), .button.is-pale-green.is-focused:not(.no-focus-style) {
  color: #1a1a1a;
  border-color: #0000;
}

.button.is-pale-green:focus:not(.no-focus-style):not(:active), .button.is-pale-green.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #d6e3df40;
}

.button.is-pale-green:active, .button.is-pale-green.is-active {
  color: #1a1a1a;
  background-color: #c7d9d3;
  border-color: #0000;
}

.button.is-pale-green[disabled] {
  box-shadow: none;
  background-color: #d6e3df;
  border-color: #0000;
}

.button.is-pale-green.is-inverted {
  color: #d6e3df;
  background-color: #1a1a1a;
}

.button.is-pale-green.is-inverted:hover {
  background-color: #0d0d0d;
}

.button.is-pale-green.is-inverted[disabled] {
  box-shadow: none;
  color: #d6e3df;
  background-color: #1a1a1a;
  border-color: #0000;
}

.button.is-pale-green.is-loading:after {
  border-color: #0000 #0000 #1a1a1a #1a1a1a !important;
}

.button.is-pale-green.is-outlined {
  color: #d6e3df;
  background-color: #0000;
  border-color: #d6e3df;
}

.button.is-pale-green.is-outlined:hover, .button.is-pale-green.is-outlined:focus {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #d6e3df;
}

.button.is-pale-green.is-outlined.is-loading:after {
  border-color: #0000 #0000 #d6e3df #d6e3df !important;
}

.button.is-pale-green.is-outlined[disabled] {
  box-shadow: none;
  color: #d6e3df;
  background-color: #0000;
  border-color: #d6e3df;
}

.button.is-pale-green.is-inverted.is-outlined {
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-pale-green.is-inverted.is-outlined:hover, .button.is-pale-green.is-inverted.is-outlined:focus {
  color: #d6e3df;
  background-color: #1a1a1a;
}

.button.is-pale-green.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
  border-color: #0000;
}

.button.is-success:hover, .button.is-success.is-hovered {
  color: #fcfcfc;
  background-color: #45e1be;
  border-color: #0000;
}

.button.is-success:focus:not(.no-focus-style), .button.is-success.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-success:focus:not(.no-focus-style):not(:active), .button.is-success.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #50e3c240;
}

.button.is-success:active, .button.is-success.is-active {
  color: #fcfcfc;
  background-color: #3adfba;
  border-color: #0000;
}

.button.is-success[disabled] {
  box-shadow: none;
  background-color: #50e3c2;
  border-color: #0000;
}

.button.is-success.is-inverted {
  color: #50e3c2;
  background-color: #fcfcfc;
}

.button.is-success.is-inverted:hover {
  background-color: #efefef;
}

.button.is-success.is-inverted[disabled] {
  box-shadow: none;
  color: #50e3c2;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-success.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-success.is-outlined {
  color: #50e3c2;
  background-color: #0000;
  border-color: #50e3c2;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined:focus {
  color: #fcfcfc;
  background-color: #50e3c2;
  border-color: #50e3c2;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: #0000 #0000 #50e3c2 #50e3c2 !important;
}

.button.is-success.is-outlined[disabled] {
  box-shadow: none;
  color: #50e3c2;
  background-color: #0000;
  border-color: #50e3c2;
}

.button.is-success.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined:focus {
  color: #50e3c2;
  background-color: #fcfcfc;
}

.button.is-success.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
  border-color: #0000;
}

.button.is-warning:hover, .button.is-warning.is-hovered {
  color: #1a1a1ab3;
  background-color: #ffdb4a;
  border-color: #0000;
}

.button.is-warning:focus:not(.no-focus-style), .button.is-warning.is-focused:not(.no-focus-style) {
  color: #1a1a1ab3;
  border-color: #0000;
}

.button.is-warning:focus:not(.no-focus-style):not(:active), .button.is-warning.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #ffdd5740;
}

.button.is-warning:active, .button.is-warning.is-active {
  color: #1a1a1ab3;
  background-color: #ffd83d;
  border-color: #0000;
}

.button.is-warning[disabled] {
  box-shadow: none;
  background-color: #ffdd57;
  border-color: #0000;
}

.button.is-warning.is-inverted {
  color: #ffdd57;
  background-color: #1a1a1ab3;
}

.button.is-warning.is-inverted:hover {
  background-color: #0d0d0db3;
}

.button.is-warning.is-inverted[disabled] {
  box-shadow: none;
  color: #ffdd57;
  background-color: #1a1a1ab3;
  border-color: #0000;
}

.button.is-warning.is-loading:after {
  border-color: #0000 #0000 #1a1a1ab3 #1a1a1ab3 !important;
}

.button.is-warning.is-outlined {
  color: #ffdd57;
  background-color: #0000;
  border-color: #ffdd57;
}

.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined:focus {
  color: #1a1a1ab3;
  background-color: #ffdd57;
  border-color: #ffdd57;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: #0000 #0000 #ffdd57 #ffdd57 !important;
}

.button.is-warning.is-outlined[disabled] {
  box-shadow: none;
  color: #ffdd57;
  background-color: #0000;
  border-color: #ffdd57;
}

.button.is-warning.is-inverted.is-outlined {
  color: #1a1a1ab3;
  background-color: #0000;
  border-color: #1a1a1ab3;
}

.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined:focus {
  color: #ffdd57;
  background-color: #1a1a1ab3;
}

.button.is-warning.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #1a1a1ab3;
  background-color: #0000;
  border-color: #1a1a1ab3;
}

.button.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
  border-color: #0000;
}

.button.is-danger:hover, .button.is-danger.is-hovered {
  color: #fcfcfc;
  background-color: #fc491b;
  border-color: #0000;
}

.button.is-danger:focus:not(.no-focus-style), .button.is-danger.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-danger:focus:not(.no-focus-style):not(:active), .button.is-danger.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #fc532840;
}

.button.is-danger:active, .button.is-danger.is-active {
  color: #fcfcfc;
  background-color: #fc3f0f;
  border-color: #0000;
}

.button.is-danger[disabled] {
  box-shadow: none;
  background-color: #fc5328;
  border-color: #0000;
}

.button.is-danger.is-inverted {
  color: #fc5328;
  background-color: #fcfcfc;
}

.button.is-danger.is-inverted:hover {
  background-color: #efefef;
}

.button.is-danger.is-inverted[disabled] {
  box-shadow: none;
  color: #fc5328;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-danger.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-danger.is-outlined {
  color: #fc5328;
  background-color: #0000;
  border-color: #fc5328;
}

.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined:focus {
  color: #fcfcfc;
  background-color: #fc5328;
  border-color: #fc5328;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fc5328 #fc5328 !important;
}

.button.is-danger.is-outlined[disabled] {
  box-shadow: none;
  color: #fc5328;
  background-color: #0000;
  border-color: #fc5328;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined:focus {
  color: #fc5328;
  background-color: #fcfcfc;
}

.button.is-danger.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
  border-color: #0000;
}

.button.is-me:hover, .button.is-me.is-hovered {
  color: #fcfcfc;
  background-color: #00c596;
  border-color: #0000;
}

.button.is-me:focus:not(.no-focus-style), .button.is-me.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-me:focus:not(.no-focus-style):not(:active), .button.is-me.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #00d2a040;
}

.button.is-me:active, .button.is-me.is-active {
  color: #fcfcfc;
  background-color: #00b98d;
  border-color: #0000;
}

.button.is-me[disabled] {
  box-shadow: none;
  background-color: #00d2a0;
  border-color: #0000;
}

.button.is-me.is-inverted {
  color: #00d2a0;
  background-color: #fcfcfc;
}

.button.is-me.is-inverted:hover {
  background-color: #efefef;
}

.button.is-me.is-inverted[disabled] {
  box-shadow: none;
  color: #00d2a0;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-me.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-me.is-outlined {
  color: #00d2a0;
  background-color: #0000;
  border-color: #00d2a0;
}

.button.is-me.is-outlined:hover, .button.is-me.is-outlined:focus {
  color: #fcfcfc;
  background-color: #00d2a0;
  border-color: #00d2a0;
}

.button.is-me.is-outlined.is-loading:after {
  border-color: #0000 #0000 #00d2a0 #00d2a0 !important;
}

.button.is-me.is-outlined[disabled] {
  box-shadow: none;
  color: #00d2a0;
  background-color: #0000;
  border-color: #00d2a0;
}

.button.is-me.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-me.is-inverted.is-outlined:hover, .button.is-me.is-inverted.is-outlined:focus {
  color: #00d2a0;
  background-color: #fcfcfc;
}

.button.is-me.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #0000;
}

.button.is-we:hover, .button.is-we.is-hovered {
  color: #fcfcfc;
  background-color: #00a8d0;
  border-color: #0000;
}

.button.is-we:focus:not(.no-focus-style), .button.is-we.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-we:focus:not(.no-focus-style):not(:active), .button.is-we.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #00b2dd40;
}

.button.is-we:active, .button.is-we.is-active {
  color: #fcfcfc;
  background-color: #009dc4;
  border-color: #0000;
}

.button.is-we[disabled] {
  box-shadow: none;
  background-color: #00b2dd;
  border-color: #0000;
}

.button.is-we.is-inverted {
  color: #00b2dd;
  background-color: #fcfcfc;
}

.button.is-we.is-inverted:hover {
  background-color: #efefef;
}

.button.is-we.is-inverted[disabled] {
  box-shadow: none;
  color: #00b2dd;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-we.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-we.is-outlined {
  color: #00b2dd;
  background-color: #0000;
  border-color: #00b2dd;
}

.button.is-we.is-outlined:hover, .button.is-we.is-outlined:focus {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #00b2dd;
}

.button.is-we.is-outlined.is-loading:after {
  border-color: #0000 #0000 #00b2dd #00b2dd !important;
}

.button.is-we.is-outlined[disabled] {
  box-shadow: none;
  color: #00b2dd;
  background-color: #0000;
  border-color: #00b2dd;
}

.button.is-we.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-we.is-inverted.is-outlined:hover, .button.is-we.is-inverted.is-outlined:focus {
  color: #00b2dd;
  background-color: #fcfcfc;
}

.button.is-we.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
  border-color: #0000;
}

.button.is-amenity:hover, .button.is-amenity.is-hovered {
  color: #fcfcfc;
  background-color: #7473cc;
  border-color: #0000;
}

.button.is-amenity:focus:not(.no-focus-style), .button.is-amenity.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-amenity:focus:not(.no-focus-style):not(:active), .button.is-amenity.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #7d7ccf40;
}

.button.is-amenity:active, .button.is-amenity.is-active {
  color: #fcfcfc;
  background-color: #6a69c8;
  border-color: #0000;
}

.button.is-amenity[disabled] {
  box-shadow: none;
  background-color: #7d7ccf;
  border-color: #0000;
}

.button.is-amenity.is-inverted {
  color: #7d7ccf;
  background-color: #fcfcfc;
}

.button.is-amenity.is-inverted:hover {
  background-color: #efefef;
}

.button.is-amenity.is-inverted[disabled] {
  box-shadow: none;
  color: #7d7ccf;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-amenity.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-amenity.is-outlined {
  color: #7d7ccf;
  background-color: #0000;
  border-color: #7d7ccf;
}

.button.is-amenity.is-outlined:hover, .button.is-amenity.is-outlined:focus {
  color: #fcfcfc;
  background-color: #7d7ccf;
  border-color: #7d7ccf;
}

.button.is-amenity.is-outlined.is-loading:after {
  border-color: #0000 #0000 #7d7ccf #7d7ccf !important;
}

.button.is-amenity.is-outlined[disabled] {
  box-shadow: none;
  color: #7d7ccf;
  background-color: #0000;
  border-color: #7d7ccf;
}

.button.is-amenity.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-amenity.is-inverted.is-outlined:hover, .button.is-amenity.is-inverted.is-outlined:focus {
  color: #7d7ccf;
  background-color: #fcfcfc;
}

.button.is-amenity.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
  border-color: #0000;
}

.button.is-light-pale-green:hover, .button.is-light-pale-green.is-hovered {
  color: #1a1a1a;
  background-color: #e2ece9;
  border-color: #0000;
}

.button.is-light-pale-green:focus:not(.no-focus-style), .button.is-light-pale-green.is-focused:not(.no-focus-style) {
  color: #1a1a1a;
  border-color: #0000;
}

.button.is-light-pale-green:focus:not(.no-focus-style):not(:active), .button.is-light-pale-green.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #eaf1ef40;
}

.button.is-light-pale-green:active, .button.is-light-pale-green.is-active {
  color: #1a1a1a;
  background-color: #dbe7e3;
  border-color: #0000;
}

.button.is-light-pale-green[disabled] {
  box-shadow: none;
  background-color: #eaf1ef;
  border-color: #0000;
}

.button.is-light-pale-green.is-inverted {
  color: #eaf1ef;
  background-color: #1a1a1a;
}

.button.is-light-pale-green.is-inverted:hover {
  background-color: #0d0d0d;
}

.button.is-light-pale-green.is-inverted[disabled] {
  box-shadow: none;
  color: #eaf1ef;
  background-color: #1a1a1a;
  border-color: #0000;
}

.button.is-light-pale-green.is-loading:after {
  border-color: #0000 #0000 #1a1a1a #1a1a1a !important;
}

.button.is-light-pale-green.is-outlined {
  color: #eaf1ef;
  background-color: #0000;
  border-color: #eaf1ef;
}

.button.is-light-pale-green.is-outlined:hover, .button.is-light-pale-green.is-outlined:focus {
  color: #1a1a1a;
  background-color: #eaf1ef;
  border-color: #eaf1ef;
}

.button.is-light-pale-green.is-outlined.is-loading:after {
  border-color: #0000 #0000 #eaf1ef #eaf1ef !important;
}

.button.is-light-pale-green.is-outlined[disabled] {
  box-shadow: none;
  color: #eaf1ef;
  background-color: #0000;
  border-color: #eaf1ef;
}

.button.is-light-pale-green.is-inverted.is-outlined {
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-light-pale-green.is-inverted.is-outlined:hover, .button.is-light-pale-green.is-inverted.is-outlined:focus {
  color: #eaf1ef;
  background-color: #1a1a1a;
}

.button.is-light-pale-green.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #1a1a1a;
  background-color: #0000;
  border-color: #1a1a1a;
}

.button.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
  border-color: #0000;
}

.button.is-green-forest-darker:hover, .button.is-green-forest-darker.is-hovered {
  color: #fcfcfc;
  background-color: #193f30;
  border-color: #0000;
}

.button.is-green-forest-darker:focus:not(.no-focus-style), .button.is-green-forest-darker.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-green-forest-darker:focus:not(.no-focus-style):not(:active), .button.is-green-forest-darker.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #1d483740;
}

.button.is-green-forest-darker:active, .button.is-green-forest-darker.is-active {
  color: #fcfcfc;
  background-color: #163629;
  border-color: #0000;
}

.button.is-green-forest-darker[disabled] {
  box-shadow: none;
  background-color: #1d4837;
  border-color: #0000;
}

.button.is-green-forest-darker.is-inverted {
  color: #1d4837;
  background-color: #fcfcfc;
}

.button.is-green-forest-darker.is-inverted:hover {
  background-color: #efefef;
}

.button.is-green-forest-darker.is-inverted[disabled] {
  box-shadow: none;
  color: #1d4837;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-green-forest-darker.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-green-forest-darker.is-outlined {
  color: #1d4837;
  background-color: #0000;
  border-color: #1d4837;
}

.button.is-green-forest-darker.is-outlined:hover, .button.is-green-forest-darker.is-outlined:focus {
  color: #fcfcfc;
  background-color: #1d4837;
  border-color: #1d4837;
}

.button.is-green-forest-darker.is-outlined.is-loading:after {
  border-color: #0000 #0000 #1d4837 #1d4837 !important;
}

.button.is-green-forest-darker.is-outlined[disabled] {
  box-shadow: none;
  color: #1d4837;
  background-color: #0000;
  border-color: #1d4837;
}

.button.is-green-forest-darker.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-green-forest-darker.is-inverted.is-outlined:hover, .button.is-green-forest-darker.is-inverted.is-outlined:focus {
  color: #1d4837;
  background-color: #fcfcfc;
}

.button.is-green-forest-darker.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
}

.button.is-green-forest-dark:hover, .button.is-green-forest-dark.is-hovered {
  color: #fcfcfc;
  background-color: #003224;
  border-color: #0000;
}

.button.is-green-forest-dark:focus:not(.no-focus-style), .button.is-green-forest-dark.is-focused:not(.no-focus-style) {
  color: #fcfcfc;
  border-color: #0000;
}

.button.is-green-forest-dark:focus:not(.no-focus-style):not(:active), .button.is-green-forest-dark.is-focused:not(.no-focus-style):not(:active) {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.button.is-green-forest-dark:active, .button.is-green-forest-dark.is-active {
  color: #fcfcfc;
  background-color: #00261b;
  border-color: #0000;
}

.button.is-green-forest-dark[disabled] {
  box-shadow: none;
  background-color: #003f2d;
  border-color: #0000;
}

.button.is-green-forest-dark.is-inverted {
  color: #003f2d;
  background-color: #fcfcfc;
}

.button.is-green-forest-dark.is-inverted:hover {
  background-color: #efefef;
}

.button.is-green-forest-dark.is-inverted[disabled] {
  box-shadow: none;
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #0000;
}

.button.is-green-forest-dark.is-loading:after {
  border-color: #0000 #0000 #fcfcfc #fcfcfc !important;
}

.button.is-green-forest-dark.is-outlined {
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-green-forest-dark.is-outlined:hover, .button.is-green-forest-dark.is-outlined:focus {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.button.is-green-forest-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #003f2d #003f2d !important;
}

.button.is-green-forest-dark.is-outlined[disabled] {
  box-shadow: none;
  color: #003f2d;
  background-color: #0000;
  border-color: #003f2d;
}

.button.is-green-forest-dark.is-inverted.is-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-green-forest-dark.is-inverted.is-outlined:hover, .button.is-green-forest-dark.is-inverted.is-outlined:focus {
  color: #003f2d;
  background-color: #fcfcfc;
}

.button.is-green-forest-dark.is-inverted.is-outlined[disabled] {
  box-shadow: none;
  color: #fcfcfc;
  background-color: #0000;
  border-color: #fcfcfc;
}

.button.is-small {
  border-radius: 2px;
  font-size: .75rem;
  line-height: .75rem;
}

.button.is-medium {
  font-size: 1.25rem;
}

.button.is-large {
  font-size: 1.5rem;
}

.button[disabled] {
  box-shadow: none;
  opacity: .5;
  background-color: #fcfcfc;
  border-color: #dbdbdb;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #003f2d #003f2d;
  border-radius: 290486px;
  width: 1em;
  height: 1em;
  animation: .7s linear infinite spinAround;
  display: block;
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #666;
  box-shadow: none;
  pointer-events: none;
  background-color: #f0f0f0;
  border-color: #dbdbdb;
}

.button.is-primary-filled {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
  border-radius: 2px;
}

.button.is-primary-filled:hover, .button.is-primary-filled.success {
  background-color: #17e88f;
  border-color: #0000;
  border-radius: 2px;
}

.button.is-primary-filled:focus, .button.is-primary-filled:active {
  color: #fcfcfc;
}

.button.is-primary-filled.has-dark-success-color {
  background-color: #003f2d;
}

.button.is-primary-filled.has-dark-success-color:hover {
  background-color: #144e3e;
  border-radius: 5px;
}

.button.is-primary-filled.has-save-icon:after {
  content: url("save-white-icon-spacer-by-cbre.29d86b34.svg");
}

.button.is-primary-filled.has-checkmark-icon-before:before {
  content: url("check-mark-teal-icon-in-white-circle-spacer-by-cbre.7faff4cf.svg");
  margin-right: .5rem;
}

.button.is-primary-outlined {
  color: #003f2d;
  background-color: #0000;
  border-width: 2px;
  border-color: #003f2d;
}

.button.is-primary-outlined:hover {
  color: #17e88f;
  border-color: #17e88f;
}

.button.is-tertiary-filled {
  color: #fcfcfc;
  background-color: #0d8070;
  border-color: #0000;
  border-radius: 2px;
}

.button.is-tertiary-filled:hover {
  color: #fcfcfc;
  background-color: #778f9c;
  border-color: #0000;
  border-radius: 2px;
}

.button.is-tertiary-filled.is-disabled {
  opacity: .2;
}

.button.is-tertiary-filled:focus {
  color: #fcfcfc;
}

.button.is-tertiary-filled-modal {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
  border-radius: 2px;
}

.button.is-tertiary-filled-modal:hover {
  color: #fcfcfc;
  background-color: #144e3e;
  border-color: #0000;
  border-radius: 2px;
}

.button.is-tertiary-filled-modal.is-disabled {
  opacity: .2;
}

.button.is-tertiary-filled-modal:focus {
  color: #fcfcfc;
}

.button.is-tertiary-outlined {
  color: #fcfcfc;
  background-color: #0000;
  border-width: 2px;
  border-color: #003f2d;
  border-radius: 2px;
}

.button.is-tertiary-outlined:hover {
  color: #fcfcfc;
  border-color: #12b8a2;
}

.button.is-tertiary-outlined:disabled, .button.is-tertiary-outlined.is-disabled {
  opacity: .2;
}

.button.is-tertiary-outlined:focus {
  color: #fcfcfc;
}

.button.is-tertiary-outlined.has-tertiary-text {
  color: #003f2d;
}

.button.is-tertiary-outlined.has-tertiary-text:hover {
  color: #12b8a2;
}

.button.is-tertiary-outlined.is-filled-on-hover:hover {
  color: #fcfcfc;
  background-color: #0d8070;
  border-color: #0d8070;
}

.button.is-tertiary-outlined-modal {
  color: #fcfcfc;
  background-color: #0000;
  border-width: 2px;
  border-color: #003f2d;
  border-radius: 2px;
}

.button.is-tertiary-outlined-modal:hover {
  color: #fcfcfc;
  border-color: #144e3e;
}

.button.is-tertiary-outlined-modal:disabled, .button.is-tertiary-outlined-modal.is-disabled {
  opacity: .2;
}

.button.is-tertiary-outlined-modal:focus {
  color: #fcfcfc;
}

.button.is-tertiary-outlined-modal.has-tertiary-text {
  color: #003f2d;
}

.button.is-tertiary-outlined-modal.has-tertiary-text:hover {
  color: #144e3e;
}

.button.is-tertiary-outlined-modal.is-filled-on-hover:hover {
  color: #fcfcfc;
  background-color: #0d8070;
  border-color: #0d8070;
}

.button.has-min-width-narrow {
  min-width: 130px;
}

.button.has-min-width-wide {
  min-width: 160px;
}

.button.back-button {
  background-image: url("back-arrow-white-icon-spacer-by-cbre.3ede40a0.svg");
  background-position: 8px;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  padding-left: 1.5rem;
}

.button.flooredPlanButton {
  border-radius: 5px;
  width: 500px;
  height: 45px;
  margin-bottom: 60px;
}

.button-pair {
  flex-wrap: wrap;
  display: flex;
}

.button-pair .button {
  min-width: 45%;
  margin-top: .2rem;
}

.button-pair .button:first-of-type {
  margin-right: 10%;
}

@media screen and (width <= 767px) {
  .button-pair .button {
    font-size: .75rem;
  }

  .button-pair .button:first-of-type {
    margin-right: 1rem;
  }
}

.container {
  width: 100%;
  max-width: calc(100% - 64px);
  margin-right: 25px;
  position: relative;
}

.container.is-fluid {
  width: auto;
  max-width: none;
  margin-left: 32px;
  margin-right: 32px;
}

.content:not(:last-child) {
  margin-bottom: 1.5rem;
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #434a48;
  font-weight: 400;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #fcfcfc;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style: decimal;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: #434a48;
  text-align: left;
}

.content table tr:hover {
  background-color: #fcfcfc;
}

.content table thead td, .content table thead th {
  color: #434a48;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: #434a48;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content.is-small {
  font-size: .75rem;
}

.content.is-medium {
  font-size: 1.25rem;
}

.content.is-large {
  font-size: 1.5rem;
}

.input, .textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  color: #434a48;
  background-color: #fcfcfc;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 2.25em;
  padding: calc(.375em - 1px) calc(.625em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
  box-shadow: inset 0 1px 2px #1a1a1a1a;
}

.input:focus, .input.is-focused, .input:active, .input.is-active, .textarea:focus, .textarea.is-focused, .textarea:active, .textarea.is-active {
  outline: none;
}

.input[disabled], .textarea[disabled] {
  pointer-events: none;
}

.input::-moz-placeholder {
  color: #434a484d;
}

.textarea::-moz-placeholder {
  color: #434a484d;
}

.input::-webkit-input-placeholder {
  color: #434a484d;
}

.textarea::-webkit-input-placeholder {
  color: #434a484d;
}

:is(.input:-moz-placeholder, .textarea:-moz-placeholder), :is(.input:-ms-input-placeholder, .textarea:-ms-input-placeholder) {
  color: #434a484d;
}

.input:hover, .input.is-hovered, .textarea:hover, .textarea.is-hovered {
  border-color: #b5b5b5;
}

.input:focus, .input.is-focused, .input:active, .input.is-active, .textarea:focus, .textarea.is-focused, .textarea:active, .textarea.is-active {
  border-color: #003f2d;
  box-shadow: 0 0 0 .125em #003f2d40;
}

.input[disabled], .textarea[disabled] {
  box-shadow: none;
  color: #666;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.input[disabled]::-moz-placeholder {
  color: #6666664d;
}

.textarea[disabled]::-moz-placeholder {
  color: #6666664d;
}

.input[disabled]::-webkit-input-placeholder {
  color: #6666664d;
}

.textarea[disabled]::-webkit-input-placeholder {
  color: #6666664d;
}

:is(.input[disabled]:-moz-placeholder, .textarea[disabled]:-moz-placeholder), :is(.input[disabled]:-ms-input-placeholder, .textarea[disabled]:-ms-input-placeholder) {
  color: #6666664d;
}

.input[type="search"], .textarea[type="search"] {
  border-radius: 290486px;
}

.input[readonly], .textarea[readonly] {
  box-shadow: none;
}

.input.is-white, .textarea.is-white {
  border-color: #fcfcfc;
}

.input.is-white:focus, .input.is-white.is-focused, .input.is-white:active, .input.is-white.is-active, .textarea.is-white:focus, .textarea.is-white.is-focused, .textarea.is-white:active, .textarea.is-white.is-active {
  box-shadow: 0 0 0 .125em #fcfcfc40;
}

.input.is-black, .textarea.is-black {
  border-color: #1a1a1a;
}

.input.is-black:focus, .input.is-black.is-focused, .input.is-black:active, .input.is-black.is-active, .textarea.is-black:focus, .textarea.is-black.is-focused, .textarea.is-black:active, .textarea.is-black.is-active {
  box-shadow: 0 0 0 .125em #1a1a1a40;
}

.input.is-light, .textarea.is-light {
  border-color: #f0f0f0;
}

.input.is-light:focus, .input.is-light.is-focused, .input.is-light:active, .input.is-light.is-active, .textarea.is-light:focus, .textarea.is-light.is-focused, .textarea.is-light:active, .textarea.is-light.is-active {
  box-shadow: 0 0 0 .125em #f0f0f040;
}

.input.is-dark, .textarea.is-dark {
  border-color: #434a48;
}

.input.is-dark:focus, .input.is-dark.is-focused, .input.is-dark:active, .input.is-dark.is-active, .textarea.is-dark:focus, .textarea.is-dark.is-focused, .textarea.is-dark:active, .textarea.is-dark.is-active {
  box-shadow: 0 0 0 .125em #434a4840;
}

.input.is-primary, .textarea.is-primary {
  border-color: #003f2d;
}

.input.is-primary:focus, .input.is-primary.is-focused, .input.is-primary:active, .input.is-primary.is-active, .textarea.is-primary:focus, .textarea.is-primary.is-focused, .textarea.is-primary:active, .textarea.is-primary.is-active {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.input.is-secondary, .textarea.is-secondary {
  border-color: #d6e3df;
}

.input.is-secondary:focus, .input.is-secondary.is-focused, .input.is-secondary:active, .input.is-secondary.is-active, .textarea.is-secondary:focus, .textarea.is-secondary.is-focused, .textarea.is-secondary:active, .textarea.is-secondary.is-active {
  box-shadow: 0 0 0 .125em #d6e3df40;
}

.input.is-tertiary, .textarea.is-tertiary {
  border-color: #0d8070;
}

.input.is-tertiary:focus, .input.is-tertiary.is-focused, .input.is-tertiary:active, .input.is-tertiary.is-active, .textarea.is-tertiary:focus, .textarea.is-tertiary.is-focused, .textarea.is-tertiary:active, .textarea.is-tertiary.is-active {
  box-shadow: 0 0 0 .125em #0d807040;
}

.input.is-primary-invert, .textarea.is-primary-invert {
  border-color: #fcfcfc;
}

.input.is-primary-invert:focus, .input.is-primary-invert.is-focused, .input.is-primary-invert:active, .input.is-primary-invert.is-active, .textarea.is-primary-invert:focus, .textarea.is-primary-invert.is-focused, .textarea.is-primary-invert:active, .textarea.is-primary-invert.is-active {
  box-shadow: 0 0 0 .125em #fcfcfc40;
}

.input.is-link, .textarea.is-link {
  border-color: #003f2d;
}

.input.is-link:focus, .input.is-link.is-focused, .input.is-link:active, .input.is-link.is-active, .textarea.is-link:focus, .textarea.is-link.is-focused, .textarea.is-link:active, .textarea.is-link.is-active {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.input.is-info, .textarea.is-info {
  border-color: #00b2dd;
}

.input.is-info:focus, .input.is-info.is-focused, .input.is-info:active, .input.is-info.is-active, .textarea.is-info:focus, .textarea.is-info.is-focused, .textarea.is-info:active, .textarea.is-info.is-active {
  box-shadow: 0 0 0 .125em #00b2dd40;
}

.input.is-pale-green, .textarea.is-pale-green {
  border-color: #d6e3df;
}

.input.is-pale-green:focus, .input.is-pale-green.is-focused, .input.is-pale-green:active, .input.is-pale-green.is-active, .textarea.is-pale-green:focus, .textarea.is-pale-green.is-focused, .textarea.is-pale-green:active, .textarea.is-pale-green.is-active {
  box-shadow: 0 0 0 .125em #d6e3df40;
}

.input.is-success, .textarea.is-success {
  border-color: #50e3c2;
}

.input.is-success:focus, .input.is-success.is-focused, .input.is-success:active, .input.is-success.is-active, .textarea.is-success:focus, .textarea.is-success.is-focused, .textarea.is-success:active, .textarea.is-success.is-active {
  box-shadow: 0 0 0 .125em #50e3c240;
}

.input.is-warning, .textarea.is-warning {
  border-color: #ffdd57;
}

.input.is-warning:focus, .input.is-warning.is-focused, .input.is-warning:active, .input.is-warning.is-active, .textarea.is-warning:focus, .textarea.is-warning.is-focused, .textarea.is-warning:active, .textarea.is-warning.is-active {
  box-shadow: 0 0 0 .125em #ffdd5740;
}

.input.is-danger, .textarea.is-danger {
  border-color: #fc5328;
}

.input.is-danger:focus, .input.is-danger.is-focused, .input.is-danger:active, .input.is-danger.is-active, .textarea.is-danger:focus, .textarea.is-danger.is-focused, .textarea.is-danger:active, .textarea.is-danger.is-active {
  box-shadow: 0 0 0 .125em #fc532840;
}

.input.is-me, .textarea.is-me {
  border-color: #00d2a0;
}

.input.is-me:focus, .input.is-me.is-focused, .input.is-me:active, .input.is-me.is-active, .textarea.is-me:focus, .textarea.is-me.is-focused, .textarea.is-me:active, .textarea.is-me.is-active {
  box-shadow: 0 0 0 .125em #00d2a040;
}

.input.is-we, .textarea.is-we {
  border-color: #00b2dd;
}

.input.is-we:focus, .input.is-we.is-focused, .input.is-we:active, .input.is-we.is-active, .textarea.is-we:focus, .textarea.is-we.is-focused, .textarea.is-we:active, .textarea.is-we.is-active {
  box-shadow: 0 0 0 .125em #00b2dd40;
}

.input.is-amenity, .textarea.is-amenity {
  border-color: #7d7ccf;
}

.input.is-amenity:focus, .input.is-amenity.is-focused, .input.is-amenity:active, .input.is-amenity.is-active, .textarea.is-amenity:focus, .textarea.is-amenity.is-focused, .textarea.is-amenity:active, .textarea.is-amenity.is-active {
  box-shadow: 0 0 0 .125em #7d7ccf40;
}

.input.is-light-pale-green, .textarea.is-light-pale-green {
  border-color: #eaf1ef;
}

.input.is-light-pale-green:focus, .input.is-light-pale-green.is-focused, .input.is-light-pale-green:active, .input.is-light-pale-green.is-active, .textarea.is-light-pale-green:focus, .textarea.is-light-pale-green.is-focused, .textarea.is-light-pale-green:active, .textarea.is-light-pale-green.is-active {
  box-shadow: 0 0 0 .125em #eaf1ef40;
}

.input.is-green-forest-darker, .textarea.is-green-forest-darker {
  border-color: #1d4837;
}

.input.is-green-forest-darker:focus, .input.is-green-forest-darker.is-focused, .input.is-green-forest-darker:active, .input.is-green-forest-darker.is-active, .textarea.is-green-forest-darker:focus, .textarea.is-green-forest-darker.is-focused, .textarea.is-green-forest-darker:active, .textarea.is-green-forest-darker.is-active {
  box-shadow: 0 0 0 .125em #1d483740;
}

.input.is-green-forest-dark, .textarea.is-green-forest-dark {
  border-color: #003f2d;
}

.input.is-green-forest-dark:focus, .input.is-green-forest-dark.is-focused, .input.is-green-forest-dark:active, .input.is-green-forest-dark.is-active, .textarea.is-green-forest-dark:focus, .textarea.is-green-forest-dark.is-focused, .textarea.is-green-forest-dark:active, .textarea.is-green-forest-dark.is-active {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.input.is-small, .textarea.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.input.is-medium, .textarea.is-medium {
  font-size: 1.25rem;
}

.input.is-large, .textarea.is-large {
  border-radius: 18px;
  height: 3.5rem;
  font-size: 1rem;
}

.input.is-fullwidth, .textarea.is-fullwidth {
  width: 100%;
  display: block;
}

.input.is-inline, .textarea.is-inline {
  width: auto;
  display: inline;
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  max-width: 100%;
  padding: .625em;
  display: block;
}

.textarea:not([rows]) {
  min-height: 120px;
  max-height: 600px;
}

.textarea[rows] {
  height: unset;
}

.textarea.has-fixed-size {
  resize: none;
}

.checkbox, .radio {
  cursor: pointer;
  max-width: calc(100% - 1.5rem);
  padding-left: .25em;
  line-height: 1.25;
  display: inline-block;
  position: relative;
  right: -1.5em;
}

.checkbox input, .radio input {
  cursor: pointer;
  z-index: 0;
  position: absolute;
  top: .5rem;
  left: -1.25rem;
}

.checkbox[disabled], .checkbox.is-disabled, .radio[disabled], .radio.is-disabled {
  opacity: .75;
  pointer-events: none;
}

.checkbox:before, .radio:before {
  content: "";
  z-index: 1;
  background-color: #fcfcfc;
  border: 2px solid #1a1a1a;
  width: 1.25em;
  height: 1.25em;
  position: absolute;
  left: -1.5em;
}

.checkbox:not(.in-quiz):before {
  border-radius: 4px;
  margin-top: 3px;
}

.checkbox:not(.in-quiz).is-active:before {
  content: url("checkmark-forest-green-icon-spacer-by-cbre.316bea21.svg");
  justify-content: center;
  align-items: center;
  display: flex;
}

.checkbox.in-quiz {
  color: #fff;
}

.checkbox.in-quiz.input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.checkbox.in-quiz:before {
  background: #003f2d;
  border: 1px solid #fcfcfc;
  border-radius: 7px;
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

.checkbox.in-quiz.is-active:before {
  content: url("checkmark-white-icon-spacer-by-cbre.033b3a0a.svg");
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (width <= 1181px) {
  .checkbox.in-quiz {
    margin-left: 0;
  }
}

.checkbox.in-results:before {
  border-color: #666;
  border-radius: 7px;
  width: 18px;
  height: 18px;
}

.checkbox.in-results.is-active:before {
  content: url("checkmark-forest-green-icon-spacer-by-cbre.316bea21.svg");
  justify-content: center;
  align-items: center;
  display: flex;
}

.checkbox.in-results:focus-within:before {
  border: 2px solid #003f2d;
}

.radio:before {
  border-radius: 50%;
}

.radio:after {
  content: "";
  background-color: #0000;
  border-radius: 50%;
  width: .625em;
  height: .625em;
  position: absolute;
  top: .3125em;
  left: -1.175em;
}

.radio:active:before, .radio.is-active:before {
  border: 1px solid #d6e3df;
}

.radio:active:after, .radio.is-active:after {
  background-color: #d6e3df;
}

.radio-spectrum {
  cursor: pointer;
  color: #fcfcfc;
  text-align: center;
  letter-spacing: .2px;
  justify-content: center;
  padding-top: calc(1em + 34px);
  padding-left: .75em;
  padding-right: .75em;
  font-size: 18px;
  line-height: 1.33;
  transition: color .75s cubic-bezier(.23, 1, .32, 1);
  display: inline-flex;
  position: relative;
}

.radio-spectrum input {
  cursor: pointer;
}

.radio-spectrum:before {
  content: "";
  z-index: 1;
  background-color: #003f2d;
  border: 2px solid #fcfcfc;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  transition: background-color .75s cubic-bezier(.23, 1, .32, 1);
  position: absolute;
  top: 0;
  left: calc(50% - 17px);
}

.radio-spectrum:not(:last-child):after {
  content: "";
  z-index: 0;
  border-top: 2px solid #fcfcfc;
  width: calc(100% + 17px);
  height: 2px;
  position: absolute;
  top: 17px;
  left: 50%;
}

.radio-spectrum:hover {
  font-weight: inherit;
}

@media screen and (width >= 768px) {
  .radio-spectrum:hover:before {
    background-color: #17e88f;
  }
}

.radio-spectrum:active, .radio-spectrum.is-active, .radio-spectrum:focus {
  color: #17e88f;
}

.radio-spectrum:active:before, .radio-spectrum.is-active:before, .radio-spectrum:focus:before {
  background-color: #17e88f;
  border-color: #17e88f;
}

@media screen and (width <= 767px) {
  .radio-spectrum {
    text-align: left;
    flex-direction: column;
    justify-content: center;
    height: 20%;
    min-height: 5rem;
    padding-top: 0;
    padding-left: calc(1em + 34px);
  }

  .radio-spectrum:before {
    justify-self: center;
    top: calc(50% - 17px);
    left: 0;
  }

  .radio-spectrum:not(:last-child):after {
    border-top: none;
    border-left: 2px solid #fcfcfc;
    height: calc(100% + 17px);
    top: 50%;
    left: 17px;
  }
}

.select {
  vertical-align: top;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 3.5em;
}

.select:not(.is-multiple):after {
  content: " ";
  pointer-events: none;
  z-index: 4;
  border-top: 0;
  border-bottom: 1px solid #003f2d;
  border-left: 1px solid #003f2d;
  border-right: 0;
  width: .5em;
  height: .5em;
  margin-top: -.375em;
  display: block;
  position: absolute;
  top: 50%;
  right: 1.125em;
  transform: rotate(-45deg);
}

.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  color: #434a48;
  cursor: pointer;
  background-color: #fcfcfc;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  outline: none;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 2.25em;
  padding: calc(.375em - 1px) calc(.625em - 1px);
  font-size: 1em;
  line-height: 1.5;
  display: block;
  position: relative;
}

.select select:focus, .select select.is-focused, .select select:active, .select select.is-active {
  outline: none;
}

.select select[disabled] {
  pointer-events: none;
}

.select select::placeholder {
  color: #434a484d;
}

.select select::placeholder {
  color: #434a484d;
}

.select select:-moz-placeholder {
  color: #434a484d;
}

.select select:-ms-input-placeholder {
  color: #434a484d;
}

.select select:hover, .select select.is-hovered {
  border-color: #b5b5b5;
}

.select select:focus, .select select.is-focused, .select select:active, .select select.is-active {
  border-color: #003f2d;
  box-shadow: 0 0 0 .125em #003f2d40;
}

.select select[disabled] {
  box-shadow: none;
  color: #666;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.select select[disabled]::placeholder {
  color: #6666664d;
}

.select select[disabled]::placeholder {
  color: #6666664d;
}

.select select[disabled]:-moz-placeholder {
  color: #6666664d;
}

.select select[disabled]:-ms-input-placeholder {
  color: #6666664d;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover {
  border-color: #fcfcfc;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: unset;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:hover:after {
  border-color: #434a48;
}

.select.is-white select {
  border-color: #fcfcfc;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 .125em #fcfcfc40;
}

.select.is-black select {
  border-color: #1a1a1a;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 .125em #1a1a1a40;
}

.select.is-light select {
  border-color: #f0f0f0;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 .125em #f0f0f040;
}

.select.is-dark select {
  border-color: #434a48;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 .125em #434a4840;
}

.select.is-primary select {
  border-color: #003f2d;
}

.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.select.is-secondary select {
  border-color: #d6e3df;
}

.select.is-secondary select:focus, .select.is-secondary select.is-focused, .select.is-secondary select:active, .select.is-secondary select.is-active {
  box-shadow: 0 0 0 .125em #d6e3df40;
}

.select.is-tertiary select {
  border-color: #0d8070;
}

.select.is-tertiary select:focus, .select.is-tertiary select.is-focused, .select.is-tertiary select:active, .select.is-tertiary select.is-active {
  box-shadow: 0 0 0 .125em #0d807040;
}

.select.is-primary-invert select {
  border-color: #fcfcfc;
}

.select.is-primary-invert select:focus, .select.is-primary-invert select.is-focused, .select.is-primary-invert select:active, .select.is-primary-invert select.is-active {
  box-shadow: 0 0 0 .125em #fcfcfc40;
}

.select.is-link select {
  border-color: #003f2d;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.select.is-info select {
  border-color: #00b2dd;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 .125em #00b2dd40;
}

.select.is-pale-green select {
  border-color: #d6e3df;
}

.select.is-pale-green select:focus, .select.is-pale-green select.is-focused, .select.is-pale-green select:active, .select.is-pale-green select.is-active {
  box-shadow: 0 0 0 .125em #d6e3df40;
}

.select.is-success select {
  border-color: #50e3c2;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 .125em #50e3c240;
}

.select.is-warning select {
  border-color: #ffdd57;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 .125em #ffdd5740;
}

.select.is-danger select {
  border-color: #fc5328;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 .125em #fc532840;
}

.select.is-me select {
  border-color: #00d2a0;
}

.select.is-me select:focus, .select.is-me select.is-focused, .select.is-me select:active, .select.is-me select.is-active {
  box-shadow: 0 0 0 .125em #00d2a040;
}

.select.is-we select {
  border-color: #00b2dd;
}

.select.is-we select:focus, .select.is-we select.is-focused, .select.is-we select:active, .select.is-we select.is-active {
  box-shadow: 0 0 0 .125em #00b2dd40;
}

.select.is-amenity select {
  border-color: #7d7ccf;
}

.select.is-amenity select:focus, .select.is-amenity select.is-focused, .select.is-amenity select:active, .select.is-amenity select.is-active {
  box-shadow: 0 0 0 .125em #7d7ccf40;
}

.select.is-light-pale-green select {
  border-color: #eaf1ef;
}

.select.is-light-pale-green select:focus, .select.is-light-pale-green select.is-focused, .select.is-light-pale-green select:active, .select.is-light-pale-green select.is-active {
  box-shadow: 0 0 0 .125em #eaf1ef40;
}

.select.is-green-forest-darker select {
  border-color: #1d4837;
}

.select.is-green-forest-darker select:focus, .select.is-green-forest-darker select.is-focused, .select.is-green-forest-darker select:active, .select.is-green-forest-darker select.is-active {
  box-shadow: 0 0 0 .125em #1d483740;
}

.select.is-green-forest-dark select {
  border-color: #003f2d;
}

.select.is-green-forest-dark select:focus, .select.is-green-forest-dark select.is-focused, .select.is-green-forest-dark select:active, .select.is-green-forest-dark select.is-active {
  box-shadow: 0 0 0 .125em #003f2d40;
}

.select.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.select.is-medium {
  font-size: 1.25rem;
}

.select.is-large, .select.is-large select {
  border-radius: 18px;
  height: 3.5rem;
  font-size: 1rem;
}

.select.is-disabled:after {
  border-color: #666;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #003f2d #003f2d;
  border-radius: 290486px;
  width: 1em;
  height: 1em;
  margin-top: 0;
  animation: .7s linear infinite spinAround;
  display: block;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: .75rem;
}

.select.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.select.is-loading.is-large:after {
  font-size: 1.5rem;
}

.file {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #1a1a1a;
  background-color: #fcfcfc;
  border-color: #0000;
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #1a1a1a;
  background-color: #f6f6f6;
  border-color: #0000;
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #1a1a1a;
  border-color: #0000;
  box-shadow: 0 0 .5em #fcfcfc40;
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #1a1a1a;
  background-color: #efefef;
  border-color: #0000;
}

.file.is-black .file-cta {
  color: #fcfcfc;
  background-color: #1a1a1a;
  border-color: #0000;
}

.file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #141414;
  border-color: #0000;
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #1a1a1a40;
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fcfcfc;
  background-color: #0d0d0d;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #434a48;
  background-color: #f0f0f0;
  border-color: #0000;
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: #434a48;
  background-color: #e9e9e9;
  border-color: #0000;
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: #434a48;
  border-color: #0000;
  box-shadow: 0 0 .5em #f0f0f040;
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: #434a48;
  background-color: #e3e3e3;
  border-color: #0000;
}

.file.is-dark .file-cta {
  color: #f0f0f0;
  background-color: #434a48;
  border-color: #0000;
}

.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #f0f0f0;
  background-color: #3d4341;
  border-color: #0000;
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #f0f0f0;
  border-color: #0000;
  box-shadow: 0 0 .5em #434a4840;
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #f0f0f0;
  background-color: #373d3b;
  border-color: #0000;
}

.file.is-primary .file-cta {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
}

.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #003224;
  border-color: #0000;
}

.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #003f2d40;
}

.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  color: #fcfcfc;
  background-color: #00261b;
  border-color: #0000;
}

.file.is-secondary .file-cta {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #0000;
}

.file.is-secondary:hover .file-cta, .file.is-secondary.is-hovered .file-cta {
  color: #1a1a1a;
  background-color: #ceded9;
  border-color: #0000;
}

.file.is-secondary:focus .file-cta, .file.is-secondary.is-focused .file-cta {
  color: #1a1a1a;
  border-color: #0000;
  box-shadow: 0 0 .5em #d6e3df40;
}

.file.is-secondary:active .file-cta, .file.is-secondary.is-active .file-cta {
  color: #1a1a1a;
  background-color: #c7d9d3;
  border-color: #0000;
}

.file.is-tertiary .file-cta {
  color: #fcfcfc;
  background-color: #0d8070;
  border-color: #0000;
}

.file.is-tertiary:hover .file-cta, .file.is-tertiary.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #0c7466;
  border-color: #0000;
}

.file.is-tertiary:focus .file-cta, .file.is-tertiary.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #0d807040;
}

.file.is-tertiary:active .file-cta, .file.is-tertiary.is-active .file-cta {
  color: #fcfcfc;
  background-color: #0b695c;
  border-color: #0000;
}

.file.is-primary-invert .file-cta {
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #0000;
}

.file.is-primary-invert:hover .file-cta, .file.is-primary-invert.is-hovered .file-cta {
  color: #003f2d;
  background-color: #f6f6f6;
  border-color: #0000;
}

.file.is-primary-invert:focus .file-cta, .file.is-primary-invert.is-focused .file-cta {
  color: #003f2d;
  border-color: #0000;
  box-shadow: 0 0 .5em #fcfcfc40;
}

.file.is-primary-invert:active .file-cta, .file.is-primary-invert.is-active .file-cta {
  color: #003f2d;
  background-color: #efefef;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #003224;
  border-color: #0000;
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #003f2d40;
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fcfcfc;
  background-color: #00261b;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #0000;
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #00a8d0;
  border-color: #0000;
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #00b2dd40;
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fcfcfc;
  background-color: #009dc4;
  border-color: #0000;
}

.file.is-pale-green .file-cta {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #0000;
}

.file.is-pale-green:hover .file-cta, .file.is-pale-green.is-hovered .file-cta {
  color: #1a1a1a;
  background-color: #ceded9;
  border-color: #0000;
}

.file.is-pale-green:focus .file-cta, .file.is-pale-green.is-focused .file-cta {
  color: #1a1a1a;
  border-color: #0000;
  box-shadow: 0 0 .5em #d6e3df40;
}

.file.is-pale-green:active .file-cta, .file.is-pale-green.is-active .file-cta {
  color: #1a1a1a;
  background-color: #c7d9d3;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #fcfcfc;
  background-color: #50e3c2;
  border-color: #0000;
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #45e1be;
  border-color: #0000;
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #50e3c240;
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #fcfcfc;
  background-color: #3adfba;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #1a1a1ab3;
  background-color: #ffdd57;
  border-color: #0000;
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: #1a1a1ab3;
  background-color: #ffdb4a;
  border-color: #0000;
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: #1a1a1ab3;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffdd5740;
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: #1a1a1ab3;
  background-color: #ffd83d;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fcfcfc;
  background-color: #fc5328;
  border-color: #0000;
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #fc491b;
  border-color: #0000;
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #fc532840;
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fcfcfc;
  background-color: #fc3f0f;
  border-color: #0000;
}

.file.is-me .file-cta {
  color: #fcfcfc;
  background-color: #00d2a0;
  border-color: #0000;
}

.file.is-me:hover .file-cta, .file.is-me.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #00c596;
  border-color: #0000;
}

.file.is-me:focus .file-cta, .file.is-me.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #00d2a040;
}

.file.is-me:active .file-cta, .file.is-me.is-active .file-cta {
  color: #fcfcfc;
  background-color: #00b98d;
  border-color: #0000;
}

.file.is-we .file-cta {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #0000;
}

.file.is-we:hover .file-cta, .file.is-we.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #00a8d0;
  border-color: #0000;
}

.file.is-we:focus .file-cta, .file.is-we.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #00b2dd40;
}

.file.is-we:active .file-cta, .file.is-we.is-active .file-cta {
  color: #fcfcfc;
  background-color: #009dc4;
  border-color: #0000;
}

.file.is-amenity .file-cta {
  color: #fcfcfc;
  background-color: #7d7ccf;
  border-color: #0000;
}

.file.is-amenity:hover .file-cta, .file.is-amenity.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #7473cc;
  border-color: #0000;
}

.file.is-amenity:focus .file-cta, .file.is-amenity.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #7d7ccf40;
}

.file.is-amenity:active .file-cta, .file.is-amenity.is-active .file-cta {
  color: #fcfcfc;
  background-color: #6a69c8;
  border-color: #0000;
}

.file.is-light-pale-green .file-cta {
  color: #1a1a1a;
  background-color: #eaf1ef;
  border-color: #0000;
}

.file.is-light-pale-green:hover .file-cta, .file.is-light-pale-green.is-hovered .file-cta {
  color: #1a1a1a;
  background-color: #e2ece9;
  border-color: #0000;
}

.file.is-light-pale-green:focus .file-cta, .file.is-light-pale-green.is-focused .file-cta {
  color: #1a1a1a;
  border-color: #0000;
  box-shadow: 0 0 .5em #eaf1ef40;
}

.file.is-light-pale-green:active .file-cta, .file.is-light-pale-green.is-active .file-cta {
  color: #1a1a1a;
  background-color: #dbe7e3;
  border-color: #0000;
}

.file.is-green-forest-darker .file-cta {
  color: #fcfcfc;
  background-color: #1d4837;
  border-color: #0000;
}

.file.is-green-forest-darker:hover .file-cta, .file.is-green-forest-darker.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #193f30;
  border-color: #0000;
}

.file.is-green-forest-darker:focus .file-cta, .file.is-green-forest-darker.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #1d483740;
}

.file.is-green-forest-darker:active .file-cta, .file.is-green-forest-darker.is-active .file-cta {
  color: #fcfcfc;
  background-color: #163629;
  border-color: #0000;
}

.file.is-green-forest-dark .file-cta {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #0000;
}

.file.is-green-forest-dark:hover .file-cta, .file.is-green-forest-dark.is-hovered .file-cta {
  color: #fcfcfc;
  background-color: #003224;
  border-color: #0000;
}

.file.is-green-forest-dark:focus .file-cta, .file.is-green-forest-dark.is-focused .file-cta {
  color: #fcfcfc;
  border-color: #0000;
  box-shadow: 0 0 .5em #003f2d40;
}

.file.is-green-forest-dark:active .file-cta, .file.is-green-forest-dark.is-active .file-cta {
  color: #fcfcfc;
  background-color: #00261b;
  border-color: #0000;
}

.file.is-small {
  font-size: .75rem;
}

.file.is-medium {
  font-size: 1.25rem;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 1.5rem;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.is-centered {
  justify-content: center;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}

.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  order: -1;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #434a48;
  background-color: #e9e9e9;
}

.file-label:hover .file-name {
  border-color: #d5d5d5;
}

.file-label:active .file-cta {
  color: #434a48;
  background-color: #e3e3e3;
}

.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  outline: none;
  width: .01em;
  height: .01em;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  white-space: nowrap;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  height: 2.25em;
  padding: calc(.375em - 1px) 1em;
  font-size: 1em;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.file-cta:focus, .file-cta.is-focused, .file-cta:active, .file-cta.is-active, .file-name:focus, .file-name.is-focused, .file-name:active, .file-name.is-active {
  outline: none;
}

.file-cta[disabled], .file-name[disabled] {
  pointer-events: none;
}

.file-cta {
  color: #4a4a4a;
  background-color: #f0f0f0;
}

.file-name {
  text-align: left;
  text-overflow: ellipsis;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  max-width: 16em;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #434a48;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1.25rem;
}

.label.is-large {
  font-size: 1.5rem;
}

.help {
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.help.is-white {
  color: #fcfcfc;
}

.help.is-black {
  color: #1a1a1a;
}

.help.is-light {
  color: #f0f0f0;
}

.help.is-dark {
  color: #434a48;
}

.help.is-primary {
  color: #003f2d;
}

.help.is-secondary {
  color: #d6e3df;
}

.help.is-tertiary {
  color: #0d8070;
}

.help.is-primary-invert {
  color: #fcfcfc;
}

.help.is-link {
  color: #003f2d;
}

.help.is-info {
  color: #00b2dd;
}

.help.is-pale-green {
  color: #d6e3df;
}

.help.is-success {
  color: #50e3c2;
}

.help.is-warning {
  color: #ffdd57;
}

.help.is-danger {
  color: #fc5328;
}

.help.is-me {
  color: #00d2a0;
}

.help.is-we {
  color: #00b2dd;
}

.help.is-amenity {
  color: #7d7ccf;
}

.help.is-light-pale-green {
  color: #eaf1ef;
}

.help.is-green-forest-darker {
  color: #1d4837;
}

.help.is-green-forest-dark {
  color: #003f2d;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:first-child .button, .field.has-addons .control:first-child .input, .field.has-addons .control:first-child .select select {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.field.has-addons .control:last-child .button, .field.has-addons .control:last-child .input, .field.has-addons .control:last-child .select select {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.field.has-addons .control .button, .field.has-addons .control .input, .field.has-addons .control .select select {
  border-radius: 0;
}

.field.has-addons .control .button:hover, .field.has-addons .control .button.is-hovered, .field.has-addons .control .input:hover, .field.has-addons .control .input.is-hovered, .field.has-addons .control .select select:hover, .field.has-addons .control .select select.is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:focus, .field.has-addons .control .button.is-focused, .field.has-addons .control .button:active, .field.has-addons .control .button.is-active, .field.has-addons .control .input:focus, .field.has-addons .control .input.is-focused, .field.has-addons .control .input:active, .field.has-addons .control .input.is-active, .field.has-addons .control .select select:focus, .field.has-addons .control .select select.is-focused, .field.has-addons .control .select select:active, .field.has-addons .control .select select.is-active {
  z-index: 3;
}

.field.has-addons .control .button:focus:hover, .field.has-addons .control .button.is-focused:hover, .field.has-addons .control .button:active:hover, .field.has-addons .control .button.is-active:hover, .field.has-addons .control .input:focus:hover, .field.has-addons .control .input.is-focused:hover, .field.has-addons .control .input:active:hover, .field.has-addons .control .input.is-active:hover, .field.has-addons .control .select select:focus:hover, .field.has-addons .control .select select.is-focused:hover, .field.has-addons .control .select select:active:hover, .field.has-addons .control .select select.is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (width >= 768px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (width <= 767px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (width >= 768px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1.25rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.5rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (width >= 768px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  text-align: left;
  font-size: 1rem;
  position: relative;
}

.control.has-icon .icon {
  color: #dbdbdb;
  pointer-events: none;
  z-index: 4;
  width: 2.25em;
  height: 2.25em;
  position: absolute;
  top: 0;
}

.control.has-icon .input:focus + .icon {
  color: #666;
}

.control.has-icon .input.is-small + .icon {
  font-size: .75rem;
}

.control.has-icon .input.is-medium + .icon {
  font-size: 1.25rem;
}

.control.has-icon .input.is-large + .icon {
  font-size: 1.5rem;
}

.control.has-icon:not(.has-icon-right) .icon {
  left: 0;
}

.control.has-icon:not(.has-icon-right) .input {
  padding-left: 2.25em;
}

.control.has-icon.has-icon-right .icon {
  right: 0;
}

.control.has-icon.has-icon-right .input {
  padding-right: 2.25em;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #666;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: .75rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1.25rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.5rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  pointer-events: none;
  z-index: 4;
  width: 2.25em;
  height: 2.25em;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.25em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.25em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #003f2d #003f2d;
  border-radius: 290486px;
  width: 1em;
  height: 1em;
  animation: .7s linear infinite spinAround;
  display: block;
  position: relative;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: .75rem;
}

.control.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.control.is-loading.is-large:after {
  font-size: 1.5rem;
}

.is-loss-factor-styles {
  position: absolute;
  left: 20px;
}

.underline-input {
  cursor: pointer;
  color: #003f2d;
  width: inherit;
  border-bottom: 2px solid #003f2d;
  justify-content: space-between;
  align-items: center;
  padding: 0 .325rem;
  display: flex;
}

.underline-input:hover, .underline-input .input:hover {
  color: #17e88f;
}

.underline-input.large-arrow-underline {
  background: linear-gradient(to right, #0000 5%, #003f2d 5% 95%, #0000 95%) 0 75% / 50% 2px no-repeat !important;
  border-bottom: none !important;
}

.underline-input.shorter-underline-width {
  width: 4rem;
}

.underline-input.is-loss-factor {
  position: relative;
}

.underline-input .input {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  color: #003f2d;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-select: unset;
  background-color: #0000;
  border: none;
  border-radius: 0;
  justify-content: flex-start;
  align-items: center;
  height: 2.25em;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.underline-input .input:focus, .underline-input .input.is-focused, .underline-input .input:active, .underline-input .input.is-active {
  outline: none;
}

.underline-input .input[disabled] {
  pointer-events: none;
}

.underline-input input[type="number"] {
  -moz-appearance: textfield;
  max-width: 2.5rem;
}

.underline-input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.underline-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.underline-input .dropdown-triangle {
  background: none;
  border-top: 7px solid;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
  margin-left: .75rem;
  position: relative;
}

.underline-input .dropdown-triangle:after {
  content: "";
  position: absolute;
  inset: -12px -15px -8px;
}

.underline-input .dropdown-triangle-up {
  background: none;
  border-bottom: 7px solid;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
  margin-bottom: .5rem;
  margin-left: .75rem;
  position: relative;
}

.underline-input .dropdown-triangle-up:after {
  content: "";
  position: absolute;
  inset: -8px -15px -12px;
}

.underline-input .dropdown-triangle-xlarge {
  background: none;
  border-top: 11px solid;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  margin-top: .2rem;
  margin-bottom: .5rem;
  margin-left: .75rem;
  position: relative;
}

.underline-input .dropdown-triangle-up-xlarge {
  background: none;
  border-bottom: 11px solid;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  margin-bottom: .7rem;
  margin-left: .75rem;
  position: relative;
}

.underline-input .number-arrow-icons {
  flex-direction: column;
  margin-right: .25rem;
  display: flex;
}

.underline-input .number-arrow-icons .icon:first-of-type {
  transform: rotate(180deg);
}

.underline-input:focus, .underline-input:active {
  outline: none;
}

@media screen and (width <= 767px) {
  .underline-input {
    max-width: 300px;
  }

  .underline-input .input {
    white-space: pre-wrap;
    height: auto;
    min-height: 2.25rem;
  }
}

.underline-input.is-disabled {
  pointer-events: none !important;
}

.underline-input.is-disabled input[type="number"] {
  text-align: center;
}

.underline-input.has-placeholder .input {
  min-width: 2.5rem;
}

.underline-input.is-primary {
  border-bottom: 2px solid #fcfcfc;
}

.underline-input.is-primary.has-dashed-border {
  border: 1px dashed #fcfcfc;
  border-radius: 2px;
}

.underline-input.is-primary .input {
  color: #fcfcfc;
  background-color: #0000;
}

.underline-input.is-primary .dropdown-triangle {
  background: none;
  border-top: 7px solid #fcfcfc;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
}

.underline-input.is-primary .dropdown-triangle-up {
  background: none;
  border-bottom: 7px solid #fcfcfc;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
}

.underline-input.is-primary.has-placeholder .input {
  color: #fcfcfc80;
}

.underline-input.is-primary.is-large .dropdown-triangle {
  background: none;
  border-top: 9px solid #fcfcfc;
  border-left: 9px solid #0000;
  border-right: 9px solid #0000;
  width: 0;
  height: 0;
  margin-top: .25rem;
}

.underline-input.is-primary.is-large .dropdown-triangle-up {
  background: none;
  border-bottom: 9px solid #fcfcfc;
  border-left: 9px solid #0000;
  border-right: 9px solid #0000;
  width: 0;
  height: 0;
}

.underline-input.is-primary.is-disabled, .underline-input.is-primary.is-inactive {
  border-bottom: 2px solid #fcfcfc33;
}

.underline-input.is-primary.is-disabled.has-dashed-border, .underline-input.is-primary.is-inactive.has-dashed-border {
  border: 1px dashed #fcfcfc33;
  border-radius: 2px;
}

.underline-input.is-primary.is-disabled *, .underline-input.is-primary.is-inactive * {
  color: #fcfcfc33;
}

.underline-input.is-primary.is-disabled .dropdown-triangle, .underline-input.is-primary.is-inactive .dropdown-triangle {
  background: none;
  border-top: 7px solid #fcfcfc33;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
}

.underline-input.is-primary.is-disabled .dropdown-triangle-up, .underline-input.is-primary.is-inactive .dropdown-triangle-up {
  background: none;
  border-bottom: 7px solid #fcfcfc33;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
}

.form {
  background-color: #fcfcfc;
}

.form .checkbox {
  margin-top: 1.5rem;
}

.form .underline-input-with-label {
  flex-direction: column;
  justify-content: flex-end;
  margin-top: .75rem;
  display: flex;
}

.form .underline-input-with-label.first-name, .form .underline-input-with-label.last-name {
  min-width: 48%;
  display: inline-flex;
}

.form .underline-input-with-label.first-name {
  margin-right: 2%;
}

.form .underline-input-with-label.last-name {
  margin-left: 2%;
}

.form .underline-input-label {
  color: #003f2d80;
  height: 1.25rem;
  font-size: .75rem;
  line-height: 1.25rem;
  display: block;
}

.form .underline-input {
  padding: 0 0 .75rem;
  border-bottom: 2px solid #003f2d !important;
}

.form .underline-input .input, .form .underline-input .input::placeholder {
  letter-spacing: .2px;
  width: 100%;
  font-family: Calibre Regular !important;
}

.form .underline-input .input {
  color: #003f2d;
  font-weight: 600;
}

.form .underline-input .input::placeholder {
  color: #003f2d80;
  font-weight: 500;
}

.form .underline-input input:-webkit-autofill {
  -webkit-text-fill-color: #003f2d;
  -webkit-box-shadow: inset 0 0 0 1000px #fcfcfc;
}

.form .underline-input input:-webkit-autofill:hover {
  -webkit-text-fill-color: #003f2d;
  -webkit-box-shadow: inset 0 0 0 1000px #fcfcfc;
}

.form .underline-input input:-webkit-autofill:focus {
  -webkit-text-fill-color: #003f2d;
  -webkit-box-shadow: inset 0 0 0 1000px #fcfcfc;
}

.form .form-checkbox.is-small {
  font-size: .75rem;
}

.form .form-checkbox.is-small span a {
  text-decoration: underline !important;
}

@media screen and (width <= 767px) {
  .form .underline-input .input {
    min-height: unset;
    font-size: .75rem;
  }

  .form .form-checkbox:not(.is-small) {
    font-size: .9rem;
  }
}

.radio-control input {
  display: none;
}

.radio-control label {
  cursor: pointer;
  align-items: center;
  height: 28px;
  font-size: 14px;
  display: flex;
}

.radio-control label:before {
  content: "";
  border: 1px solid #003f2d;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  display: inline-block;
}

.radio-control label:after {
  content: "";
  background-color: #0000;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 11px;
  left: 2px;
}

.radio-control label.is-active:after {
  background-color: #003f2d;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
}

.icon.is-tiny {
  width: .75em;
  height: .75em;
}

.icon.is-small {
  width: 1rem;
  height: 1rem;
}

.icon.is-medium {
  width: 2rem;
  height: 2rem;
}

.icon.is-large {
  width: 3rem;
  height: 3rem;
}

.icon.rotate-pos-90 {
  transform: rotate(90deg);
}

.icon.rotate-neg-90 {
  transform: rotate(-90deg);
}

.image {
  display: block;
  position: relative;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image.is-square img, .image.is-1by1 img, .image.is-4by3 img, .image.is-3by2 img, .image.is-16by9 img, .image.is-2by1 img {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.flooredImage {
  width: 350px;
  height: 150px;
}

.image.is-16x16 {
  width: 16px;
  height: 16px;
}

.image.is-24x24 {
  width: 24px;
  height: 24px;
}

.image.is-32x32 {
  width: 32px;
  height: 32px;
}

.image.is-48x48 {
  width: 48px;
  height: 48px;
}

.image.is-64x64 {
  width: 64px;
  height: 64px;
}

.image.is-96x96 {
  width: 96px;
  height: 96px;
}

.image.is-128x128 {
  width: 128px;
  height: 128px;
}

.notification {
  background-color: #fcfcfc;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification:not(:last-child) {
  margin-bottom: 1.5rem;
}

.notification a:not(.button) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fcfcfc;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5em;
  right: .5em;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.notification.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.notification.is-light {
  color: #434a48;
  background-color: #f0f0f0;
}

.notification.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
}

.notification.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
}

.notification.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.notification.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
}

.notification.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
}

.notification.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
}

.notification.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.notification.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.notification.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.notification.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.notification.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
}

.notification.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.notification.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.notification.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.notification.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.notification.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
}

.notification.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
}

.hbar {
  background-color: #f0f0f0;
  border-radius: .75rem;
  height: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  overflow: hidden;
}

.hbar:not(:last-child) {
  margin-bottom: 1.5rem;
}

.hbar.is-radiusless {
  overflow: visible;
}

.hbar.is-small {
  border-radius: .5rem;
  height: 1.25rem;
  font-size: .75rem;
  line-height: 1.25rem;
}

.hbar.is-large {
  border-radius: 1.5rem;
  height: 11rem;
  font-size: 4rem;
}

.hbar.is-large .hbar-segment {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.hbar.is-large .hbar-value {
  text-align: center;
  padding: 0;
}

.hbar-segment {
  color: #fcfcfc;
  background-color: #d6e3df;
}

.hbar-segment.is-placeholder {
  background-color: #dbdbdb;
  border: 0 solid #fcfcfc;
  border-width: 0 2px;
}

.hbar-segment.is-placeholder:first-child {
  border-left: 0;
}

.hbar-segment.is-placeholder:last-child {
  border-right: 0;
}

.hbar-segment.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.hbar-segment.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.hbar-segment.is-light {
  color: #434a48;
  background-color: #f0f0f0;
}

.hbar-segment.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
}

.hbar-segment.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
}

.hbar-segment.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.hbar-segment.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
}

.hbar-segment.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
}

.hbar-segment.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
}

.hbar-segment.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.hbar-segment.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.hbar-segment.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.hbar-segment.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.hbar-segment.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
}

.hbar-segment.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.hbar-segment.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.hbar-segment.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.hbar-segment.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.hbar-segment.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
}

.hbar-segment.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
}

.is-placeholder + .is-placeholder {
  border-left: 0;
}

.hbar-value {
  text-align: right;
  cursor: default;
  padding-right: .5rem;
}

.table {
  color: #434a48;
  background-color: #fcfcfc;
  margin-bottom: 1.5rem;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.table td.is-black, .table th.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.table td.is-light, .table th.is-light {
  color: #434a48;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.table td.is-dark, .table th.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
  border-color: #434a48;
}

.table td.is-primary, .table th.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.table td.is-secondary, .table th.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #d6e3df;
}

.table td.is-tertiary, .table th.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
  border-color: #0d8070;
}

.table td.is-primary-invert, .table th.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.table td.is-link, .table th.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.table td.is-info, .table th.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #00b2dd;
}

.table td.is-pale-green, .table th.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
  border-color: #d6e3df;
}

.table td.is-success, .table th.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
  border-color: #50e3c2;
}

.table td.is-warning, .table th.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
  border-color: #ffdd57;
}

.table td.is-danger, .table th.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
  border-color: #fc5328;
}

.table td.is-me, .table th.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
  border-color: #00d2a0;
}

.table td.is-we, .table th.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
  border-color: #00b2dd;
}

.table td.is-amenity, .table th.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
  border-color: #7d7ccf;
}

.table td.is-light-pale-green, .table th.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
  border-color: #eaf1ef;
}

.table td.is-green-forest-darker, .table th.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
  border-color: #1d4837;
}

.table td.is-green-forest-dark, .table th.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table th {
  color: #434a48;
  text-align: left;
}

.table tr.is-selected {
  color: #fcfcfc;
  background-color: #003f2d;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fcfcfc;
}

.table thead td, .table thead th {
  color: #434a48;
  border-width: 0 0 2px;
}

.table tfoot td, .table tfoot th {
  color: #434a48;
  border-width: 2px 0 0;
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #f0f0f0;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.tags {
  flex-wrap: wrap;
  place-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: absolute;
}

.tags .tag {
  border: none;
  margin-bottom: 1rem;
}

@media screen and (width >= 768px) {
  .tags .tag:not(:last-child) {
    margin-right: 1rem;
  }
}

.tags:last-child {
  margin-bottom: -1rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #fcfcfc;
  white-space: nowrap;
  background-color: #778f9c;
  border-radius: 2px;
  height: 2em;
  padding-left: 1.25em;
  padding-right: 1.25em;
  font-size: .75rem;
  line-height: 1.5;
}

.tag:not(body).tag-btn-width {
  max-width: 500px;
}

.tag:not(body):not(.is-block) {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.tag:not(body).is-clickable {
  cursor: pointer;
  transition: background-color .75s cubic-bezier(.23, 1, .32, 1);
}

.tag:not(body).is-clickable:focus, .tag:not(body).is-clickable:active {
  outline: none;
}

.tag:not(body).is-clickable:hover, .tag:not(body).is-clickable:focus {
  background-color: #96b3b6;
}

.tag:not(body).is-disabled {
  pointer-events: none;
  opacity: .5;
  outline: none;
}

.tag:not(body).btn-width {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  overflow: hidden;
}

.tag:not(body) .delete {
  cursor: pointer;
  align-items: center;
  margin-left: .25em;
  margin-right: -.375em;
  display: flex;
}

.tag:not(body) .flex-btn-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-basis: 92%;
  min-width: 0;
  overflow: hidden;
}

.tag:not(body).is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.tag:not(body).is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.tag:not(body).is-light {
  color: #434a48;
  background-color: #f0f0f0;
}

.tag:not(body).is-dark {
  color: #f0f0f0;
  background-color: #434a48;
}

.tag:not(body).is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
}

.tag:not(body).is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.tag:not(body).is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
}

.tag:not(body).is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
}

.tag:not(body).is-link {
  color: #fcfcfc;
  background-color: #003f2d;
}

.tag:not(body).is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.tag:not(body).is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.tag:not(body).is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.tag:not(body).is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.tag:not(body).is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
}

.tag:not(body).is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.tag:not(body).is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.tag:not(body).is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.tag:not(body).is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.tag:not(body).is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
}

.tag:not(body).is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
}

.tag:not(body).is-medium {
  font-size: 1rem;
}

.tag:not(body).is-large {
  font-size: 1.25rem;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:before, .tag:not(body).is-delete:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  width: 50%;
  height: 1px;
}

.tag:not(body).is-delete:after {
  width: 1px;
  height: 50%;
}

.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #0b695c;
}

.tag:not(body).is-delete:active {
  background-color: #085247;
}

.tag:not(body).is-rounded {
  border-radius: 290486px;
}

.tag:not(body).has-border {
  border: 2px solid #fcfcfc33;
}

@media screen and (width <= 767px) {
  .tag:not(body) {
    white-space: initial;
    width: 100%;
    height: auto;
  }
}

.title, .subtitle {
  word-break: break-word;
}

.title:not(:last-child), .subtitle:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #1a1a1a;
  font-family: Financier Regular, sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title + .highlight {
  margin-top: -.75rem;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.5rem;
}

.title.is-half-spaced + .subtitle {
  margin-top: -1rem;
}

.title.is-1 {
  font-size: 4rem;
}

@media screen and (width <= 767px) {
  .title.is-1-mobile {
    font-size: 4rem;
  }
}

.title.is-2 {
  font-size: 3rem;
}

@media screen and (width <= 767px) {
  .title.is-2-mobile {
    font-size: 3rem;
  }
}

.title.is-3 {
  font-size: 2.5rem;
}

@media screen and (width <= 767px) {
  .title.is-3-mobile {
    font-size: 2.5rem;
  }
}

.title.is-4 {
  font-size: 1.5rem;
}

@media screen and (width <= 767px) {
  .title.is-4-mobile {
    font-size: 1.5rem;
  }
}

.title.is-5 {
  font-size: 1.25rem;
}

@media screen and (width <= 767px) {
  .title.is-5-mobile {
    font-size: 1.25rem;
  }
}

.title.is-6 {
  font-size: 1rem;
}

@media screen and (width <= 767px) {
  .title.is-6-mobile {
    font-size: 1rem;
  }
}

.title.is-7 {
  font-size: .75rem;
}

@media screen and (width <= 767px) {
  .title.is-7-mobile {
    font-size: .75rem;
  }
}

.title.is-8 {
  font-size: .5rem;
}

@media screen and (width <= 767px) {
  .title.is-8-mobile {
    font-size: .5rem;
  }
}

.title.is-9 {
  font-size: .2rem;
}

@media screen and (width <= 767px) {
  .title.is-9-mobile {
    font-size: .2rem;
  }
}

.title2 {
  color: #1a1a1a;
  font-family: Calibre Regular;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.125;
}

.title2 strong {
  color: inherit;
  font-weight: inherit;
}

.title2 + .highlight {
  margin-top: -.75rem;
}

.title2:not(.is-spaced) + .subtitle {
  margin-top: -1.5rem;
}

.title2.is-half-spaced + .subtitle {
  margin-top: -1rem;
}

.title2.is-1 {
  font-size: 4rem;
}

@media screen and (width <= 767px) {
  .title2.is-1-mobile {
    font-size: 4rem;
  }
}

.title2.is-2 {
  font-size: 3rem;
}

@media screen and (width <= 767px) {
  .title2.is-2-mobile {
    font-size: 3rem;
  }
}

.title2.is-3 {
  font-size: 2.5rem;
}

@media screen and (width <= 767px) {
  .title2.is-3-mobile {
    font-size: 2.5rem;
  }
}

.title2.is-4 {
  font-size: 1.5rem;
}

@media screen and (width <= 767px) {
  .title2.is-4-mobile {
    font-size: 1.5rem;
  }
}

.title2.is-5 {
  font-size: 1.25rem;
}

@media screen and (width <= 767px) {
  .title2.is-5-mobile {
    font-size: 1.25rem;
  }
}

.title2.is-6 {
  font-size: 1rem;
}

@media screen and (width <= 767px) {
  .title2.is-6-mobile {
    font-size: 1rem;
  }
}

.title2.is-7 {
  font-size: .75rem;
}

@media screen and (width <= 767px) {
  .title2.is-7-mobile {
    font-size: .75rem;
  }
}

.title2.is-8 {
  font-size: .5rem;
}

@media screen and (width <= 767px) {
  .title2.is-8-mobile {
    font-size: .5rem;
  }
}

.title2.is-9 {
  font-size: .2rem;
}

@media screen and (width <= 767px) {
  .title2.is-9-mobile {
    font-size: .2rem;
  }
}

.subtitle {
  color: #1a1a1a;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #1a1a1a;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -.75rem;
}

.subtitle.is-1 {
  font-size: 4rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-1-mobile {
    font-size: 4rem;
  }
}

.subtitle.is-2 {
  font-size: 3rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-2-mobile {
    font-size: 3rem;
  }
}

.subtitle.is-3 {
  font-size: 2.5rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-3-mobile {
    font-size: 2.5rem;
  }
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-4-mobile {
    font-size: 1.5rem;
  }
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-5-mobile {
    font-size: 1.25rem;
  }
}

.subtitle.is-6 {
  font-size: 1rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-6-mobile {
    font-size: 1rem;
  }
}

.subtitle.is-7 {
  font-size: .75rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-7-mobile {
    font-size: .75rem;
  }
}

.subtitle.is-8 {
  font-size: .5rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-8-mobile {
    font-size: .5rem;
  }
}

.subtitle.is-9 {
  font-size: .2rem;
}

@media screen and (width <= 767px) {
  .subtitle.is-9-mobile {
    font-size: .2rem;
  }
}

.block:not(:last-child) {
  margin-bottom: 1.5rem;
}

.delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: -10%;
  background-color: #0000;
  border: none;
  border-radius: 50%;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.delete:before, .delete:after {
  content: "";
  transform-origin: center;
  background-color: #fcfcfc;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.delete:before {
  width: 50%;
  height: 1px;
}

.delete:after {
  width: 1px;
  height: 50%;
}

.delete:hover, .delete:focus, .delete:active {
  background-color: #1a1a1a4d;
}

.delete.is-small {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.delete.is-medium {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.delete.is-large {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.highlight {
  max-width: 100%;
  padding: 0;
  font-weight: 400;
  overflow: hidden;
}

.highlight:not(:last-child) {
  margin-bottom: 1.5rem;
}

.highlight pre {
  max-width: 100%;
  overflow: auto;
}

.loader {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #003f2d #003f2d;
  border-radius: 290486px;
  width: 1em;
  height: 1em;
  animation: .7s linear infinite spinAround;
  display: block;
  position: relative;
}

.loader.light {
  border: 2px solid #0000;
  border-color: #0000 #0000 #d6e3df #d6e3df;
}

.number {
  text-align: center;
  vertical-align: top;
  background-color: #fcfcfc;
  border-radius: 290486px;
  justify-content: center;
  align-items: center;
  min-width: 2.5em;
  height: 2em;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  display: inline-flex;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 767px) {
  .column.is-narrow-mobile {
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 768px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 989px) {
  .column.is-narrow-touch {
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 990px) {
  .column.is-narrow-desktop {
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1182px) {
  .column.is-narrow-widescreen {
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1374px) {
  .column.is-narrow-fullhd {
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.column.align-center {
  align-self: center;
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 768px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 990px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0 * .25rem;
}

.columns.is-variable.is-1 {
  --columnGap: 1 * .25rem;
}

.columns.is-variable.is-2 {
  --columnGap: 2 * .25rem;
}

.columns.is-variable.is-3 {
  --columnGap: 3 * .25rem;
}

.columns.is-variable.is-4 {
  --columnGap: 4 * .25rem;
}

.columns.is-variable.is-5 {
  --columnGap: 5 * .25rem;
}

.columns.is-variable.is-6 {
  --columnGap: 6 * .25rem;
}

.columns.is-variable.is-7 {
  --columnGap: 7 * .25rem;
}

.columns.is-variable.is-8 {
  --columnGap: 8 * .25rem;
}

.tile {
  flex: 1 1 0;
  align-items: stretch;
  min-height: min-content;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (width >= 768px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    flex: none;
    width: 8.33333%;
  }

  .tile.is-2 {
    flex: none;
    width: 16.6667%;
  }

  .tile.is-3 {
    flex: none;
    width: 25%;
  }

  .tile.is-4 {
    flex: none;
    width: 33.3333%;
  }

  .tile.is-5 {
    flex: none;
    width: 41.6667%;
  }

  .tile.is-6 {
    flex: none;
    width: 50%;
  }

  .tile.is-7 {
    flex: none;
    width: 58.3333%;
  }

  .tile.is-8 {
    flex: none;
    width: 66.6667%;
  }

  .tile.is-9 {
    flex: none;
    width: 75%;
  }

  .tile.is-10 {
    flex: none;
    width: 83.3333%;
  }

  .tile.is-11 {
    flex: none;
    width: 91.6667%;
  }

  .tile.is-12 {
    flex: none;
    width: 100%;
  }
}

#spacer-logo {
  width: 140px;
  height: 100%;
}

@media screen and (width <= 767px) {
  #spacer-logo {
    width: 100px;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #1a1a1a;
  background-color: #fcfcfc;
}

.hero.is-white a:not(.button), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title, .hero.is-white .title2 {
  color: #1a1a1a;
}

.hero.is-white .subtitle {
  color: #1a1a1ae6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #1a1a1a;
}

@media screen and (width <= 989px) {
  .hero.is-white .navbar-menu {
    background-color: #fcfcfc;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #1a1a1ab3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #efefef;
}

.hero.is-white .tabs a {
  color: #1a1a1a;
  opacity: .9;
}

.hero.is-white .tabs a:hover, .hero.is-white .tabs li.is-active a {
  opacity: 1;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #1a1a1a;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fcfcfc;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e5e0e1 0%, #fcfcfc 71%, #fff 100%);
}

@media screen and (width <= 767px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e5e0e1 0%, #fcfcfc 71%, #fff 100%);
  }
}

.hero.is-black {
  color: #fcfcfc;
  background-color: #1a1a1a;
}

.hero.is-black a:not(.button), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title, .hero.is-black .title2 {
  color: #fcfcfc;
}

.hero.is-black .subtitle {
  color: #fcfcfce6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-black .navbar-menu {
    background-color: #1a1a1a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0d0d0d;
}

.hero.is-black .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-black .tabs a:hover, .hero.is-black .tabs li.is-active a {
  opacity: 1;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #1a1a1a;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #010000 0%, #1a1a1a 71%, #292525 100%);
}

@media screen and (width <= 767px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #010000 0%, #1a1a1a 71%, #292525 100%);
  }
}

.hero.is-light {
  color: #434a48;
  background-color: #f0f0f0;
}

.hero.is-light a:not(.button), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title, .hero.is-light .title2 {
  color: #434a48;
}

.hero.is-light .subtitle {
  color: #434a48e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #434a48;
}

@media screen and (width <= 989px) {
  .hero.is-light .navbar-menu {
    background-color: #f0f0f0;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #434a48b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #434a48;
  background-color: #e3e3e3;
}

.hero.is-light .tabs a {
  color: #434a48;
  opacity: .9;
}

.hero.is-light .tabs a:hover, .hero.is-light .tabs li.is-active a {
  opacity: 1;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #434a48;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f0f0f0;
  background-color: #434a48;
  border-color: #434a48;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dad2d3 0%, #f0f0f0 71%, #fdfcfc 100%);
}

@media screen and (width <= 767px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dad2d3 0%, #f0f0f0 71%, #fdfcfc 100%);
  }
}

.hero.is-dark {
  color: #f0f0f0;
  background-color: #434a48;
}

.hero.is-dark a:not(.button), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title, .hero.is-dark .title2 {
  color: #f0f0f0;
}

.hero.is-dark .subtitle {
  color: #f0f0f0e6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #f0f0f0;
}

@media screen and (width <= 989px) {
  .hero.is-dark .navbar-menu {
    background-color: #434a48;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #f0f0f0b3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #f0f0f0;
  background-color: #373d3b;
}

.hero.is-dark .tabs a {
  color: #f0f0f0;
  opacity: .9;
}

.hero.is-dark .tabs a:hover, .hero.is-dark .tabs li.is-active a {
  opacity: 1;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #f0f0f0;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #434a48;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #26342e 0%, #434a48 71%, #4b5c5a 100%);
}

@media screen and (width <= 767px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #26342e 0%, #434a48 71%, #4b5c5a 100%);
  }
}

.hero.is-primary {
  color: #fcfcfc;
  background-color: #003f2d;
}

.hero.is-primary a:not(.button), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title, .hero.is-primary .title2 {
  color: #fcfcfc;
}

.hero.is-primary .subtitle {
  color: #fcfcfce6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-primary .navbar-menu {
    background-color: #003f2d;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #00261b;
}

.hero.is-primary .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-primary .tabs a:hover, .hero.is-primary .tabs li.is-active a {
  opacity: 1;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #000c07 0%, #003f2d 71%, #00594e 100%);
}

@media screen and (width <= 767px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000c07 0%, #003f2d 71%, #00594e 100%);
  }
}

.hero.is-secondary {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.hero.is-secondary a:not(.button), .hero.is-secondary strong {
  color: inherit;
}

.hero.is-secondary .title, .hero.is-secondary .title2 {
  color: #1a1a1a;
}

.hero.is-secondary .subtitle {
  color: #1a1a1ae6;
}

.hero.is-secondary .subtitle a:not(.button), .hero.is-secondary .subtitle strong {
  color: #1a1a1a;
}

@media screen and (width <= 989px) {
  .hero.is-secondary .navbar-menu {
    background-color: #d6e3df;
  }
}

.hero.is-secondary .navbar-item, .hero.is-secondary .navbar-link {
  color: #1a1a1ab3;
}

.hero.is-secondary a.navbar-item:hover, .hero.is-secondary a.navbar-item.is-active, .hero.is-secondary .navbar-link:hover, .hero.is-secondary .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #c7d9d3;
}

.hero.is-secondary .tabs a {
  color: #1a1a1a;
  opacity: .9;
}

.hero.is-secondary .tabs a:hover, .hero.is-secondary .tabs li.is-active a {
  opacity: 1;
}

.hero.is-secondary .tabs.is-boxed a, .hero.is-secondary .tabs.is-toggle a {
  color: #1a1a1a;
}

.hero.is-secondary .tabs.is-boxed a:hover, .hero.is-secondary .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-secondary .tabs.is-boxed li.is-active a, .hero.is-secondary .tabs.is-boxed li.is-active a:hover, .hero.is-secondary .tabs.is-toggle li.is-active a, .hero.is-secondary .tabs.is-toggle li.is-active a:hover {
  color: #d6e3df;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.hero.is-secondary.is-bold {
  background-image: linear-gradient(141deg, #b2d4c4 0%, #d6e3df 71%, #e4eeed 100%);
}

@media screen and (width <= 767px) {
  .hero.is-secondary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #b2d4c4 0%, #d6e3df 71%, #e4eeed 100%);
  }
}

.hero.is-tertiary {
  color: #fcfcfc;
  background-color: #0d8070;
}

.hero.is-tertiary a:not(.button), .hero.is-tertiary strong {
  color: inherit;
}

.hero.is-tertiary .title, .hero.is-tertiary .title2 {
  color: #fcfcfc;
}

.hero.is-tertiary .subtitle {
  color: #fcfcfce6;
}

.hero.is-tertiary .subtitle a:not(.button), .hero.is-tertiary .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-tertiary .navbar-menu {
    background-color: #0d8070;
  }
}

.hero.is-tertiary .navbar-item, .hero.is-tertiary .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-tertiary a.navbar-item:hover, .hero.is-tertiary a.navbar-item.is-active, .hero.is-tertiary .navbar-link:hover, .hero.is-tertiary .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #0b695c;
}

.hero.is-tertiary .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-tertiary .tabs a:hover, .hero.is-tertiary .tabs li.is-active a {
  opacity: 1;
}

.hero.is-tertiary .tabs.is-boxed a, .hero.is-tertiary .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-tertiary .tabs.is-boxed a:hover, .hero.is-tertiary .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-tertiary .tabs.is-boxed li.is-active a, .hero.is-tertiary .tabs.is-boxed li.is-active a:hover, .hero.is-tertiary .tabs.is-toggle li.is-active a, .hero.is-tertiary .tabs.is-toggle li.is-active a:hover {
  color: #0d8070;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-tertiary.is-bold {
  background-image: linear-gradient(141deg, #04563d 0%, #0d8070 71%, #0b979b 100%);
}

@media screen and (width <= 767px) {
  .hero.is-tertiary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #04563d 0%, #0d8070 71%, #0b979b 100%);
  }
}

.hero.is-primary-invert {
  color: #003f2d;
  background-color: #fcfcfc;
}

.hero.is-primary-invert a:not(.button), .hero.is-primary-invert strong {
  color: inherit;
}

.hero.is-primary-invert .title, .hero.is-primary-invert .title2 {
  color: #003f2d;
}

.hero.is-primary-invert .subtitle {
  color: #003f2de6;
}

.hero.is-primary-invert .subtitle a:not(.button), .hero.is-primary-invert .subtitle strong {
  color: #003f2d;
}

@media screen and (width <= 989px) {
  .hero.is-primary-invert .navbar-menu {
    background-color: #fcfcfc;
  }
}

.hero.is-primary-invert .navbar-item, .hero.is-primary-invert .navbar-link {
  color: #003f2db3;
}

.hero.is-primary-invert a.navbar-item:hover, .hero.is-primary-invert a.navbar-item.is-active, .hero.is-primary-invert .navbar-link:hover, .hero.is-primary-invert .navbar-link.is-active {
  color: #003f2d;
  background-color: #efefef;
}

.hero.is-primary-invert .tabs a {
  color: #003f2d;
  opacity: .9;
}

.hero.is-primary-invert .tabs a:hover, .hero.is-primary-invert .tabs li.is-active a {
  opacity: 1;
}

.hero.is-primary-invert .tabs.is-boxed a, .hero.is-primary-invert .tabs.is-toggle a {
  color: #003f2d;
}

.hero.is-primary-invert .tabs.is-boxed a:hover, .hero.is-primary-invert .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-primary-invert .tabs.is-boxed li.is-active a, .hero.is-primary-invert .tabs.is-boxed li.is-active a:hover, .hero.is-primary-invert .tabs.is-toggle li.is-active a, .hero.is-primary-invert .tabs.is-toggle li.is-active a:hover {
  color: #fcfcfc;
  background-color: #003f2d;
  border-color: #003f2d;
}

.hero.is-primary-invert.is-bold {
  background-image: linear-gradient(141deg, #e5e0e1 0%, #fcfcfc 71%, #fff 100%);
}

@media screen and (width <= 767px) {
  .hero.is-primary-invert.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e5e0e1 0%, #fcfcfc 71%, #fff 100%);
  }
}

.hero.is-link {
  color: #fcfcfc;
  background-color: #003f2d;
}

.hero.is-link a:not(.button), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title, .hero.is-link .title2 {
  color: #fcfcfc;
}

.hero.is-link .subtitle {
  color: #fcfcfce6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-link .navbar-menu {
    background-color: #003f2d;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #00261b;
}

.hero.is-link .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-link .tabs a:hover, .hero.is-link .tabs li.is-active a {
  opacity: 1;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #000c07 0%, #003f2d 71%, #00594e 100%);
}

@media screen and (width <= 767px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000c07 0%, #003f2d 71%, #00594e 100%);
  }
}

.hero.is-info {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.hero.is-info a:not(.button), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title, .hero.is-info .title2 {
  color: #fcfcfc;
}

.hero.is-info .subtitle {
  color: #fcfcfce6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-info .navbar-menu {
    background-color: #00b2dd;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #009dc4;
}

.hero.is-info .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-info .tabs a:hover, .hero.is-info .tabs li.is-active a {
  opacity: 1;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #00b2dd;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #00a5aa 0%, #00b2dd 71%, #009df7 100%);
}

@media screen and (width <= 767px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #00a5aa 0%, #00b2dd 71%, #009df7 100%);
  }
}

.hero.is-pale-green {
  color: #1a1a1a;
  background-color: #d6e3df;
}

.hero.is-pale-green a:not(.button), .hero.is-pale-green strong {
  color: inherit;
}

.hero.is-pale-green .title, .hero.is-pale-green .title2 {
  color: #1a1a1a;
}

.hero.is-pale-green .subtitle {
  color: #1a1a1ae6;
}

.hero.is-pale-green .subtitle a:not(.button), .hero.is-pale-green .subtitle strong {
  color: #1a1a1a;
}

@media screen and (width <= 989px) {
  .hero.is-pale-green .navbar-menu {
    background-color: #d6e3df;
  }
}

.hero.is-pale-green .navbar-item, .hero.is-pale-green .navbar-link {
  color: #1a1a1ab3;
}

.hero.is-pale-green a.navbar-item:hover, .hero.is-pale-green a.navbar-item.is-active, .hero.is-pale-green .navbar-link:hover, .hero.is-pale-green .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #c7d9d3;
}

.hero.is-pale-green .tabs a {
  color: #1a1a1a;
  opacity: .9;
}

.hero.is-pale-green .tabs a:hover, .hero.is-pale-green .tabs li.is-active a {
  opacity: 1;
}

.hero.is-pale-green .tabs.is-boxed a, .hero.is-pale-green .tabs.is-toggle a {
  color: #1a1a1a;
}

.hero.is-pale-green .tabs.is-boxed a:hover, .hero.is-pale-green .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-pale-green .tabs.is-boxed li.is-active a, .hero.is-pale-green .tabs.is-boxed li.is-active a:hover, .hero.is-pale-green .tabs.is-toggle li.is-active a, .hero.is-pale-green .tabs.is-toggle li.is-active a:hover {
  color: #d6e3df;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.hero.is-pale-green.is-bold {
  background-image: linear-gradient(141deg, #b2d4c4 0%, #d6e3df 71%, #e4eeed 100%);
}

@media screen and (width <= 767px) {
  .hero.is-pale-green.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #b2d4c4 0%, #d6e3df 71%, #e4eeed 100%);
  }
}

.hero.is-success {
  color: #fcfcfc;
  background-color: #50e3c2;
}

.hero.is-success a:not(.button), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title, .hero.is-success .title2 {
  color: #fcfcfc;
}

.hero.is-success .subtitle {
  color: #fcfcfce6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-success .navbar-menu {
    background-color: #50e3c2;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #3adfba;
}

.hero.is-success .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-success .tabs a:hover, .hero.is-success .tabs li.is-active a {
  opacity: 1;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #50e3c2;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #17e997 0%, #50e3c2 71%, #62ebe3 100%);
}

@media screen and (width <= 767px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #17e997 0%, #50e3c2 71%, #62ebe3 100%);
  }
}

.hero.is-warning {
  color: #1a1a1ab3;
  background-color: #ffdd57;
}

.hero.is-warning a:not(.button), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title, .hero.is-warning .title2 {
  color: #1a1a1ab3;
}

.hero.is-warning .subtitle {
  color: #1a1a1ae6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #1a1a1ab3;
}

@media screen and (width <= 989px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffdd57;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #1a1a1ab3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #1a1a1ab3;
  background-color: #ffd83d;
}

.hero.is-warning .tabs a {
  color: #1a1a1ab3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover, .hero.is-warning .tabs li.is-active a {
  opacity: 1;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #1a1a1ab3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffdd57;
  background-color: #1a1a1ab3;
  border-color: #1a1a1ab3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffaf24 0%, #ffdd57 71%, #fffa70 100%);
}

@media screen and (width <= 767px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffaf24 0%, #ffdd57 71%, #fffa70 100%);
  }
}

.hero.is-danger {
  color: #fcfcfc;
  background-color: #fc5328;
}

.hero.is-danger a:not(.button), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title, .hero.is-danger .title2 {
  color: #fcfcfc;
}

.hero.is-danger .subtitle {
  color: #fcfcfce6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-danger .navbar-menu {
    background-color: #fc5328;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #fc3f0f;
}

.hero.is-danger .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-danger .tabs a:hover, .hero.is-danger .tabs li.is-active a {
  opacity: 1;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #fc5328;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #f10900 0%, #fc5328 71%, #ff863f 100%);
}

@media screen and (width <= 767px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #f10900 0%, #fc5328 71%, #ff863f 100%);
  }
}

.hero.is-me {
  color: #fcfcfc;
  background-color: #00d2a0;
}

.hero.is-me a:not(.button), .hero.is-me strong {
  color: inherit;
}

.hero.is-me .title, .hero.is-me .title2 {
  color: #fcfcfc;
}

.hero.is-me .subtitle {
  color: #fcfcfce6;
}

.hero.is-me .subtitle a:not(.button), .hero.is-me .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-me .navbar-menu {
    background-color: #00d2a0;
  }
}

.hero.is-me .navbar-item, .hero.is-me .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-me a.navbar-item:hover, .hero.is-me a.navbar-item.is-active, .hero.is-me .navbar-link:hover, .hero.is-me .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #00b98d;
}

.hero.is-me .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-me .tabs a:hover, .hero.is-me .tabs li.is-active a {
  opacity: 1;
}

.hero.is-me .tabs.is-boxed a, .hero.is-me .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-me .tabs.is-boxed a:hover, .hero.is-me .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-me .tabs.is-boxed li.is-active a, .hero.is-me .tabs.is-boxed li.is-active a:hover, .hero.is-me .tabs.is-toggle li.is-active a, .hero.is-me .tabs.is-toggle li.is-active a:hover {
  color: #00d2a0;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-me.is-bold {
  background-image: linear-gradient(141deg, #009f5f 0%, #00d2a0 71%, #00ecdb 100%);
}

@media screen and (width <= 767px) {
  .hero.is-me.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #009f5f 0%, #00d2a0 71%, #00ecdb 100%);
  }
}

.hero.is-we {
  color: #fcfcfc;
  background-color: #00b2dd;
}

.hero.is-we a:not(.button), .hero.is-we strong {
  color: inherit;
}

.hero.is-we .title, .hero.is-we .title2 {
  color: #fcfcfc;
}

.hero.is-we .subtitle {
  color: #fcfcfce6;
}

.hero.is-we .subtitle a:not(.button), .hero.is-we .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-we .navbar-menu {
    background-color: #00b2dd;
  }
}

.hero.is-we .navbar-item, .hero.is-we .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-we a.navbar-item:hover, .hero.is-we a.navbar-item.is-active, .hero.is-we .navbar-link:hover, .hero.is-we .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #009dc4;
}

.hero.is-we .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-we .tabs a:hover, .hero.is-we .tabs li.is-active a {
  opacity: 1;
}

.hero.is-we .tabs.is-boxed a, .hero.is-we .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-we .tabs.is-boxed a:hover, .hero.is-we .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-we .tabs.is-boxed li.is-active a, .hero.is-we .tabs.is-boxed li.is-active a:hover, .hero.is-we .tabs.is-toggle li.is-active a, .hero.is-we .tabs.is-toggle li.is-active a:hover {
  color: #00b2dd;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-we.is-bold {
  background-image: linear-gradient(141deg, #00a5aa 0%, #00b2dd 71%, #009df7 100%);
}

@media screen and (width <= 767px) {
  .hero.is-we.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #00a5aa 0%, #00b2dd 71%, #009df7 100%);
  }
}

.hero.is-amenity {
  color: #fcfcfc;
  background-color: #7d7ccf;
}

.hero.is-amenity a:not(.button), .hero.is-amenity strong {
  color: inherit;
}

.hero.is-amenity .title, .hero.is-amenity .title2 {
  color: #fcfcfc;
}

.hero.is-amenity .subtitle {
  color: #fcfcfce6;
}

.hero.is-amenity .subtitle a:not(.button), .hero.is-amenity .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-amenity .navbar-menu {
    background-color: #7d7ccf;
  }
}

.hero.is-amenity .navbar-item, .hero.is-amenity .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-amenity a.navbar-item:hover, .hero.is-amenity a.navbar-item.is-active, .hero.is-amenity .navbar-link:hover, .hero.is-amenity .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #6a69c8;
}

.hero.is-amenity .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-amenity .tabs a:hover, .hero.is-amenity .tabs li.is-active a {
  opacity: 1;
}

.hero.is-amenity .tabs.is-boxed a, .hero.is-amenity .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-amenity .tabs.is-boxed a:hover, .hero.is-amenity .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-amenity .tabs.is-boxed li.is-active a, .hero.is-amenity .tabs.is-boxed li.is-active a:hover, .hero.is-amenity .tabs.is-toggle li.is-active a, .hero.is-amenity .tabs.is-toggle li.is-active a:hover {
  color: #7d7ccf;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-amenity.is-bold {
  background-image: linear-gradient(141deg, #4b5fcd 0%, #7d7ccf 71%, #998bda 100%);
}

@media screen and (width <= 767px) {
  .hero.is-amenity.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #4b5fcd 0%, #7d7ccf 71%, #998bda 100%);
  }
}

.hero.is-light-pale-green {
  color: #1a1a1a;
  background-color: #eaf1ef;
}

.hero.is-light-pale-green a:not(.button), .hero.is-light-pale-green strong {
  color: inherit;
}

.hero.is-light-pale-green .title, .hero.is-light-pale-green .title2 {
  color: #1a1a1a;
}

.hero.is-light-pale-green .subtitle {
  color: #1a1a1ae6;
}

.hero.is-light-pale-green .subtitle a:not(.button), .hero.is-light-pale-green .subtitle strong {
  color: #1a1a1a;
}

@media screen and (width <= 989px) {
  .hero.is-light-pale-green .navbar-menu {
    background-color: #eaf1ef;
  }
}

.hero.is-light-pale-green .navbar-item, .hero.is-light-pale-green .navbar-link {
  color: #1a1a1ab3;
}

.hero.is-light-pale-green a.navbar-item:hover, .hero.is-light-pale-green a.navbar-item.is-active, .hero.is-light-pale-green .navbar-link:hover, .hero.is-light-pale-green .navbar-link.is-active {
  color: #1a1a1a;
  background-color: #dbe7e3;
}

.hero.is-light-pale-green .tabs a {
  color: #1a1a1a;
  opacity: .9;
}

.hero.is-light-pale-green .tabs a:hover, .hero.is-light-pale-green .tabs li.is-active a {
  opacity: 1;
}

.hero.is-light-pale-green .tabs.is-boxed a, .hero.is-light-pale-green .tabs.is-toggle a {
  color: #1a1a1a;
}

.hero.is-light-pale-green .tabs.is-boxed a:hover, .hero.is-light-pale-green .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-light-pale-green .tabs.is-boxed li.is-active a, .hero.is-light-pale-green .tabs.is-boxed li.is-active a:hover, .hero.is-light-pale-green .tabs.is-toggle li.is-active a, .hero.is-light-pale-green .tabs.is-toggle li.is-active a:hover {
  color: #eaf1ef;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.hero.is-light-pale-green.is-bold {
  background-image: linear-gradient(141deg, #c7e1d5 0%, #eaf1ef 71%, #f9fbfb 100%);
}

@media screen and (width <= 767px) {
  .hero.is-light-pale-green.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #c7e1d5 0%, #eaf1ef 71%, #f9fbfb 100%);
  }
}

.hero.is-green-forest-darker {
  color: #fcfcfc;
  background-color: #1d4837;
}

.hero.is-green-forest-darker a:not(.button), .hero.is-green-forest-darker strong {
  color: inherit;
}

.hero.is-green-forest-darker .title, .hero.is-green-forest-darker .title2 {
  color: #fcfcfc;
}

.hero.is-green-forest-darker .subtitle {
  color: #fcfcfce6;
}

.hero.is-green-forest-darker .subtitle a:not(.button), .hero.is-green-forest-darker .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-green-forest-darker .navbar-menu {
    background-color: #1d4837;
  }
}

.hero.is-green-forest-darker .navbar-item, .hero.is-green-forest-darker .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-green-forest-darker a.navbar-item:hover, .hero.is-green-forest-darker a.navbar-item.is-active, .hero.is-green-forest-darker .navbar-link:hover, .hero.is-green-forest-darker .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #163629;
}

.hero.is-green-forest-darker .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-green-forest-darker .tabs a:hover, .hero.is-green-forest-darker .tabs li.is-active a {
  opacity: 1;
}

.hero.is-green-forest-darker .tabs.is-boxed a, .hero.is-green-forest-darker .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-green-forest-darker .tabs.is-boxed a:hover, .hero.is-green-forest-darker .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-green-forest-darker .tabs.is-boxed li.is-active a, .hero.is-green-forest-darker .tabs.is-boxed li.is-active a:hover, .hero.is-green-forest-darker .tabs.is-toggle li.is-active a, .hero.is-green-forest-darker .tabs.is-toggle li.is-active a:hover {
  color: #1d4837;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-green-forest-darker.is-bold {
  background-image: linear-gradient(141deg, #0c2617 0%, #1d4837 71%, #215d50 100%);
}

@media screen and (width <= 767px) {
  .hero.is-green-forest-darker.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #0c2617 0%, #1d4837 71%, #215d50 100%);
  }
}

.hero.is-green-forest-dark {
  color: #fcfcfc;
  background-color: #003f2d;
}

.hero.is-green-forest-dark a:not(.button), .hero.is-green-forest-dark strong {
  color: inherit;
}

.hero.is-green-forest-dark .title, .hero.is-green-forest-dark .title2 {
  color: #fcfcfc;
}

.hero.is-green-forest-dark .subtitle {
  color: #fcfcfce6;
}

.hero.is-green-forest-dark .subtitle a:not(.button), .hero.is-green-forest-dark .subtitle strong {
  color: #fcfcfc;
}

@media screen and (width <= 989px) {
  .hero.is-green-forest-dark .navbar-menu {
    background-color: #003f2d;
  }
}

.hero.is-green-forest-dark .navbar-item, .hero.is-green-forest-dark .navbar-link {
  color: #fcfcfcb3;
}

.hero.is-green-forest-dark a.navbar-item:hover, .hero.is-green-forest-dark a.navbar-item.is-active, .hero.is-green-forest-dark .navbar-link:hover, .hero.is-green-forest-dark .navbar-link.is-active {
  color: #fcfcfc;
  background-color: #00261b;
}

.hero.is-green-forest-dark .tabs a {
  color: #fcfcfc;
  opacity: .9;
}

.hero.is-green-forest-dark .tabs a:hover, .hero.is-green-forest-dark .tabs li.is-active a {
  opacity: 1;
}

.hero.is-green-forest-dark .tabs.is-boxed a, .hero.is-green-forest-dark .tabs.is-toggle a {
  color: #fcfcfc;
}

.hero.is-green-forest-dark .tabs.is-boxed a:hover, .hero.is-green-forest-dark .tabs.is-toggle a:hover {
  background-color: #1a1a1a1a;
}

.hero.is-green-forest-dark .tabs.is-boxed li.is-active a, .hero.is-green-forest-dark .tabs.is-boxed li.is-active a:hover, .hero.is-green-forest-dark .tabs.is-toggle li.is-active a, .hero.is-green-forest-dark .tabs.is-toggle li.is-active a:hover {
  color: #003f2d;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.hero.is-green-forest-dark.is-bold {
  background-image: linear-gradient(141deg, #000c07 0%, #003f2d 71%, #00594e 100%);
}

@media screen and (width <= 767px) {
  .hero.is-green-forest-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000c07 0%, #003f2d 71%, #00594e 100%);
  }
}

.hero.is-small .hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media screen and (width >= 768px), print {
  .hero.is-medium .hero-body {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .hero.is-large .hero-body {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (width <= 767px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (width <= 767px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (width >= 768px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (width >= 990px) {
  .section.is-medium {
    padding: 9rem 1.5rem;
  }

  .section.is-large {
    padding: 18rem 1.5rem;
  }
}

.footer {
  color: #003f2d;
  background-color: #c0d4cb;
  height: 518px;
  padding: 2.5rem 4rem;
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif;
}

.footer .footer-header {
  color: #434a48;
  height: 25px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
}

.footer .footer-button, .footer .footer-link {
  color: #003f2d;
  padding: .3rem 0;
  transition: color .75s cubic-bezier(.23, 1, .32, 1);
  display: block;
}

.footer .footer-button:hover, .footer .footer-link:hover {
  color: #17e88f;
}

.footer .footer-button:focus, .footer .footer-link:focus {
  outline: none;
}

.footer .footer-cookie-link {
  color: #003f2d !important;
  border: none !important;
  padding: .3rem 0 !important;
  font-size: .75rem !important;
  transition: color .75s cubic-bezier(.23, 1, .32, 1) !important;
  display: block !important;
}

.footer .footer-cookie-link:hover {
  color: #17e88f !important;
  background: none !important;
}

.footer .footer-cookie-link:focus {
  outline: none !important;
}

.footer .dropdown {
  position: relative;
}

.footer .dropdown.is-active .underline-input {
  background-color: #c3d4cf;
}

.footer .dropdown.is-active .dropdown-button-wrapper {
  outline: none;
}

.footer .dropdown-content {
  background-color: #fcfcfc;
}

.footer .dropdown-menu {
  margin-left: 0;
  left: 0 !important;
}

.footer .dropdown-item {
  color: #003f2d;
  padding: 0 .3rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif !important;
}

.footer .dropdown-item.hover, .footer .dropdown-item.hover.is-active {
  color: #fcfcfc;
  background-color: #003f2d;
}

.footer .dropdown-item.is-active {
  color: #003f2d;
  background-color: #fcfcfc;
  font-weight: 700;
}

.footer .underline-input {
  border-bottom: 1px solid #adadad;
  padding: .3rem;
}

.footer .underline-input:hover {
  background-color: #c3d4cf;
}

.footer .dropdown-triangle, .footer .dropdown-triangle-up {
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  margin: 0 0 0 .5rem;
}

.footer .dropdown-triangle:after, .footer .dropdown-triangle-up:after {
  all: initial;
}

.footer .dropdown-triangle {
  border-top: 5px solid;
}

.footer .dropdown-triangle-up {
  border-bottom: 5px solid;
}

@media screen and (width <= 767px) {
  .footer {
    height: auto;
    padding: 1.5rem;
  }

  .footer .social-media-icons {
    margin-top: 1rem;
  }

  .footer .terms-and-privacy-footer-section {
    margin-top: -40px;
  }

  .footer .dropdown-item {
    min-height: 0;
    line-height: 1.75rem;
  }

  .footer .dropdown-menu {
    max-height: none;
    top: auto;
    bottom: calc(100% + 4px);
    width: 90px !important;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .footer {
    height: auto;
  }
}

.footer .dropdown-menu, .footer .dropdown-content {
  max-height: 150px !important;
}

.quiz {
  background-color: #003f2d;
  height: 100%;
  overflow: hidden;
}

.quiz-exit-slide {
  height: 100%;
}

.quiz-exit-slide .loader {
  width: 2.5rem;
  height: 2.5rem;
  margin: auto;
}

.quiz-content {
  min-height: 800px;
  padding-bottom: 2.5rem;
}

@media screen and (width <= 1181px) {
  .quiz-content {
    min-height: 900px;
  }
}

.quiz-content > .column {
  flex: none;
  width: 75%;
}

@media screen and (width >= 1182px) {
  .quiz-content > .column {
    margin-top: -6rem;
  }
}

.quiz-question-slide {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.quiz-progress-label {
  color: #fcfcfc;
  letter-spacing: .2px;
}

.quiz-progress-label .progress {
  width: 30%;
  height: .3rem;
}

.quiz-question .tag {
  margin-right: .5rem;
}

.question-text {
  color: #fcfcfc;
}

.question-text .underline-input .input {
  font-size: 1.88rem;
}

.question-text .underline-input input[type="number"] {
  max-width: 4rem;
  font-family: Financier Regular, sans-serif;
}

.question-text .dropdown-item {
  height: auto;
  font-size: 1.25rem;
}

.question-answer {
  letter-spacing: .4px;
}

.question-response {
  align-items: center;
  display: flex;
}

.question-response.hide {
  position: absolute;
  top: 0;
}

.question-response.fade-in img.is-invisible-to-be-faded-in {
  display: none;
}

.question-response-title {
  width: 25%;
}

.sentence-complete-answer {
  color: #fcfcfc;
  flex-wrap: wrap;
  align-items: start;
  margin: .5rem 0;
  line-height: 2;
  transition: all .75s cubic-bezier(.23, 1, .32, 1);
  display: flex;
}

.sentence-complete-answer.is-inactive {
  color: #fcfcfc33;
}

.sentence-complete-answer.is-unselected {
  color: #fcfcfc80;
}

.sentence-complete-answer .sentence-complete-words {
  height: 2.33rem;
  padding-right: .5rem;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .sentence-complete-answer .headcount-question {
    padding-right: 0;
  }
}

.sentence-complete-answer .underline-input {
  margin: 0 .5rem;
}

@media screen and (width <= 1181px) {
  .sentence-complete-answer .underline-input {
    margin-left: 0;
  }
}

.sentence-complete-answer .dropdown-menu {
  max-width: 600px;
}

@media screen and (width <= 1181px) {
  .sentence-complete-answer .dropdown-menu {
    left: 0;
  }
}

.sentence-complete-answer.title .input {
  font-family: Financier Regular, sans-serif;
}

.inactive-quiz-question-container {
  background-color: #0000;
  border: none;
}

.inactive-quiz-question-container .quiz-question {
  width: auto;
}

.inactive-quiz-question {
  padding: 0;
}

.inactive-quiz-question .sentence-complete-answer, .inactive-quiz-question .sentence-complete-answer .input {
  color: #fcfcfc33;
  font-family: Financier Regular, sans-serif;
  font-size: 1rem !important;
}

.inactive-quiz-question .sentence-complete-answer .question-text, .inactive-quiz-question .sentence-complete-answer .input .question-text {
  cursor: pointer;
}

.inactive-quiz-question .sentence-complete-answer .dropdown-menu, .inactive-quiz-question .sentence-complete-answer .input .dropdown-menu {
  display: none;
}

.inactive-quiz-question .sentence-complete-answer .underline-input > input[type="text"], .inactive-quiz-question .sentence-complete-answer .input .underline-input > input[type="text"] {
  width: 10rem;
}

.question-response-title {
  color: #17e88f;
}

.quiz-navigation-btns-container {
  position: relative;
}

.quiz-navigation-btn {
  height: 2rem;
  margin: .75rem .2rem;
  transition: all .75s cubic-bezier(.23, 1, .32, 1);
  position: absolute;
  bottom: 0;
}

.quiz-navigation-btn:first-of-type, .quiz-navigation-btn.is-pulled-left {
  left: 0;
}

.quiz-navigation-btn:focus, .quiz-navigation-btn:active {
  outline: none;
}

.quiz-navigation-btn:focus.is-tertiary-filled, .quiz-navigation-btn:active.is-tertiary-filled {
  background-color: #96b3b6;
  border: none;
}

.quiz-navigation-btn:focus.is-tertiary-filled:hover, .quiz-navigation-btn:active.is-tertiary-filled:hover {
  background-color: #778f9c;
}

.quiz-navigation-btn:focus.is-tertiary-outlined, .quiz-navigation-btn:active.is-tertiary-outlined {
  border-color: #96b3b6;
}

.quiz-sticky-footer {
  height: 68px;
  padding: 0 12px;
  box-shadow: 0 -1px 5px #1a1a1a;
}

@media screen and (width >= 1182px) {
  .quiz-sticky-footer {
    display: none;
  }
}

.quiz-sticky-footer .quiz-navigation-btn {
  width: 6rem;
  height: 44px;
  position: absolute;
}

.quiz-sticky-footer .quiz-navigation-btn:first-of-type, .quiz-sticky-footer .quiz-navigation-btn.is-pulled-left {
  left: 12px;
}

.radio-spectrum-container .radio-spectrum {
  width: 20%;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .tags {
    position: initial;
    padding-bottom: 3rem;
  }
}

@media screen and (width >= 768px) {
  .quiz-question-slide {
    min-height: 34rem;
  }

  .quiz-progress-label {
    height: 2.5rem;
    font-size: 1rem;
  }

  .quiz-question {
    width: 100%;
  }

  .quiz-question .rank-question-tooltip {
    padding-bottom: .5rem;
  }

  .quiz-question .rank-question-tooltip:before, .quiz-question .rank-question-tooltip:after {
    left: 40% !important;
  }

  .quiz-question.sentence-completion-question .question-response {
    margin-top: 3rem;
  }

  .question-text {
    align-items: center;
    font-size: 1.88rem;
    display: flex;
  }

  .question-text:not(.sentence-complete-answer) {
    height: 5rem;
  }

  .sentence-complete-answer {
    min-height: 2.33rem;
  }

  .inactive-quiz-question-container:hover, .inactive-quiz-question-container:focus, .inactive-quiz-question-container:active {
    outline: none;
  }

  .inactive-quiz-question-container:hover .sentence-complete-answer, .inactive-quiz-question-container:hover .sentence-complete-answer .input, .inactive-quiz-question-container:focus .sentence-complete-answer, .inactive-quiz-question-container:focus .sentence-complete-answer .input, .inactive-quiz-question-container:active .sentence-complete-answer, .inactive-quiz-question-container:active .sentence-complete-answer .input {
    color: #fcfcfc !important;
  }

  .inactive-quiz-question-container:hover .sentence-complete-answer .underline-input, .inactive-quiz-question-container:hover .sentence-complete-answer .input .underline-input, .inactive-quiz-question-container:focus .sentence-complete-answer .underline-input, .inactive-quiz-question-container:focus .sentence-complete-answer .input .underline-input, .inactive-quiz-question-container:active .sentence-complete-answer .underline-input, .inactive-quiz-question-container:active .sentence-complete-answer .input .underline-input {
    border-bottom: 2px solid #fcfcfc;
  }

  .inactive-quiz-question-container:hover .sentence-complete-answer .underline-input .dropdown-triangle, .inactive-quiz-question-container:hover .sentence-complete-answer .input .underline-input .dropdown-triangle, .inactive-quiz-question-container:focus .sentence-complete-answer .underline-input .dropdown-triangle, .inactive-quiz-question-container:focus .sentence-complete-answer .input .underline-input .dropdown-triangle, .inactive-quiz-question-container:active .sentence-complete-answer .underline-input .dropdown-triangle, .inactive-quiz-question-container:active .sentence-complete-answer .input .underline-input .dropdown-triangle {
    background: none;
    border-top: 7px solid #fcfcfc;
    border-left: 7px solid #0000;
    border-right: 7px solid #0000;
    width: 0;
    height: 0;
  }

  .inactive-quiz-question-container:hover .sentence-complete-answer .underline-input .dropdown-triangle-up, .inactive-quiz-question-container:hover .sentence-complete-answer .input .underline-input .dropdown-triangle-up, .inactive-quiz-question-container:focus .sentence-complete-answer .underline-input .dropdown-triangle-up, .inactive-quiz-question-container:focus .sentence-complete-answer .input .underline-input .dropdown-triangle-up, .inactive-quiz-question-container:active .sentence-complete-answer .underline-input .dropdown-triangle-up, .inactive-quiz-question-container:active .sentence-complete-answer .input .underline-input .dropdown-triangle-up {
    background: none;
    border-bottom: 7px solid #fcfcfc;
    border-left: 7px solid #0000;
    border-right: 7px solid #0000;
    width: 0;
    height: 0;
  }

  .inactive-quiz-question .question-answer {
    margin-bottom: 1rem;
  }

  .radio-spectrum-container {
    margin-top: 10%;
  }

  .question-response {
    height: 10rem;
    margin-bottom: 1rem;
  }

  .question-response img {
    width: auto;
    height: 10rem;
  }

  .question-response-title {
    max-width: 25%;
    padding-bottom: .5rem;
  }

  .quiz-navigation-btn {
    min-width: 130px;
    margin-bottom: -2rem;
  }

  .quiz-navigation-btn:last-of-type:not(.is-pulled-left) {
    left: calc(130px + 1rem);
  }
}

@media screen and (width <= 767px) {
  .quiz-content {
    height: auto;
    padding-top: 0;
    padding-bottom: 3.5rem;
  }

  .quiz-content > .column {
    flex: none;
    width: 100%;
  }

  .quiz-progress-label {
    font-size: .75rem;
    margin-bottom: 0 !important;
  }

  .quiz-progress-label .percentage {
    padding-top: .4rem;
    padding-left: .2rem;
  }

  .quiz-exit-slide {
    padding: 1.5rem;
  }

  .quiz-question-slide {
    min-height: calc(100vh - 3.5rem);
    padding: 0 1.5rem 1.5rem;
  }

  .question-text {
    height: auto;
  }

  .quiz-question .tags {
    position: initial;
    padding-top: 1rem;
  }

  .quiz-question .tag {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .quiz-question .tag.is-disabled {
    display: none;
  }

  .question-text {
    font-size: 1.25rem;
  }

  .question-text.sentence-complete-answer {
    line-height: 2;
  }

  .question-text .underline-input .input {
    font-size: 1.25rem;
  }

  .question-text .underline-input input[type="number"] {
    padding-bottom: .5rem;
  }

  .radio-spectrum-container {
    margin-top: -1rem;
  }

  .radio-spectrum-container .radio-spectrum {
    width: 100%;
  }

  .sentence-complete-answer {
    height: auto;
    margin: 0 0 .25rem;
    position: relative;
  }

  .sentence-complete-answer .underline-input {
    margin: 0 .5rem 0 0 !important;
  }

  .sentence-complete-answer .input {
    line-height: 1;
  }

  .question-response {
    height: 10rem;
    margin-top: 3rem;
  }

  .question-response-title {
    width: 50%;
  }

  .quiz-navigation-btns-container {
    min-height: 2rem;
    margin-bottom: 1rem;
  }

  .quiz-navigation-btn {
    bottom: unset;
    min-width: 100px;
    top: 0;
  }

  .quiz-navigation-btn:last-of-type:not(.is-pulled-left) {
    left: 150px;
  }

  .inactive-quiz-question-container {
    padding-bottom: .5rem;
  }

  .inactive-quiz-question-container .question-answer {
    margin-bottom: 0;
  }
}

.email-wall-section {
  background-color: #f5f7f7;
}

.email-wall-section .email-wall-section-title {
  letter-spacing: .5px;
  color: #003f2d;
  padding-bottom: .5rem;
  font-family: Calibre Regular;
  font-size: 2.5rem;
  font-stretch: normal;
  display: block;
}

.email-wall-section .email-wall-text {
  letter-spacing: .4px;
  color: #4a4a4a;
  font-family: Calibre Regular;
  font-weight: 600;
}

.email-wall-section .email-wall-section-subtitle {
  width: 80%;
}

@media screen and (width <= 767px) {
  .email-wall-section .email-wall-section-subtitle {
    width: 100%;
  }
}

.email-wall-section .email-wall-label {
  color: #b5b5b5;
  margin-top: 1.5rem;
  font-size: 1rem;
}

.email-wall-section .email-wall-section-input {
  text-overflow: ellipsis;
  box-shadow: none;
  background-color: #0000;
  border-style: none none solid;
  border-bottom-width: 2px;
  border-bottom-color: #b5b5b5;
  border-radius: 0;
  outline: none;
  width: 80%;
  font-size: 1.5rem;
  overflow: hidden;
}

.email-wall-section .email-wall-section-input:read-only {
  color: #b5b5b5;
}

@media screen and (width <= 767px) {
  .email-wall-section .email-wall-section-input {
    width: 15rem;
  }
}

.email-wall-section input:-webkit-autofill {
  -webkit-text-fill-color: #4a4a4a;
  border-bottom: 2px solid #b5b5b5;
  box-shadow: inset 0 0 0 1000px #f3f3f3;
}

.email-wall-section input:-webkit-autofill:hover {
  -webkit-text-fill-color: #4a4a4a;
  border-bottom: 2px solid #b5b5b5;
  box-shadow: inset 0 0 0 1000px #f3f3f3;
}

.email-wall-section input:-webkit-autofill:focus {
  -webkit-text-fill-color: #4a4a4a;
  border-bottom: 2px solid #b5b5b5;
  box-shadow: inset 0 0 0 1000px #f3f3f3;
}

.email-wall-section .email-wall-name-input-container {
  display: flex;
}

@media screen and (width <= 767px) {
  .email-wall-section .email-wall-name-input-container {
    flex-direction: column;
  }
}

.email-wall-section .email-wall-envelope-icon {
  background-image: url("email-envelope-green-icon-spacer-by-cbre.71919c21.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 1.5rem 2.5rem;
  border-bottom: 2px solid #b5b5b5;
  width: 1.5rem;
  height: 1.9rem;
  padding: 1.25rem;
}

.email-wall-section .email-wall-name {
  width: 42%;
}

.email-wall-section .email-wall-name:first-of-type {
  margin-right: 1rem;
}

.email-wall-section .email-wall-name .email-wall-section-input {
  width: 100%;
}

.email-wall-section .email-wall-checkbox-container {
  flex-direction: row;
  width: 100%;
  margin-top: .5rem;
  margin-left: -.5rem;
  display: flex;
}

.email-wall-section .email-wall-checkbox-label {
  width: 90%;
  font-size: .75rem;
}

@media screen and (width <= 767px) {
  .email-wall-section .email-wall-checkbox-label {
    width: 100%;
  }
}

.email-wall-section .email-wall-checkbox-label span a {
  text-decoration: underline !important;
}

.email-wall-section .email-wall-contact-notice {
  width: 90%;
  margin: 1rem 0 0 1.5rem;
  font-size: .75rem;
}

@media screen and (width <= 767px) {
  .email-wall-section .email-wall-contact-notice {
    width: 100%;
  }
}

.email-wall-section .email-wall-checkbox {
  cursor: pointer;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  background-color: #fcfcfc;
  border: 1px solid #d6e3df;
  border-radius: 4px;
  outline: none;
  width: 1.125rem;
  height: 1.125rem;
  margin: .5rem .5rem 0;
}

.email-wall-section .email-wall-checkbox::-ms-check {
  display: none;
}

.email-wall-section .email-wall-checkbox:checked {
  background: url("checkmark-forest-green-icon-spacer-by-cbre.316bea21.svg") center no-repeat;
}

@media screen and (width <= 767px) {
  .email-wall-section .email-wall-checkbox {
    margin-top: 1rem;
  }
}

.email-wall-section .email-wall-submit-button {
  color: #fcfcfc;
  background-color: #003f2d;
  border: none;
  min-width: 11.81rem;
  height: 3.125rem;
  margin-top: 2rem;
}

.email-wall-section .email-wall-submit-button:hover {
  background-color: #144e3e;
}

@media screen and (width <= 767px) {
  .email-wall-name {
    width: 100% !important;
  }
}

.space-summary .space-section {
  background-color: #f3f3f3;
  width: 75%;
  position: relative;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .space-summary .space-section {
    width: 100%;
  }
}

.space-summary .space-section .sf-label-container {
  align-items: center;
  margin-bottom: 1.5rem;
  display: flex;
}

.space-summary .space-section .sf-label-container .w-90 {
  width: 90%;
}

.space-summary .space-section .sf-label-container .title {
  margin-bottom: 0;
}

.space-summary .space-section .sf-label-container .tooltip {
  padding-bottom: 0;
}

.space-summary .space-section .rsf-bar {
  height: 40px;
}

.space-summary .space-section .rsf-bar .bar {
  background-color: #bebde7;
  width: 100%;
  height: 10px;
}

.space-summary .space-section .rsf-bar .bar .bar-progress {
  border: 11px solid #0000;
  border-top: 14px solid #073a41;
  border-bottom-width: 0;
  width: 0;
  height: 0;
}

.space-summary .space-section .rsf-bar .bar .min-max-labels {
  position: relative;
  top: -30px;
}

.space-summary .space-section .radio-button-name {
  font-size: .75rem;
  text-decoration: underline;
}

.space-summary .space-section .radio-control-container {
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
}

.space-summary .space-section .radio-control .icon {
  height: .8rem;
  margin-top: .4rem;
}

.space-summary .space-section .tooltip {
  padding-bottom: 7px;
}

.space-summary .loss-factor-section {
  cursor: pointer;
  background-color: #eaf1ef;
  width: 75%;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .space-summary .loss-factor-section {
    width: 100%;
  }
}

.space-summary .loss-factor-section .loss-factor-button {
  position: relative;
}

.space-summary .loss-factor-section .loss-factor-button .loss-factor-text {
  margin-top: 6px;
}

.space-summary .loss-factor-section .loss-factor-button .arrow-image {
  background-image: url("down-arrow-teal-icon-spacer-by-cbre.c8841fce.svg");
  width: 16px;
  height: 10px;
  margin: 16px 10px 16px 26px;
}

.space-summary .loss-factor-section .loss-factor-button .arrow-image.is-up {
  transform: rotate(180deg);
}

.space-summary .loss-factor-section .loss-factor-helper-description {
  padding: 20px;
}

@media screen and (width <= 767px) {
  .space-summary .space-section, .space-summary .loss-factor-section {
    width: 100%;
  }

  .mobile-space-summary-section {
    padding: 0;
  }
}

.program-stats .program-stat-content {
  background-color: #fafafa;
  border-radius: 4px;
  height: 100%;
  padding: 1.5rem;
  box-shadow: 0 .125rem .25rem #6666664d;
}

.program-stats .program-stat-title {
  color: #434a48;
  font-size: 1.25rem;
}

.program-stats .title, .program-stats .subtitle {
  word-break: initial;
}

.program-stats .program-stat-value {
  margin-bottom: 2.3rem;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .program-stats {
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 4rem;
    display: flex;
  }

  .program-stats .program-stat-content {
    width: 13rem;
    min-height: 177.5px;
  }

  .program-stats .program-stat-subtitle {
    white-space: pre-wrap;
  }

  .program-stats .subtitle {
    font-size: .75rem;
  }

  .program-stats .program-stat-title {
    font-size: 1rem;
  }

  .program-stats .program-stat-value {
    font-size: 1.5rem;
  }
}

@media screen and (width <= 767px) {
  .program-stats {
    margin-left: 5%;
  }

  .program-stats .program-stat-content {
    width: 90%;
    min-height: 224.5px;
  }
}

.example-plans-preview {
  background-color: #e6eaea;
  padding: 2.5rem 0;
}

.example-plan-column {
  text-align: center;
}

.example-plan-column .tooltip {
  margin-top: 1rem;
  padding-right: 0;
}

.example-plan-image {
  width: 60%;
}

.example-plan-label {
  color: #434a48;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.example-plan-button {
  height: 3rem;
}

.space-unit-breakdown-container {
  margin-bottom: 2.5rem;
}

@media screen and (width <= 1373px) {
  .space-unit-breakdown-container {
    width: 100% !important;
  }
}

.sidebar-description {
  width: 80%;
  font-size: .8rem;
}

.space-unit-breakdown-totals {
  align-items: flex-end;
  margin-top: 1.5rem;
}

.space-unit-breakdown-totals .grand-total {
  font-size: 1.3rem;
}

.open-amenity-adder {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: -10%;
  background-color: #0000;
  border: 2px solid #7d7ccf;
  border-radius: 50%;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-right: .25rem;
  font-size: 1rem;
  display: inline-block;
  position: relative;
}

.open-amenity-adder:before, .open-amenity-adder:after {
  content: "";
  transform-origin: center;
  background-color: #7d7ccf;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(0);
}

.open-amenity-adder:before {
  width: 50%;
  height: 1px;
}

.open-amenity-adder:after {
  width: 1px;
  height: 50%;
}

.open-amenity-adder:hover, .open-amenity-adder:focus, .open-amenity-adder:active {
  background-color: #7d7ccf33;
}

.open-amenity-adder.is-small {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.open-amenity-adder.is-medium {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.open-amenity-adder.is-large {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.space-unit-row .delete-unit-row {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: -10%;
  background-color: #0000;
  border: none;
  border-radius: 50%;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin: 0 2px;
  font-size: 1rem;
  display: inline-block;
  position: relative;
}

.space-unit-row .delete-unit-row:before, .space-unit-row .delete-unit-row:after {
  content: "";
  transform-origin: center;
  background-color: #1a1a1a;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.space-unit-row .delete-unit-row:before {
  width: 50%;
  height: 1px;
}

.space-unit-row .delete-unit-row:after {
  width: 1px;
  height: 50%;
}

.space-unit-row .delete-unit-row:hover, .space-unit-row .delete-unit-row:focus, .space-unit-row .delete-unit-row:active {
  background-color: #1a1a1a33;
}

.space-unit-row .delete-unit-row.is-small {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.space-unit-row .delete-unit-row.is-medium {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.space-unit-row .delete-unit-row.is-large {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.amenity-unit-adder .space-category-breakdown-row {
  border: 1px solid #0d8070;
}

.amenity-unit-adder .space-category-breakdown-row .grand-total, .amenity-unit-adder .space-category-breakdown-row .space-category-quantity .underline-input input, .amenity-unit-adder .space-category-breakdown-row .space-category-area .underline-input input {
  color: #0d8070 !important;
}

.amenity-unit-adder .space-category-breakdown-row .underline-input {
  border-bottom-color: #0d8070 !important;
}

.amenity-unit-adder .space-category-breakdown-row .underline-input .dropdown-triangle {
  border-top-color: #0d8070;
}

.amenity-unit-adder .space-category-breakdown-row .underline-input .dropdown-triangle-up {
  border-bottom-color: #0d8070;
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: -10%;
  background-color: #0000;
  border: none;
  border-radius: 50%;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin: 0 2px;
  font-size: 1rem;
  display: inline-block;
  position: relative;
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row:before, .amenity-unit-adder .space-category-breakdown-row .delete-unit-row:after {
  content: "";
  transform-origin: center;
  background-color: #003f2d;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row:before {
  width: 50%;
  height: 1px;
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row:after {
  width: 1px;
  height: 50%;
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row:hover, .amenity-unit-adder .space-category-breakdown-row .delete-unit-row:focus, .amenity-unit-adder .space-category-breakdown-row .delete-unit-row:active {
  background-color: #003f2d33;
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row.is-small {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row.is-medium {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.amenity-unit-adder .space-category-breakdown-row .delete-unit-row.is-large {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.space-category-breakdown-row {
  color: #1a1a1a;
  align-items: center;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.space-category-breakdown-row .add-icon {
  background-color: #0d8070;
  background-image: url("checkmark-white-icon-spacer-by-cbre.033b3a0a.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  padding: .6em;
}

.space-category-breakdown-row .add-icon.is-inactive {
  pointer-events: none;
  background-color: #0d80704d;
}

.space-category-breakdown-row .add-icon:hover:not(.is-inactive) {
  background-color: #12b8a2;
}

.space-category-breakdown-row .space-category-breakdown-input-container {
  color: #1a1a1a;
  justify-content: space-around;
  align-items: center;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  display: flex;
}

.space-category-breakdown-row .space-category-breakdown-input-container .underline-input {
  width: 3.5rem;
}

.space-category-breakdown-row .space-category-units-container {
  align-items: center;
  display: flex;
}

.space-category-breakdown-row .space-category-units-container .space-category-unit {
  text-overflow: ellipsis;
  width: 71px;
  margin-left: .5rem;
  overflow: hidden;
}

.space-category-breakdown-row .space-category-units-container .space-category-unit .underline-input {
  width: 100%;
}

.space-category-breakdown-row .underline-input {
  position: relative;
}

.space-category-breakdown-row .underline-input .number-arrow-icons {
  position: absolute;
  bottom: .25rem;
  right: .25rem;
}

.accent-text-me .grand-total, .accent-text-me .space-category-quantity .underline-input input, .accent-text-me .space-category-area .underline-input input, .accent-text-me .space-type-color-input input, .accent-text-me .space-type-color-text {
  color: #00d2a0 !important;
}

.accent-text-me .underline-input {
  border-bottom-color: #00d2a0 !important;
}

.accent-text-me .underline-input .dropdown-triangle {
  border-top-color: #00d2a0;
}

.accent-text-me .underline-input .dropdown-triangle-up {
  border-bottom-color: #00d2a0;
}

.accent-text-we .grand-total, .accent-text-we .space-category-quantity .underline-input input, .accent-text-we .space-category-area .underline-input input, .accent-text-we .space-type-color-input input, .accent-text-we .space-type-color-text {
  color: #00b2dd !important;
}

.accent-text-we .underline-input {
  border-bottom-color: #00b2dd !important;
}

.accent-text-we .underline-input .dropdown-triangle {
  border-top-color: #00b2dd;
}

.accent-text-we .underline-input .dropdown-triangle-up {
  border-bottom-color: #00b2dd;
}

.accent-text-amenity .grand-total, .accent-text-amenity .space-category-quantity .underline-input input, .accent-text-amenity .space-category-area .underline-input input, .accent-text-amenity .space-type-color-input input, .accent-text-amenity .space-type-color-text {
  color: #7d7ccf !important;
}

.accent-text-amenity .underline-input {
  border-bottom-color: #7d7ccf !important;
}

.accent-text-amenity .underline-input .dropdown-triangle {
  border-top-color: #7d7ccf;
}

.accent-text-amenity .underline-input .dropdown-triangle-up {
  border-bottom-color: #7d7ccf;
}

.space-unit-breakdown-totals .space-category-breakdown-row-name, .space-category-breakdown-row .space-category-breakdown-row-name {
  word-break: break-word;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.space-unit-breakdown-totals .space-category-breakdown-row-name .name, .space-category-breakdown-row .space-category-breakdown-row-name .name {
  width: 90%;
}

.space-unit-breakdown-totals .space-category-breakdown-row-name input, .space-category-breakdown-row .space-category-breakdown-row-name input {
  color: #1a1a1a;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.space-unit-breakdown-totals .space-category-breakdown-row-name input::placeholder, .space-category-breakdown-row .space-category-breakdown-row-name input::placeholder {
  color: #0d807066;
}

.space-unit-breakdown-totals .space-category-breakdown-row-name .dropdown-content, .space-category-breakdown-row .space-category-breakdown-row-name .dropdown-content {
  max-height: 333px;
}

.space-unit-breakdown-totals .program-delta-label, .space-category-breakdown-row .program-delta-label {
  font-size: .7rem;
  position: absolute;
}

@media screen and (width <= 767px) {
  .space-unit-breakdown-container {
    margin-top: 0;
  }

  .space-category-breakdown-row .column {
    padding-bottom: 0;
  }

  .space-category-breakdown-row .grand-total {
    display: inline;
  }

  .space-category-breakdown-row .program-delta-label {
    padding-left: .5rem;
    display: block;
    position: relative;
  }

  .total-unit {
    margin-left: .5rem;
  }

  .space-category-mobile-summary {
    background-color: #dbdbdb1a;
  }

  .space-category-button {
    background-color: #0000;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    border: none;
    outline: none;
    margin-left: auto;
    margin-right: 1rem;
    padding-left: 1rem;
  }

  .space-category-button-me {
    background-image: url("down-arrow-green-icon-spacer-by-cbre.99c30636.svg");
  }

  .space-category-button-we {
    background-image: url("down-arrow-blue-icon-spacer-by-cbre.9c80e712.svg");
  }

  .space-category-button-amenity {
    background-image: url("down-arrow-purple-icon-spacer-by-cbre.28bc0ec8.svg");
  }

  .small-title {
    font-size: .8rem;
  }

  .space-category-section-content {
    font-size: .75rem;
  }

  .space-category-x {
    padding-bottom: .1rem !important;
  }

  .custom-x-style {
    width: 12%;
  }
}

.your-details .your-details-border {
  border: 1px solid #dbdbdb;
  width: 100%;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .your-details-border {
    width: 111%;
    margin-left: -2rem;
  }
}

.your-details .assumption-separator {
  background-color: #b5b5b5;
  width: 2px;
  height: 90%;
}

.your-details .days-per-week-assumption-separator {
  margin-right: 1.25rem;
}

.your-details .save-changes-button {
  border-radius: 5px;
  outline: none;
  min-width: 130px;
  margin-top: 2rem;
}

.your-details .assumptions-controls {
  background-color: #fafafa;
  padding: 0 .5rem;
  position: relative;
  top: .8rem;
}

.your-details .assumptions-controls .dropdown-menu {
  width: 175px;
  left: auto;
  right: 0;
}

.your-details .assumptions-controls .radio-control-container {
  width: 100%;
  position: absolute;
  top: .1rem;
  left: .75rem;
}

.your-details .assumptions-controls .circulation-dropdown .circulation-dropdown-input .dropdown .underline-input .input {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;
  display: block;
  overflow: hidden;
}

.your-details .assumptions-controls .circulation-dropdown .dropdown-menu {
  width: 375px;
  left: auto;
  right: 0;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .assumptions-controls-container {
    flex-wrap: wrap;
    display: flex;
  }
}

.your-details .assumptions-controls-container .assumption-container .headcount-container {
  margin-left: 1.2rem;
}

.your-details .assumption-container {
  align-items: center;
  display: flex;
}

.your-details .assumption-title, .your-details .assumption-unit {
  margin-bottom: .5rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: .8rem;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .assumption-title, .your-details .assumption-unit {
    max-width: 10%;
  }
}

.your-details .assumption-unit {
  margin-left: .4rem;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .recommended-office-size-section {
    margin-right: 100rem;
  }

  .your-details .recommended-office-size-content {
    width: 110%;
  }
}

.your-details .headcount-following-text {
  margin-top: .5rem;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .days-per-week-assumption-text {
    max-width: 10rem;
  }
}

.your-details .per-week-text {
  width: 51px;
}

.your-details .profile-type-name {
  max-width: 10rem;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  display: block;
}

.your-details .is-mobile-input, .your-details .is-mobile-input-flex {
  display: none;
}

.your-details .your-details-workspace {
  padding: 2.5rem;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .your-details-workspace {
    padding: 1rem;
  }
}

.your-details .program-reset-button {
  cursor: pointer;
  background-image: url("revert-forest-green-icon-spacer-by-cbre.918fcdff.svg");
  background-position: 1rem;
  background-repeat: no-repeat;
  border-radius: 5px;
  outline: none;
  margin-top: 2rem;
  margin-right: 1rem;
  padding-left: 2rem;
}

.your-details .program-reset-button:hover {
  color: #2afbca;
  background-image: url("revert-bright-teal-icon-spacer-by-cbre.e84ec560.svg");
}

.your-details .space-category-dropdown-input {
  cursor: pointer;
  background-image: url("down-arrow-teal-icon-spacer-by-cbre.c8841fce.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  border-radius: 8px;
  width: 13rem;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .space-category-dropdown-input {
    width: 20rem;
  }
}

.your-details .space-dropdown-width {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 27px;
  display: block;
  overflow: hidden;
}

.your-details .space-category-dropdown-content {
  background-color: #fcfcfc;
  border-radius: 8px;
  width: 13rem;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .space-category-dropdown-content {
    width: 20rem;
  }
}

.your-details .space-category-dropdown-item {
  color: #1a1a1a;
  width: 13rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif !important;
}

.your-details .space-category-dropdown-item.hover {
  color: #fcfcfc;
  background-color: #778f9c;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .your-details .space-category-dropdown-item {
    width: 25rem;
  }
}

.your-details .is-space-category-dropdown {
  outline: none;
}

.mobile-assumption-separator {
  display: none;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .mobile-assumption {
    margin-right: 1rem;
  }
}

@media screen and (width <= 767px) {
  .your-details .your-details-workspace {
    padding: 0;
    bottom: 1rem;
  }

  .mobile-assumption-container {
    width: 100%;
    position: relative;
  }

  .mobile-assumption-title {
    padding-top: .8rem;
  }

  .assumptions-controls .radio-control {
    width: 30%;
    display: inline-block;
  }

  .program-bar-chart-segment-percentage {
    font-size: 1.5rem;
  }

  .program-bar-chart-segment-amount {
    display: none;
  }

  .profile-type-name {
    max-width: none !important;
  }

  .is-mobile-input {
    display: inherit !important;
  }

  .is-mobile-input-flex {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex !important;
  }

  .mobile-assumption-separator {
    background-color: #b5b5b5;
    width: 90%;
    height: 1px;
    margin-left: .9rem;
  }

  .mobile-assumption {
    margin-left: auto;
  }

  .mobile-buttons {
    text-align: right;
    margin-top: 2rem;
    margin-right: 1rem;
  }

  .is-primary-filled.success {
    background-color: #003f2d80;
  }

  .profile-and-headcount-assumption-style {
    margin-left: 8rem;
  }

  .days-in-office-assumption-style {
    margin-left: 5rem;
  }

  .collaboration-assumption-style {
    margin-left: 5.5rem;
  }

  .loss-factor-container {
    position: relative;
  }
}

.results .hero-section .container {
  padding: 0 4rem;
}

.results .section .container {
  padding: 0 3rem;
}

.results .light-green-content-section {
  background-color: #eaf1ef80;
}

.results .light-gray-content-section {
  background-color: #e6eaea;
}

.results .no-margin-bottom {
  margin-bottom: 0;
}

.results .results-breakdown-padding {
  padding-left: 6rem;
}

@media screen and (width <= 1373px) {
  .results .results-breakdown-padding {
    padding-left: 0;
  }
}

.results .underline-input .input {
  height: 1.5rem;
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif;
  font-size: .8rem;
}

.results .underline-input.is-disabled {
  border: none !important;
}

.results .underline-input.is-disabled .input {
  color: #434a48 !important;
}

.results .content-section .section-title {
  font-size: 1.75rem;
}

.results .content-section .section-subtitle {
  margin-bottom: 2rem;
  font-size: .9rem;
}

.results .is-highlight-content {
  background-color: #d6e3df66;
}

.results .subtitle p {
  margin-bottom: .5rem;
}

.results .section-title-container {
  margin-left: 3rem;
}

.results .profile-hero-name {
  font-size: 3rem !important;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .results .profile-traits-container {
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: 1rem;
    display: flex;
  }

  .results .profile-trait {
    width: 7rem;
  }
}

.results .profile-trait > img {
  height: 2rem;
}

.results .profile-trait-description {
  font-size: .8rem;
  line-height: 1.45;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .results .profile-trait-description {
    width: 7rem;
  }
}

.results .headCount .input {
  min-width: 2rem;
}

.results .program-save-confirmation-box {
  background-color: #dbdbdb33;
  border: 1px solid #003f2d4d;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  min-width: 30rem;
  height: 2.5rem;
  display: flex;
}

@media screen and (width <= 767px) {
  .results .program-save-confirmation-box {
    width: 100%;
    height: 5rem;
  }
}

.results .program-save-confirmation-box-text {
  font-size: 1rem;
  font-weight: 500;
}

.results .program-save-confirmation-box-text:before {
  content: url("checkmark-white-icon-in-green-circle-spacer-by-cbre.1d8232a3.svg");
  vertical-align: -15%;
  margin-right: .8rem;
}

@media screen and (width <= 767px) {
  .results .program-save-confirmation-box-text {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.results .program-tinker-warning {
  z-index: 1;
  width: 300px;
  font-size: .75rem;
  position: fixed;
  top: 367px;
  left: 63px;
}

@media screen and (width <= 767px) {
  .results .program-tinker-warning {
    margin-left: -150px;
    top: 200px;
    left: 50%;
  }
}

@media screen and (width >= 768px) and (width <= 989px) {
  .results .next-steps-title {
    font-size: 1.5rem;
  }
}

.results .next-steps-size {
  font-size: 41px;
}

.results .find-space-button {
  background-color: #003f2d;
  width: auto;
  height: 3rem;
  margin-top: 2rem;
  font-family: Calibre Regular;
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (width >= 768px) and (width <= 989px) {
  .results .find-space-button {
    width: 80%;
  }
}

.results .next-steps-find-space-button {
  min-width: 50%;
}

.results .navbar-find-space-button {
  width: 35%;
  height: 2.5rem;
  margin-top: 1.25rem;
  padding-left: 3em;
  padding-right: 3em;
}

@media screen and (width <= 1373px) {
  .results .space-category-breakdown-container {
    flex-direction: column;
    display: flex;
  }

  .results .space-category-sidebar-column {
    flex-direction: column;
    width: 100%;
    padding-left: 1.5rem;
    display: flex;
  }
}

@media screen and (width <= 989px) {
  .results .space-category-sidebar-column {
    padding-left: 1.5rem;
    display: block;
  }
}

@media screen and (width <= 767px) {
  .results {
    width: 100%;
    overflow-x: hidden;
  }

  .results .section {
    padding: 1.5rem;
  }

  .results .navbar #spacer-logo {
    width: 66px;
  }

  .results .navbar .copy-to-clipboard-box-container {
    padding: 1.4rem .5rem 0 1rem;
  }

  .results .navbar .send-results-button {
    width: 5rem;
    padding-right: 1.25rem;
    font-size: .66rem;
    margin-right: 0 !important;
  }

  .results .navbar .find-space-button {
    width: 55%;
    margin-top: .85rem;
    font-size: .66rem;
  }

  .results .hero-section .container, .results .section .container {
    padding-left: 0;
    padding-right: 0;
  }

  .results .profile-hero .subtitle {
    font-size: 1rem;
  }

  .results .profile-hero .profile-hero-name {
    font-size: 2.25rem !important;
  }

  .results .profile-hero figure {
    width: 200%;
    height: 200%;
    margin-left: -50%;
    padding-top: 0;
  }

  .results .profile-hero figure img {
    position: relative;
  }

  .results .profile-trait {
    width: 100%;
  }

  .results .section-title-container {
    margin-left: 0;
  }

  .results .find-space-button {
    width: 80%;
    height: 2rem;
    margin-left: 10%;
  }

  .results .next-steps .title {
    font-size: 1.5rem;
  }
}

.results .test-fit {
  padding: 2rem 0;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
}

.results .test-fit .container, .results .test-fit .hero-body {
  background-color: #fff5ea !important;
}

.results .test-fit .test-fit-info {
  float: right;
  margin-right: 2rem;
}

.results .test-fit .title {
  margin-bottom: 2rem;
  font-family: Calibre Semibold, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
}

.results .test-fit .subtitle {
  margin-bottom: 5rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.results .test-fit .button {
  padding: 0 3rem;
}

@media screen and (width <= 767px) {
  .results .test-fit .button {
    margin-left: 10%;
  }
}

.results .test-fit .test-fit-example-pair {
  width: 80%;
  margin-left: 2rem;
}

.results .test-fit form {
  float: left;
  border: 2px solid #eed1b1;
  width: 80%;
  height: 100%;
  padding: 1rem 2rem;
  position: relative;
}

@media screen and (width <= 767px) {
  .results .test-fit form {
    width: 85%;
  }
}

.results .test-fit .form-title {
  margin-bottom: 1rem;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
}

.results .test-fit input[type="text"] {
  border: none;
  border-bottom: 2px solid #1a1a1a;
  width: 30%;
  height: 2rem;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

@media screen and (width <= 767px) {
  .results .test-fit input[type="text"] {
    width: 100%;
  }
}

.results .test-fit input[type="radio"] {
  margin-right: .25rem;
}

.results .test-fit .test-fit-radio-pair label:first-child {
  margin-right: 1rem;
}

.results .test-fit .submit-test-fit-form-button {
  width: 40%;
  position: absolute;
  bottom: 5%;
}

@media screen and (width <= 767px) {
  .results .test-fit .submit-test-fit-form-button {
    position: initial;
    width: 100%;
    margin-left: 0;
  }
}

.results .test-fit .email-success-error-text {
  position: absolute;
  bottom: 0;
}

.results .test-fit span {
  color: #666;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: .75rem;
}

.results .test-fit .test-fit-terms-conditions {
  width: 80%;
  position: absolute;
  bottom: 17%;
  left: 12%;
}

@media screen and (width <= 767px) {
  .results .test-fit .test-fit-terms-conditions {
    position: initial;
    margin: 1rem 0;
  }

  .results .test-fit .checkbox.in-results:before {
    left: 1em;
  }

  .results .test-fit .completed-test-fit-form {
    width: 100%;
  }
}

.results .test-fit .completed-test-fit-form-content span {
  font-size: 1.25rem;
}

.results .test-fit .close-test-fit-form-button {
  cursor: pointer;
  background: url("close-button-test-fit-spacer-by-cbre.0f876523.svg");
  border: none;
  width: 1.65rem;
  height: 1.65rem;
  margin-left: 1rem;
}

.flooredPlanrequestSection {
  background-color: #f5f7f7;
  justify-content: center;
  margin-top: 30px;
  display: flex;
}

.flooredPlanrequestSection .flooredPlan-heading {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
}

.flooredPlanrequestSection .sampleText {
  text-align: center;
  margin-top: 30px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.flooredPlanrequestSection .redefinedText {
  text-align: left;
  color: #272b2f;
  width: 450px;
  height: 38px;
  margin-bottom: 20px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  left: 271px;
}

.flooredPlanrequestSection .subtext {
  text-align: left;
  width: 402px;
  height: 42px;
  margin-bottom: 13px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  left: 271px;
}

.flooredPlanrequestSection .icons-floored {
  margin-bottom: 220px;
}

.flooredPlanrequestSection .paragraph {
  text-align: left;
  width: 402px;
  height: 84px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  left: 271px;
}

.flooredPlanrequestSection .icons-floored .columns .column {
  text-align: center;
}

.flooredPlanrequestSection .icons-floored .columns .column .floored-icon {
  width: 70px;
}

.line-seperator {
  background-color: #dadada;
  width: 100%;
  height: 1px;
  margin-top: 60px;
}

.text-1 {
  text-align: center;
  width: 100%;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.text-2 {
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.Flooredvideo {
  width: 550px;
  height: 200px;
  margin-top: 40px;
  margin-bottom: 41px;
}

.set-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.topDiv-column {
  display: block !important;
}

.profile-comparison {
  min-height: 100vh;
  padding-bottom: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.profile-comparison .title {
  color: #003f2d;
}

.profile-column {
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  display: flex;
}

.profile-column .title {
  color: #fcfcfc;
}

.profile-column.is-active .button, .profile-column.is-active .profile-summary {
  background-color: #144e3e;
}

.profile-column.is-active .profile-traits {
  background-color: #219585;
}

.profile-column:hover:not(.is-active) .button {
  background-color: #144e3e;
  color: #fcfcfc !important;
}

.profile-summary-and-traits {
  box-sizing: border-box;
  border: 4px solid #0000;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.profile-summary {
  background-color: #003f2d;
  flex-direction: column;
  flex-grow: 1;
  padding: .75rem 1.25rem 1.25rem;
  transition: background-color .75s cubic-bezier(.23, 1, .32, 1);
  display: flex;
}

.profile-summary img {
  max-height: 10rem;
}

.profile-description {
  color: #fcfcfc;
  font-size: .75rem;
}

.profile-traits {
  background-color: #166f62;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-self: flex-end;
  padding: 1rem;
  transition: background-color .75s cubic-bezier(.23, 1, .32, 1);
  display: flex;
}

.profile-traits .profile-trait {
  width: 50%;
  padding: .5rem;
  display: inline-block;
}

.profile-traits .profile-trait .title {
  margin-bottom: .5rem !important;
  font-size: 1rem !important;
}

.profile-traits .profile-trait img {
  width: 1.5rem;
  height: 1.5rem;
}

.profile-traits .profile-trait .profile-trait-description {
  color: #fcfcfc;
  font-size: .75rem;
  line-height: 1.25;
}

.hero {
  white-space: pre-wrap;
}

.about-page-content-illustration {
  max-height: 800px;
}

@media screen and (width >= 768px) {
  .generic-info-page-image {
    position: sticky;
    top: 25%;
  }
}

.terms-of-service {
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.terms-of-service .main-list > li {
  margin: 1rem 0 0 1rem;
  padding-left: 1rem;
  font-weight: 700;
}

.terms-of-service .main-list > li ol, .terms-of-service .main-list > li div {
  padding-left: 1.5rem;
  font-weight: normal;
}

.terms-of-service .main-list > li ol li, .terms-of-service .main-list > li div li {
  padding-left: 1rem;
}

.hero-body .title {
  width: 75%;
  margin-bottom: 0;
}

@media screen and (width <= 767px) {
  .hero-body .title {
    width: 100%;
    font-size: 30px;
  }
}

.landing-page .cta-button, .about-page .cta-button, .blog-article .cta-button {
  padding: 1.5rem 2.5rem;
}

.landing-page .hero-question .question-text {
  margin-bottom: 0;
  padding-top: 1.25rem;
  padding-left: 2.5rem;
  font-family: Calibre Regular;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.landing-page .hero-question .radio-spectrum-container {
  margin-top: 0;
}

.landing-page .hero-question .radio-spectrum {
  color: #1a1a1a;
  padding-left: 1.75rem;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.landing-page .hero-question .radio-spectrum:before {
  background-color: #d6e3df;
  border: 2px solid #1a1a1a;
  width: 21px;
  height: 21px;
  top: 8px;
  left: 50%;
}

.landing-page .hero-question .radio-spectrum:after {
  border-top: 2px solid #1a1a1a;
}

.landing-page .hero-question .radio-spectrum:hover {
  font-weight: inherit;
}

@media screen and (width >= 768px) {
  .landing-page .hero-question .radio-spectrum:hover:before {
    background-color: #17e88f;
  }
}

.landing-page .hero-question .radio-spectrum:active:before, .landing-page .hero-question .radio-spectrum.is-active:before {
  background-color: #17e88f;
  border-color: #17e88f;
}

.landing-page .steps-summary {
  margin-right: 0;
}

.landing-page .steps-summary .image {
  width: 158px;
  height: 158px;
}

.landing-page .secondary-hero .column {
  padding: 0;
}

.landing-page .secondary-hero .title {
  font-size: 2.3rem;
}

@media screen and (width <= 767px) {
  .landing-page .hero-body {
    padding-top: 1rem;
  }

  .landing-page .hero-body .subtitle {
    margin-bottom: 0;
  }

  .landing-page .hero-question .radio-spectrum {
    color: #003f2d;
    min-height: 3rem;
    padding-top: 1.5rem;
    padding-left: calc(1em + 34px);
    font-size: 16px;
  }

  .landing-page .hero-question .radio-spectrum:before {
    border: 1px solid #1a1a1a;
    width: 26px;
    height: 26px;
    top: 50%;
    left: 8px;
  }

  .landing-page .hero-question .radio-spectrum:not(:last-child):after {
    border-top: none;
    border-left: 1px solid #1a1a1a;
    width: 100%;
    left: 20px;
  }

  .landing-page .hero-question .question-text {
    padding-left: 0;
    font-size: 20px;
  }

  .landing-page .hero-question .question-answer {
    padding-left: 1rem;
  }

  .landing-page .secondary-hero .title {
    font-size: 30px;
  }

  .landing-page .cta-button {
    padding: 1rem 2rem;
  }
}

.about-page-video {
  width: 100%;
  max-width: 750px;
}

.iframe-container {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.iframe-container iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-thumbnail {
  borderRadius: 4;
  boxShadow: 0px 4px 5px 1px #ccc;
  objectFit: cover;
}

.blog-home .hero-body .title {
  width: 100%;
}

.blog-hero {
  background-image: url("blog-page-header.7d863215.png");
  background-position: center;
  background-size: cover;
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.hero.blog-hero-small {
  padding-top: 0;
  padding-bottom: 0;
}

.hero.blog-hero-small .hero-body {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.blog-article-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.blog-article-preview {
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  overflow: hidden;
}

.blog-article-preview:hover figure, .blog-article-preview:focus figure {
  opacity: .9;
}

.blog-article-preview:hover .blog-link, .blog-article-preview:focus .blog-link {
  color: #17e88f;
}

.blog-article-preview-content {
  color: #1a1a1a99;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  max-height: 30rem;
  margin-top: -1rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  display: -webkit-box;
  overflow: hidden;
}

.blog-link {
  color: #003f2d;
  margin-top: 1rem;
  display: block;
}

.blog-tag-filter-background {
  width: 100%;
  position: relative;
}

.blog-tag-filter {
  background-color: #f5f7f7;
  width: 100%;
  position: absolute;
  bottom: 100%;
}

.blog-tag-filter .blog-tag-filter-options {
  max-width: 60%;
  margin: auto;
}

@media screen and (width <= 767px) {
  .blog-tag-filter .blog-tag-filter-options {
    max-width: 100%;
    margin: 0 .25rem;
  }
}

.blog-tag-filter input:focus + .blog-tag-filter-option {
  border: 1px dashed #fcfcfc;
}

.blog-tag-filter .blog-tag-filter-option {
  color: #1a1a1a;
  border: 1px dashed #0000;
  padding: 10px;
}

.blog-tag-filter .blog-tag-filter-option:hover {
  cursor: pointer;
  text-decoration: underline;
}

.blog-tag-filter .active-tag {
  text-decoration: underline;
}

.blog-content {
  margin: 0 5rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
}

.blog-content p {
  margin-bottom: 1rem;
}

.blog-content h2 {
  margin-bottom: .3rem;
  font-weight: 700;
}

@media screen and (width <= 767px) {
  .blog-content {
    margin: 0;
  }
}

.blog-home-link:hover {
  text-decoration: underline;
}

.referrals-navbar {
  height: 1.5rem;
  margin-top: 2rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
}

@media screen and (width <= 767px) {
  .referrals-navbar {
    margin-top: 1.1rem;
    margin-left: .5rem;
  }

  .referral-sign-up .hero-body {
    padding: 0;
  }
}

.referral-sign-up .title {
  width: auto;
  margin-top: 1rem;
}

.referral-sign-up .referral-link-box {
  background-color: #fcfcfc;
  width: 30rem;
  height: 30rem;
  padding: 1rem;
}

.referral-sign-up .referral-instructions-and-form {
  width: 50%;
  margin: 2rem auto auto;
}

@media screen and (width <= 767px) {
  .referral-sign-up .referral-instructions-and-form {
    width: 80%;
  }
}

.referral-sign-up .get-referral-link {
  width: 75%;
}

@media screen and (width <= 767px) {
  .referral-sign-up .get-referral-link {
    width: 100%;
  }
}

.referral-sign-up .retrieve-link-form {
  margin-top: 3rem;
}

.referral-sign-up .retrieve-link-form div {
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
}

.referral-sign-up .retrieve-link-form li {
  padding-left: .5rem;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
}

@media screen and (width <= 767px) {
  .referral-sign-up .retrieve-link-form li {
    margin-bottom: 1rem;
  }
}

.referral-sign-up .user-inputs {
  width: 70%;
}

@media screen and (width <= 767px) {
  .referral-sign-up .user-inputs {
    width: 90%;
  }
}

.referral-sign-up .form-title {
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.referral-sign-up .edit-referral-info-button {
  margin: initial;
  cursor: pointer;
  background: url("spacer-pro-edit-icon.bd6e9578.svg") no-repeat;
  border: none;
  width: auto;
  height: .9rem;
  margin-top: .4rem;
  margin-left: .5rem;
  padding: 0 .45rem;
}

.referral-sign-up .subtitle {
  font-size: 17px;
}

.referral-sign-up .columns {
  margin-bottom: 0;
}

.referral-sign-up input[type="text"], .referral-sign-up input[type="email"] {
  border: none;
  border-bottom: 1px solid #1a1a1a;
  height: 2rem;
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.referral-sign-up input[type="text"]:focus, .referral-sign-up input[type="email"]:focus {
  border-bottom: 2px solid #1a1a1a;
  outline: none;
}

.referral-sign-up input[type="text"][aria-invalid="true"], .referral-sign-up input[type="email"][aria-invalid="true"] {
  border-bottom: 1px solid #fc5328;
}

.referral-sign-up label, .referral-sign-up span, .referral-sign-up div {
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
}

.referral-sign-up .bold-input {
  font-family: Calibre Medium, Arial, Helvetica, sans-serif;
}

@media screen and (width <= 767px) {
  .referral-sign-up .form-error-text {
    font-size: .9rem;
  }
}

.referral-sign-up input:read-only {
  cursor: default;
  background: none;
  border-radius: 4px;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
  border: 1px solid #003f2d !important;
}

.referral-sign-up button {
  width: 14rem;
  margin: 2rem auto auto;
}

.referral-sign-up .link-never-expires {
  margin-top: .5rem;
  margin-left: 7rem;
}

@media screen and (width <= 767px) {
  .referral-sign-up .link-never-expires {
    margin-left: 2.5rem;
  }
}

.referral-sign-up .copied-link-icon {
  background: url("confirm-icon-no-background-spacer-by-cbre.236a7922.svg");
  width: 1rem;
  height: 1rem;
}

.referral-sign-up .server-error {
  margin-left: 3.5rem;
}

@media screen and (width <= 767px) {
  .referral-sign-up .server-error {
    margin-left: 2.5rem;
  }
}

.referral-sign-up .link-in-referral-pages {
  text-decoration: underline;
  color: #003f2d !important;
}

.referral-sign-up .link-in-referral-pages:hover, .referral-sign-up .link-in-referral-pages:focus {
  outline: none;
  color: #17e88f !important;
}

@media print {
  #spacer-logo {
    filter: invert(0%);
  }

  .results .profile-hero .image, .results .profile-description-section, .results .space-summary, .results .section-title, .results .next-steps, .results .footer, .results .assumptions-controls, .results .your-details-workspace .program-bar-chart, .results .section-subtitle, .results .save-changes-button, .results .program-reset-button, .results .visible-breakdown, .results .bar-chart-to-breakdown-divider, .results .spacer-logo-for-screen, .results .number-arrow-icons, .results .navbar-end, .results .amenity-unit-adder-opener, .results .amenity-unit-adder {
    display: none !important;
  }

  .results .section .container, .results .hero-section .container {
    padding-left: 1rem;
  }

  .results .program-bar-chart, .results .program-hero-for-print .rsf, .results .program-hero-for-print .usf, .results .program-hero-for-print .nsf, .results .program-stats {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .results .navbar {
    position: absolute;
  }

  .results .profile-hero {
    margin-top: -1.5rem;
  }

  .results .profile-hero-subtitle {
    font-size: .8rem;
  }

  .results .profile-hero-name {
    margin-bottom: .5rem;
    font-size: 2rem;
    margin-top: -1.25rem !important;
  }

  .results .results-profile .space-summary-section {
    width: 100%;
  }

  .results .program-hero-for-print {
    padding: 0;
  }

  .results .program-hero-for-print .loss-factor {
    font-size: .8rem;
  }

  .results .program-hero-for-print .rsf, .results .program-hero-for-print .usf, .results .program-hero-for-print .nsf {
    text-align: center;
    background-color: #d6e3df66;
    align-self: center;
    margin-left: .5rem;
  }

  .results .program-hero-for-print .title {
    margin-bottom: 0;
  }

  .results .program-hero-for-print .label {
    font-size: .6rem;
  }

  .results .office-breakdown-barchart {
    background-color: #fff;
    margin-bottom: -4.5rem;
  }

  .results .program-stats, .results .program-stat-content {
    padding: 1rem !important;
  }

  .results .program-stats .program-stat-title, .results .program-stat-content .program-stat-title, .results .program-stats .program-stat-value-percent, .results .program-stat-content .program-stat-value-percent {
    font-size: .8rem;
  }

  .results .program-stats .program-stat-value, .results .program-stat-content .program-stat-value {
    font-size: 1rem;
  }

  .results .program-stats .program-stat-subtitle, .results .program-stat-content .program-stat-subtitle {
    font-size: .5rem;
  }

  .results .print-disclaimer {
    color: #fc5328;
    font-size: .6rem;
    position: absolute;
    bottom: 5px;
  }

  .results .your-details .your-details-border {
    border: none;
  }

  .results .your-details-workspace {
    page-break-before: always;
    border: none !important;
  }

  .results .all-breakdowns-for-print .results-breakdown-padding {
    padding: 0;
  }

  .results .all-breakdowns-for-print .category-title-print {
    margin-top: 0;
  }

  .results .all-breakdowns-for-print .space-unit-breakdown-container {
    margin-top: .5rem;
  }

  .results .all-breakdowns-for-print .space-category-breakdown-container {
    flex-wrap: wrap;
  }

  .results .all-breakdowns-for-print .space-category-sidebar-column {
    width: 100%;
  }

  .results .all-breakdowns-for-print .space-category-sidebar-column .subtitle {
    margin-bottom: .5rem;
  }

  .results .all-breakdowns-for-print .space-category-breakdown-container {
    page-break-after: always;
  }

  .results .all-breakdowns-for-print .underline-input {
    border-bottom: none;
  }

  .results .all-breakdowns-for-print .space-category-breakdown-row {
    width: 200%;
  }

  .results .all-breakdowns-for-print .space-category-breakdown-row .column {
    padding: 0;
  }

  .results .all-breakdowns-for-print .space-category-breakdown-row .space-category-breakdown-row-name {
    width: 20%;
    font-size: .8rem;
  }

  .results .all-breakdowns-for-print .space-unit-breakdown-totals {
    width: 200%;
  }

  .results .all-breakdowns-for-print .space-unit-breakdown-totals .is-9-desktop {
    flex: none;
    width: 75%;
  }

  .results .all-breakdowns-for-print .space-unit-breakdown-totals .is-3-desktop {
    flex: none;
    width: 25%;
  }

  .results .all-breakdowns-for-print .space-unit-breakdown-totals .loss-factor-container {
    position: relative;
  }

  .spacer-logo-for-screen, .tooltip {
    display: none !important;
  }
}

.is-firefox .email-wall-envelope-icon {
  background-position: 100% 60% !important;
  background-size: auto !important;
}

.is-firefox .copy-to-clipboard-box-clipboard {
  background-color: #fcfcfc !important;
}

.is-firefox .copy-to-clipboard-box-clipboard:hover {
  background-color: #6666661a !important;
}

.is-ie .email-wall-envelope-icon {
  background-position: 100% 80% !important;
  background-size: auto !important;
}

.is-ie .email-wall-checkbox {
  border-radius: 0 !important;
  outline: 1px solid #d6e3df !important;
}

.is-ie .copy-to-clipboard-box-clipboard {
  background-color: #fcfcfc !important;
}

.is-ie .copy-to-clipboard-box-clipboard:hover {
  background-color: #6666661a !important;
}

.is-ie .slider-custom {
  cursor: pointer !important;
  color: #0000 !important;
  opacity: 1 !important;
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  outline: none !important;
  width: 587px !important;
  height: 35px !important;
  padding: 0 !important;
}

.is-ie .slider-custom::-ms-fill-lower {
  background: #00b2dd !important;
  border: 5px solid #00b2dd !important;
}

.is-ie .slider-custom::-ms-fill-upper {
  background: #00b2dd !important;
  border: 5px solid #00b2dd !important;
}

.is-ie .slider-custom::-ms-thumb {
  background: url("headcount-slider-icon-spacer-by-cbre.3d9c70ae.svg") !important;
  border: none !important;
  width: 35px !important;
  height: 35px !important;
}

.is-ie .slider-custom:focus::-ms-fill-lower {
  background: #00b2dd !important;
}

.is-ie .slider-custom:focus::-ms-fill-upper {
  background: #00b2dd !important;
}

.is-ie .slider-text {
  padding-top: 5px !important;
}

.is-ie .slider-right-text {
  padding-left: 6%;
}

.is-ie .quiz .quiz-exit-slide {
  padding-top: 5rem !important;
  padding-bottom: 2.5rem !important;
}

.is-ie .quiz .spectrum-question .radio-spectrum-container {
  margin-top: 7% !important;
}

.is-ie .quiz .spectrum-question .question-answer {
  height: 13rem !important;
}

.is-ie .quiz .grouped-questions {
  min-height: 22rem !important;
}

.is-ie .quiz .question-answer-helper-text {
  margin-top: .75rem !important;
}

.is-ie .quiz-content > .column {
  width: 55% !important;
  margin-top: auto !important;
}

.is-ie .quiz-question .tags {
  margin-top: -5rem;
}

.is-ie .space-category-units-container .space-category-unit {
  margin-left: 1rem !important;
}

.is-ie .profile-summary {
  padding-bottom: 8.5rem !important;
}

.is-ie .profile-traits {
  flex: none !important;
}

.is-ie .days-per-week-assumption-separator {
  margin-right: 0 !important;
}

.is-ie .days-per-week-assumption-text {
  margin-left: 1rem !important;
}

.is-ie .collaboration-assumption-separator {
  padding-left: .1rem !important;
}

.is-ie ::-ms-clear {
  display: none;
}

.is-ie .referral-sign-up .form-header span {
  width: 18.4rem !important;
}

.clipboard-copy-btn {
  width: 300px !important;
}

.sentence-complete-answer .dropdown-menu {
  left: 0;
  right: auto;
}

.cbre-spacer-logo {
  color: #003f2d;
  font-size: 32px;
  font-weight: 500;
}

.cbre-spacer-logo:hover {
  color: #003f2d !important;
}

.cbre-spacer-logo:focus {
  background-color: none;
}

.navbar.is-tertiary .cbre-spacer-logo:hover {
  color: #fff !important;
}

.intercom-launcher-discovery-frame {
  display: none !important;
}

.form-error-text {
  color: #fc5328;
  font-family: Calibre Regular, Arial, Helvetica, sans-serif;
}

.hamburger-bar {
  background: url("hamburger-menu-spacer-by-cbre.016db4bb.svg") no-repeat;
  border: none;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
}

.hamburger-bar-open {
  background-color: #0000;
  border: none;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  padding-bottom: 1.5rem;
}

.hamburger-bar-open:before, .hamburger-bar-open:after {
  content: " ";
  background-color: #003f2d;
  width: 2px;
  height: 23px;
  position: absolute;
  left: 25px;
}

.hamburger-bar-open:before {
  transform: rotate(45deg);
}

.hamburger-bar-open:after {
  transform: rotate(-45deg);
}

.hamburger-bar-menu {
  z-index: 1;
  position: absolute;
  top: 100%;
  right: -25%;
}

.hamburger-bar-menu a {
  background-color: #fcfcfc;
  padding: .5rem 8rem;
  box-shadow: 0 10px 6px -6px #b5b5b5;
}

.hamburger-bar-menu a:first-child {
  border-bottom: 1px solid #f3f3f3;
}
/*# sourceMappingURL=spacerFree.2306c903.css.map */
