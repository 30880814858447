.profile-comparison
  min-height: 100vh
  padding-left: $size-4
  padding-right: $size-4
  padding-bottom: $size-1

  .title
    color: $green-forest-dark

.profile-column
  text-align: center
  cursor: pointer
  height: 100%
  display: flex
  flex-direction: column
  justify-content: stretch

  .title
    color: $white

  &.is-active
    .button,
    .profile-summary
      background-color: $teal-button
    .profile-traits
      background-color: rgb(33, 149, 133)

  &:hover:not(.is-active)
    .button
      background-color: $teal-button
      color: $white !important

.profile-summary-and-traits
  border: $radius solid transparent
  box-sizing: border-box
  display: flex
  flex-direction: column
  flex: 1

.profile-summary
  background-color: $green-forest-dark
  transition: background-color $standard-transition
  padding: $size-7 $size-5 $size-5 $size-5
  display: flex
  flex-direction: column
  flex-grow: 1

  img
    max-height: 10rem

.profile-description
  color: $primary-invert
  font-size: $size-7

.profile-traits
  padding: $size-6
  display: flex
  justify-self: flex-end
  flex-wrap: wrap
  align-content: flex-start
  background-color: rgb(22, 111, 98)
  transition: background-color $standard-transition

  .profile-trait
    width: 50%
    display: inline-block
    padding: 0.5rem

    .title
      font-size: $size-6 !important
      margin-bottom: 0.5rem !important

    img
      height: 1.5rem
      width: 1.5rem

    .profile-trait-description
      font-size: $size-7
      line-height: 1.25
      color: $primary-invert