.slider-wrapper
	display: flex
	flex-direction: row
	justify-content: flex-start
	text-align: center
	max-width: 700px

.slider-item
	padding-right: 3%
	width: 80%

.slider-custom
	-webkit-appearance: none
	-webkit-user-select: none
	-webkit-transition: .2s
	width: 102%
	height: 2px
	border: solid 6px $blue-light
	border-radius: 20px
	opacity: 1
	background: none
	outline: none
	padding: 0

	&::-webkit-slider-thumb
		-webkit-appearance: none
		height: 32px
		width: 35px
		object-fit: contain
		cursor: pointer
		background: url('/public/svg/form-elements/headcount-slider-icon-spacer-by-cbre.svg')
		border: none

	&::-moz-range-track
		background: transparent

	&::-moz-range-thumb
		-moz-appearance: none
		height: 32px
		width: 35px
		border-radius: 0px
		border: 0 none
		cursor: pointer
		background: url('/public/svg/form-elements/headcount-slider-icon-spacer-by-cbre.svg')

	&::-moz-focus-outer
		border: none
		outline: none

.slider-text
	height: 25px
	font-family: $family-sans-serif !important
	font-size: 18px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.39
	letter-spacing: 0.5px
	color: $white
	width: 10%
