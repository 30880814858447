.copy-to-clipboard-box
  background-color: $white
  border-radius: 0.1875rem
  width: 18.3rem
  height: 4.3rem
  box-shadow: 0 0.125rem 0.25rem 0 rgba($grey, 0.5)
  position: absolute
  margin: 0.625rem 0 0 0
  right: 0
  +mobile
    margin: 2.5rem 0 0 -10rem


.copy-to-clipboard-box-text
  width: 100%
  height: 1.625rem
  font-family: $family-sans-serif
  font-size: 0.77rem
  letter-spacing: 0.4px
  margin-top: 2px
  margin-left: 12px
  color: $grey-new
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
  width: 290px

.copy-to-clipboard-box-input
  float: left
  width: 67%
  height: 1.8rem
  margin: 0.4rem 0 0 0.8rem
  border-radius: 0.1875rem
  border: solid 0.0625rem $border
  font-family: $family-sans-serif
  font-weight: 500
  font-size: 0.8rem
  letter-spacing: 0.4px
  text-align: center
  color: $text-light
  padding: 0.5625rem 0.7rem 0.4375rem 0.5rem

.copy-to-clipboard-box-clipboard
  float: right
  display: inline
  width: 4.2rem
  height: 1.7rem
  border-radius: 0.1875rem
  border: solid 0.0625rem $text-strong
  margin: 0.4375rem 0.4375rem 0 0
  font-family: $family-sans-serif
  font-size: $size-7
  font-weight: $weight-medium
  letter-spacing: 0.4px
  text-align: center
  color: $secondary-invert
  &:hover
    cursor: pointer
    background-color: rgba($grey, 0.1)
  &:focus
    outline: 0
  +mobile
    background-color: $white

.copy-to-clipboard-box-checkmark
  float: right
  display: inline
  width: 1rem
  height: 1rem
  margin: 0.8125rem 3.6rem 0 0
  background: url('/public/svg/icons/confirm-icon-no-background-spacer-by-cbre.svg')

.copy-to-clipboard-box-clipboard.copied
  color: $teal
  width: 3.2rem
  border: none
  margin-top: -1.5rem
  background-color: transparent
  cursor: auto
  &:hover
    background-color: transparent

.copy-to-clipboard-box-container
  padding-left: 0
  padding-top: 1.2rem
  display: block
  overflow: visible

.copy-to-clipboard-box-background
  +overlay
  background-color: $white
  opacity: 0
  position: fixed
  height: 100%
  width: 100%
