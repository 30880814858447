$tag-background-color: $teal-dark !default
$tag-hover-background-color: $teal-tag-hover !default
$tag-color: $white !default
$tag-radius: $radius-small !default
$tag-delete-margin: 1px !default
$tag-background-color-new: $teal-dark-new  !default

.tags
  position: absolute
  align-items: flex-start
  align-content: flex-start
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  .tag
    margin-bottom: 1rem
    border: none
    &:not(:last-child)
      +from($tablet)
        margin-right: 1rem
  &:last-child
    margin-bottom: -1rem
  &:not(:last-child)
    margin-bottom: 1rem
  &.has-addons
    .tag
      margin-right: 0
      &:not(:first-child)
        border-bottom-left-radius: 0
        border-top-left-radius: 0
      &:not(:last-child)
        border-bottom-right-radius: 0
        border-top-right-radius: 0

.tag:not(body)
  background-color: $tag-background-color-new
  border-radius: $tag-radius
  color: $tag-color
  font-size: $size-small
  height: 2em
  line-height: 1.5
  padding-left: 1.25em
  padding-right: 1.25em
  white-space: nowrap
  &.tag-btn-width
    max-width:500px
  &:not(.is-block)
    display: inline-flex
    align-items: center
    justify-content: center
  &.is-clickable
    cursor: pointer
    transition: background-color $standard-transition
    &:focus,
    &:active
      outline: none
    &:hover,
    &:focus
      background-color: $tag-hover-background-color
  &.is-disabled
    pointer-events: none
    outline: none
    opacity: 0.5
  &.btn-width
    white-space: nowrap
    max-width: 400px
    overflow: hidden
    text-overflow: ellipsis
  .delete
    margin-left: 0.25em
    margin-right: -0.375em
    cursor: pointer
    align-items: center
    display: flex
  .flex-btn-ellipsis
    min-width: 0
    flex-basis: 92%
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

  // Colors
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)
    &.is-#{$name}
      background-color: $color
      color: $color-invert
  // Sizes
  &.is-medium
    font-size: $size-normal
  &.is-large
    font-size: $size-medium
  // Modifiers
  &.is-delete
    margin-left: $tag-delete-margin
    padding: 0
    position: relative
    width: 2em
    &:before,
    &:after
      background-color: currentColor
      content: ""
      display: block
      left: 50%
      position: absolute
      top: 50%
      transform: translateX(-50%) translateY(-50%) rotate(45deg)
      transform-origin: center center
    &:before
      height: 1px
      width: 50%
    &:after
      height: 50%
      width: 1px
    &:hover,
    &:focus
      background-color: darken($tag-background-color, 5%)
    &:active
      background-color: darken($tag-background-color, 10%)
  &.is-rounded
    border-radius: 290486px
  &.has-border
    border: 2px solid $inactive-on-primary-background

  +mobile
    height: auto
    width: 100%
    white-space: initial
