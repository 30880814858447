@font-face
  font-family: 'Calibre Regular'
  src: url('/public/fonts//CalibreWeb-Regular.woff2') format('woff2'), url('/public/fonts//CalibreWeb-Regular.woff') format('woff')


@font-face
  font-family: 'Calibre Medium'
  src: url('/public/fonts//CalibreWeb-Medium.woff2') format('woff2'), url('/public/fonts//CalibreWeb-Medium.woff') format('woff')


@font-face
  font-family: 'Calibre Semibold'
  src: url('/public/fonts//CalibreWeb-Semibold.woff2') format('woff2'), url('/public/fonts//CalibreWeb-Semibold.woff') format('woff')

@font-face
  font-family: 'Financier Medium'
  src: url('/public/fonts//financier/financier-display-web-medium.eot') format('eot'), url('/public/fonts//financier/financier-display-web-medium.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium.woff2') format('woff2')

@font-face
  font-family: 'Financier Regular'
  src:  url('/public/fonts//financier/financier-display-web-medium.eot') format('eot'), url('/public/fonts//financier/financier-display-web-medium.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium.woff2') format('woff2')

@font-face
  font-family: 'Financier Semibold'
  src:  url('/public/fonts//financier/financier-display-web-semibold.eot') format('eot'), url('/public/fonts//financier/financier-display-web-semibold.woff') format('woff'), url('/public/fonts//financier/financier-display-web-semibold.woff2') format('woff2')

@font-face
  font-family: 'Financier',sans-serif
  src:  url('/public/fonts//financier/financier-display-web-medium-italic.eot') format('eot'),  url('/public/fonts//financier/financier-display-web-medium-italic.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium-italic.woff2') format('woff2')
  font-weight: 500
  font-style: normal
  font-display: fallback

@font-face
  font-family: 'Financier',sans-serif
  src:  url('/public/fonts//financier/financier-display-web-medium-italic.eot') format('eot'),  url('/public/fonts//financier/financier-display-web-medium-italic.woff') format('woff'), url('/public/fonts//financier/financier-display-web-medium-italic.woff2') format('woff2')
  font-weight: 400
  font-style: normal
  font-display: fallback

@font-face
  font-family: 'Financier',sans-serif
  src:  url('/public/fonts//financier/financier-display-web-semibold-italic.eot') format('eot'), url('/public/fonts//financier/financier-display-web-semibold-italic.woff') format('woff'), url('/public/fonts//financier/financier-display-web-semibold-italic.woff2') format('woff2')
  font-weight: 600
  font-style: italic
  font-display: fallback
       
@import '../shared/utilities/_all'
@import '../shared/base/_all'
@import '../shared/components/_all'
@import '../shared/elements/_all'
@import '../shared/grid/_all'
@import '../shared/layout/_all'

// PAGE SPECIFIC
@import 'quiz'
@import 'results'
@import 'profile-comparison'
@import 'info-pages'
@import 'blog'
@import 'referrals'

// PRINT
@import 'print/results'
@import 'print/general'

// BROWSER OVERRIDES
@import 'firefox-overrides'
@import 'ie-overrides'

//EXTERNAL OVERRIDES
@import 'external-overrides'

//COMMON STYLING
@import 'common'