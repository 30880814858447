.blog-home

  .hero-body .title
    width: 100%

.blog-hero
  padding-top: 6.5rem
  padding-bottom: 6.5rem
  background-image: url('/public/png/blog-page-header.png')
  background-position: center
  background-size: cover


.hero.blog-hero-small
  padding-top: 0
  padding-bottom: 0

  .hero-body
    padding-bottom: 0.5rem
    padding-top: 0.5rem


.blog-article-container
  padding-right: 1rem
  padding-left: 1rem
  max-width: 800px
  margin-left: auto
  margin-right: auto

.blog-article-preview
  overflow: hidden
  border: 1px solid $grey-light
  border-radius: $radius-large

  &:hover,
  &:focus
    figure
      opacity: 0.9
    .blog-link
      color: $primary-hover

.blog-article-preview-content
  margin-top: -1rem
  color: #1A1A1A99
  font-family: $family-sans-serif
  overflow: hidden

  // show a cutoff ellipsis on multiline text
  display: -webkit-box
  -webkit-line-clamp: 6
  -webkit-box-orient: vertical

  // in case the multiline thing from above fails
  max-height: 30rem

.blog-link
  display: block
  margin-top: 1rem
  color: $primary

.blog-tag-filter-background
  position: relative
  width: 100%

.blog-tag-filter
  background-color: #F5F7F7
  position: absolute
  width: 100%
  bottom: 100%

  .blog-tag-filter-options
    max-width: 60%
    margin: auto

  +mobile
    .blog-tag-filter-options
      max-width: 100%
      margin: 0 0.25rem

  input
    @extend .visually-hidden

    &:focus + .blog-tag-filter-option
      border: 1px dashed $white

  .blog-tag-filter-option
    color: $black
    padding: 10px
    border: 1px dashed transparent

    &:hover
      cursor: pointer
      text-decoration: underline

  .active-tag
    text-decoration: underline


// blog content styling
.blog-content
  font-family: $family-sans-serif
  margin: 0 5rem

  p
    margin-bottom: 1rem

  h2
    font-weight: 700
    margin-bottom: 0.3rem

+mobile
  .blog-content
    margin: 0

.blog-home-link:hover
  text-decoration: underline


