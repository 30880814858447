.referrals-navbar
  height: 1.5rem
  margin-top: 2rem
  font-family: $family-sans-serif
  +mobile
    margin-top: 1.1rem
    margin-left: 0.5rem

.referral-sign-up

  .hero-body
    +mobile
      padding: 0

  .title
    width: auto
    margin-top: 1rem

  .referral-link-box
    background-color: $white
    padding: 1rem
    height: 30rem
    width: 30rem

  .referral-instructions-and-form
    width: 50%
    margin: auto
    margin-top: 2rem
    +mobile
      width: 80%

  .get-referral-link
    width: 75%
    +mobile
      width: 100%

  .retrieve-link-form
    margin-top: 3rem

    div
      font-family: $family-sans-serif

    li
      font-family: $family-sans-serif
      padding-left: 0.5rem
      +mobile
        margin-bottom: 1rem

  .user-inputs
    width: 70%
    +mobile
      width: 90%

  .form-title
    font-family: $family-sans-serif
    font-size: 1rem

  .edit-referral-info-button
    background: url('/public/svg/icons/spacer-pro-edit-icon.svg')
    border: none
    height: 0.9rem
    margin: initial
    cursor: pointer
    padding: 0 0.45rem
    margin-top: 0.4rem
    margin-left: 0.5rem
    width: auto
    background-repeat: no-repeat

  .subtitle
    font-size: 17px

  .columns
    margin-bottom: 0

  input[type="text"],
  input[type="email"]
    height: 2rem
    border: none
    border-bottom: 1px solid $black
    font-family: $family-sans-serif-medium
    font-size: 1rem

    &:focus
      outline: none
      border-bottom: 2px solid $black

    &[aria-invalid="true"]
      border-bottom: 1px solid $red


  label,
  span,
  div
    font-family: $family-sans-serif

  .bold-input
    font-family: $family-sans-serif-medium

  .form-error-text
    +mobile
      font-size: 0.9rem

  input:read-only
    font-family: $family-sans-serif
    background: transparent
    border: 1px solid $green-forest-dark !important
    cursor: default
    border-radius: $radius

  button
    margin: auto
    width: 14rem
    margin-top: 2rem

  .link-never-expires
    margin-top: 0.5rem
    margin-left: 7rem
    +mobile
      margin-left: 2.5rem

  .copied-link-icon
    width: 1rem
    height: 1rem
    background: url('/public/svg/icons/confirm-icon-no-background-spacer-by-cbre.svg')

  .server-error
    margin-left: 3.5rem
    +mobile
      margin-left: 2.5rem

  .link-in-referral-pages
    // importants here because .hero.is-light-pale-green supercedes this for some reason
    color: $green-forest-dark !important
    text-decoration: underline
    &:hover, &:focus
      color: $primary-hover !important
      outline: none
