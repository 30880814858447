.your-details

  .your-details-border
    border: 1px solid $grey-lighter
    width: 100%

    +tablet-only
      width: 111%
      margin-left: -2rem

  .assumption-separator
    width: 2px
    height: 90%
    background-color: $grey-light
   
  
  .days-per-week-assumption-separator
    margin-right: $size-5

  .save-changes-button
    min-width: 130px
    margin-top: 2rem
    border-radius: $radius-large
    outline: none

  .assumptions-controls
    padding: 0 .5rem
    top: 0.8rem
    position: relative
    background-color: $white-bis

    .dropdown-menu
      left: auto
      right: 0
      width: 175px

    .radio-control-container
      position: absolute
      top: 0.1rem
      left: 0.75rem
      width: 100%
    
    .circulation-dropdown
      .circulation-dropdown-input
         .dropdown
           .underline-input
             .input
               white-space: nowrap 
               overflow: hidden 
               text-overflow: ellipsis 
               display: block 
               width: 300px
      .dropdown-menu
        left: auto
        right: 0
        width: 375px
    
  .assumptions-controls-container
    +tablet-only
      display: flex
      flex-wrap: wrap
    .assumption-container
        .headcount-container
            margin-left: 1.2rem
  
  .assumption-container
    display: flex
    align-items: center
    
  .assumption-title,
  .assumption-unit
    margin-bottom: 0.5rem
    font-family: $family-sans-serif
    font-size: 0.8rem
    +tablet-only
      max-width: 10%


  .assumption-unit
    margin-left: 0.4rem

  .recommended-office-size-section
    +tablet-only
      margin-right: 100rem

  .recommended-office-size-content
    +tablet-only
      width: 110%

  .headcount-following-text
    margin-top: 0.5rem

  .days-per-week-assumption-text
    +tablet-only
      max-width: 10rem
  .per-week-text
    width: 51px
  .profile-type-name
    max-width: 10rem
    display: block
    font-family: $family-sans-serif-medium

  .is-mobile-input, .is-mobile-input-flex
    display: none

  .your-details-workspace
    padding: $size-3
    +tablet-only
      padding: $size-6

  .program-reset-button
    margin-top: 2rem
    margin-right: 1rem
    border-radius: $radius-large
    padding-left: 2rem
    background-image: url('/public/svg/icons/revert-forest-green-icon-spacer-by-cbre.svg')
    background-position: 1rem
    background-repeat: no-repeat
    cursor: pointer
    outline: none
    &:hover
      color: $teal-bright
      background-image: url('/public/svg/icons/revert-bright-teal-icon-spacer-by-cbre.svg')

  .space-category-dropdown-input
    width: 13rem
    cursor: pointer
    background-image: url(/public/svg/icons/down-arrow-teal-icon-spacer-by-cbre.svg)
    background-repeat: no-repeat
    background-position: 95% 50%
    font-family: $family-sans-serif-medium
    border-radius: $radius-xlarge
    +tablet-only
      width: 20rem

  .space-dropdown-width
     overflow: hidden
     text-overflow: ellipsis
     display: block
     white-space: nowrap
     padding-right: 27px

  .space-category-dropdown-content
    background-color: $white
    border-radius: $radius-xlarge
    width: 13rem
    +tablet-only
      width: 20rem

  .space-category-dropdown-item
    color: $black
    width: 13rem
    font-family: $family-sans-serif !important
    &.hover
      color: $white
      background-color: $teal-dark-new
    +tablet-only
      width: 25rem

  .is-space-category-dropdown
    outline: none

.mobile-assumption-separator
  display: none

+tablet-only
  .mobile-assumption
    margin-right: 1rem

+mobile
  .your-details

    .your-details-workspace
      padding: 0
      bottom: 1rem

  .mobile-assumption-container
    position: relative
    width: 100%

  .mobile-assumption-title
    padding-top: 0.8rem

  .assumptions-controls .radio-control
    display: inline-block
    width: 30%

  .program-bar-chart-segment-percentage
    font-size: $size-4

  .program-bar-chart-segment-amount
    display: none

  .profile-type-name
    max-width: none !important

  .is-mobile-input
    display: inherit !important

  .is-mobile-input-flex
    display: flex !important
    padding-left: 1rem
    padding-right: 1rem

  .mobile-assumption-separator
    width: 90%
    height: 1px
    margin-left: 0.9rem
    background-color: $grey-light

  .mobile-assumption
    margin-left: auto

  .mobile-buttons 
    text-align: right
    margin-top: 2rem
    margin-right: 1rem

  .is-primary-filled
    &.success
      background-color: rgba($primary, 0.5)

  .profile-and-headcount-assumption-style
    margin-left: 8rem

  .days-in-office-assumption-style
    margin-left: 5rem

  .collaboration-assumption-style
    margin-left: 5.5rem

  .loss-factor-container
    position: relative
