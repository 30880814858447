.space-summary

  .space-section
    background-color: $grey-lightest
    width: 75%
    position: relative
    +tablet-only
      width: 100%

    .sf-label-container
      display: flex
      align-items: center
      margin-bottom: $size-4

      .w-90
        width: 90%
      .title
        margin-bottom: 0
      .tooltip
        padding-bottom: 0

    .rsf-bar
      height: 40px

      .bar
        width: 100%
        height: 10px
        background-color: $purple-light

        .bar-progress
          width: 0
          height: 0
          border-style: solid
          border-width: 14px 11px 0 11px
          border-color: #073a41 transparent transparent transparent

        .min-max-labels
          position: relative
          top: -30px

    .radio-button-name
      text-decoration: underline
      font-size: 0.75rem

    .radio-control-container
      font-family: $family-sans-serif-medium

    .radio-control .icon
      height: 0.8rem
      margin-top: 0.4rem


    .tooltip
      padding-bottom: 7px

  .loss-factor-section
    background-color: $light-pale-green
    width: 75%
    cursor: pointer
    +tablet-only
      width: 100%

    .loss-factor-button
      position: relative

      .loss-factor-text
        margin-top: 6px

      .arrow-image
        background-image: url('/public/svg/icons/down-arrow-teal-icon-spacer-by-cbre.svg')
        height: 10px
        width: 16px
        margin: 16px 10px 16px 26px

        &.is-up
          transform: rotate(180deg)

    .loss-factor-helper-description
      padding: 20px

+mobile
  .space-summary
    .space-section
      width: 100%

    .loss-factor-section
      width: 100%

  .mobile-space-summary-section
    padding: 0
