$quiz-progress-label-height: 2.5rem
$question-text-height: 5rem
$sentence-complete-answer-height: 2.33rem
$sentence-complete-answer-margin-top-bottom: 0.5rem
$quiz-navigation-btn-height: 2rem
$question-response-height: 10rem

////////////////////////////
// STYLES FOR ALL SCREENS //
////////////////////////////
.quiz
  background-color: $primary-new
  overflow: hidden
  height: 100%

.quiz-exit-slide
  height: 100%

  .loader
    width: 2.5rem
    height: 2.5rem
    margin: auto

.quiz-content
  padding-bottom: calc(#{$navbar-height} / 2)
  min-height: 800px

  +until($widescreen)
    min-height: 900px



.quiz-content > .column
  flex: none
  width: 75%

  +from($widescreen)
    margin-top: -6rem

.quiz-question-slide
  display: flex
  flex-direction: column
  justify-content: space-between

.quiz-progress-label
  color: $primary-invert
  letter-spacing: $letter-spacing-small
  .progress
    width: 30%
    height: 0.3rem

.quiz-question .tag
  margin-right: 0.5rem

.question-text
  color: $primary-invert

  .underline-input
    .input
      font-size: 1.88rem

    input[type="number"]
      max-width: 4rem
      font-family: $title-font-family

  .dropdown-item
    height: auto
    font-size: 1.25rem

.question-answer
  letter-spacing: $letter-spacing-medium

.question-response
  display: flex
  align-items: center

  &.hide  // sketchy way to make it not take up space but avoid display: none, so fade in still works
    position: absolute
    top: 0
  &.fade-in
    img.is-invisible-to-be-faded-in
      display: none

.question-response-title
  width: 25%

.sentence-complete-answer
  color: $active-on-primary-background
  display: flex
  flex-wrap: wrap
  align-items: start
  line-height: 2
  margin: $sentence-complete-answer-margin-top-bottom 0
  transition: all $standard-transition

  &.is-inactive
    color: $inactive-on-primary-background

  &.is-unselected
    color: $unselected-on-primary-background

  .sentence-complete-words
    padding-right: 0.5rem
    height: $sentence-complete-answer-height

  .headcount-question
    +tablet-only
      padding-right: 0

  .underline-input
    margin: 0 0.5rem
    +until($widescreen)
      margin-left: 0

  .dropdown-menu
    max-width: 600px
    +until($widescreen)
      left: 0

  // for cases where sentence complete is the main question, meaning there is no question "text" property
  &.title .input
    font-family: $title-font-family


.inactive-quiz-question-container
  background-color: transparent
  border: none

  .quiz-question
    width: auto

.inactive-quiz-question
  padding: 0
  
  .sentence-complete-answer,
  .sentence-complete-answer .input
    font-size: $size-6 !important
    color: $inactive-on-primary-background
    font-family: $title-font-family

    .question-text
      cursor: pointer

    .dropdown-menu
      display: none

    .underline-input > input[type="text"]
      width: 10rem

.question-response-title
  color: $teal-header

.quiz-navigation-btns-container
  position: relative

  +until($widescreen)
   

.quiz-navigation-btn
  height: $quiz-navigation-btn-height
  position: absolute
  bottom: 0 // keep them visible on small screen
  margin: $size-7 $size-9
  transition: all $standard-transition
  &:first-of-type,
  &.is-pulled-left
    left: 0
  &:focus,
  &:active
    outline: none
    &.is-tertiary-filled
      border: none
      background-color: $tag-hover-background-color
      &:hover
        background-color: $teal-dark-new
    &.is-tertiary-outlined
      border-color: $tag-hover-background-color


.quiz-sticky-footer
  box-shadow: 0px -1px 5px $black
  height: 68px
  padding: 0 12px

  +from($widescreen)
    display: none

  .quiz-navigation-btn
    position: absolute
    height: 44px
    width: 6rem
    &:first-of-type,
    &.is-pulled-left
      left: 12px


.radio-spectrum-container .radio-spectrum
  width: 20%


//////////////////////////////////////
// STYLES FOR TABLET & DESKTOP ONLY //
//////////////////////////////////////
+tablet-only
  .tags
    position: initial
    padding-bottom: 3rem

+from($tablet)
  .quiz-question-slide
    min-height: 34rem

  .quiz-progress-label
    font-size: $size-6
    height: $quiz-progress-label-height

  .quiz-question
    width: 100% // for some reason this pushes quiz response illustrations down nicely

    .rank-question-tooltip
      padding-bottom: 0.5rem
      &:before,
      &:after
        left: 40% !important

    &.sentence-completion-question .question-response
      margin-top: 3rem

  .question-text
    font-size: 1.88rem
    display: flex
    align-items: center
    &:not(.sentence-complete-answer)
      height: $question-text-height

  .sentence-complete-answer
    min-height: $sentence-complete-answer-height

  .inactive-quiz-question-container
    &:hover,
    &:focus,
    &:active
      outline: none
      .sentence-complete-answer,
      .sentence-complete-answer .input
        color: $active-on-primary-background !important
        .underline-input
          border-bottom: $underline-input-border-width solid $active-on-primary-background
          .dropdown-triangle
            +triangle-down($active-on-primary-background, $underline-input-triangle-size, $underline-input-triangle-size)
          .dropdown-triangle-up
            +triangle-up($active-on-primary-background, $underline-input-triangle-size, $underline-input-triangle-size)

  .inactive-quiz-question .question-answer
    margin-bottom: $size-6

  .radio-spectrum-container
    margin-top: 10%

  .question-response
    height: 10rem
    margin-bottom: 1rem

    img
      height: 10rem
      width: auto

  .question-response-title
    max-width: 25%
    padding-bottom: 0.5rem

  .quiz-navigation-btn
    margin-bottom: -2rem
    min-width: $button-min-width-narrow
    &:last-of-type:not(.is-pulled-left)
      left: calc(#{$button-min-width-narrow} + #{$size-6})



////////////////////////////
// STYLES FOR MOBILE ONLY //
////////////////////////////
+mobile
  .quiz-content
    padding-bottom: $navbar-height-mobile
    padding-top: 0
    height: auto

  .quiz-content > .column
    flex: none
    width: 100%

  .quiz-progress-label
    font-size: $size-7
    margin-bottom: 0 !important

    .percentage
      padding-top: 0.4rem
      padding-left: 0.2rem

  .quiz-exit-slide
    padding: $size-4

  .quiz-question-slide
    padding: 0 $size-4 $size-4 $size-4
    min-height: calc(100vh - #{$navbar-height-mobile})

  .question-text
    height: auto

  .quiz-question
    .tags
      position: initial
      padding-top: $size-6
    .tag
      padding-left: 0.5rem
      padding-right: 0.5rem
      &.is-disabled
        display: none

  .question-text
    font-size: $size-5

    &.sentence-complete-answer
      line-height: 2

    .underline-input
      .input
        font-size: $size-5

      input[type="number"]
        padding-bottom: 0.5rem

  .radio-spectrum-container
    margin-top: -1rem

    .radio-spectrum
      width: 100%

  .sentence-complete-answer
    height: auto
    margin: 0 0 0.25rem 0
    position: relative

    .underline-input
      margin: 0 0.5rem 0 0 !important

    .input
      line-height: 1
  
  .question-response
    margin-top: 3rem
    height: $question-response-height

  .question-response-title
    width: 50%

  .quiz-navigation-btns-container
    min-height: $quiz-navigation-btn-height
    margin-bottom: $size-6

  .quiz-navigation-btn
    min-width: 100px
    bottom: unset
    top: 0
    &:last-of-type:not(.is-pulled-left) //Sets the margin between buttons
      left: 150px


  .inactive-quiz-question-container
    padding-bottom: 0.5rem

    .question-answer
      margin-bottom: 0
