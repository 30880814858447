@import "results/email-wall.sass"
@import "results/space-summary.sass"
@import "results/program-stats.sass"
@import "results/example-plans.sass"
@import "results/space-category-breakdown.sass"
@import "results/space-program-details.sass"

.results

  // RESULTS GENERAL

  .hero-section .container
    padding: 0 4rem

  .section .container
    padding: 0 3rem

  .light-green-content-section
    background-color: rgba($light-pale-green, 0.5)

  .light-gray-content-section
    background-color: $light-gray
  .no-margin-bottom
    margin-bottom: 0

  .results-breakdown-padding
    padding-left: 6rem
    +until($fullhd)
      padding-left: 0


  .underline-input
    .input
      height: 1.5rem
      font-size: 0.8rem
      font-family: $family-sans-serif-semibold

    &.is-disabled
      border: none !important
      .input
        color: $grey-darker !important

  .content-section

    .section-title
      font-size: 1.75rem


    .section-subtitle
      font-size: 0.9rem
      margin-bottom: 2rem


  .is-highlight-content
    background-color: rgba($background-accent, 0.4)

  .subtitle p
    margin-bottom: 0.5rem

  .section-title-container
    margin-left: 3rem


  // PROFILE GENERAL

  .profile-hero-name
    font-size: 3rem !important

  // PROFILE TRAIT

  .profile-traits-container
    +tablet-only
      display: flex
      align-items: flex-start
      flex-wrap: wrap
      margin-left: 1rem

  .profile-trait
    +tablet-only
      width: 7rem

  .profile-trait > img
    height: 2rem

  .profile-trait-description
    line-height: 1.45
    font-size: 0.8rem
    +tablet-only
      width: 7rem
  
  .headCount
    .input
      min-width: 2rem

  // PROGRAM SAVE CONFIRMATION

  .program-save-confirmation-box
    min-width: 30rem
    height: 2.5rem
    background-color: rgba($grey-lighter, 0.2)
    display: flex
    justify-content: center
    align-items: center
    border-radius: $radius
    border: solid 1px rgba($green-forest-dark, 0.3)
    +mobile
      width: 100%
      height: 5rem

  .program-save-confirmation-box-text
    font-size: $size-6
    font-weight: $weight-medium
    &:before
      content: url('/public/svg/icons/checkmark-white-icon-in-green-circle-spacer-by-cbre.svg')
      margin-right: 0.8rem
      vertical-align: -15%
    +mobile
      padding-left: 1rem
      padding-right: 1rem

  // PROGRAM TINKER

  .program-tinker-warning
    z-index: 1
    width: 300px
    position: fixed
    top: 367px
    left: 63px
    font-size: 0.75rem

    +mobile
      left: 50%
      margin-left: -150px
      top: 200px

  .next-steps-title
    +tablet-only
      font-size: $size-4

  .next-steps-size
     font-size: 41px

  .find-space-button
    font-size: $size-6
    height: 3rem
    width: auto
    margin-top: 2rem
    background-color: $primary
    font-family: 'Calibre Regular'
    font-weight: 400
    +tablet-only
      width: 80%

  .next-steps-find-space-button
    min-width: 50%

  .navbar-find-space-button
    width: 35%
    margin-top: 1.25rem
    height: 2.5rem
    padding-left: 3em
    padding-right: 3em

  .space-category-breakdown-container
    +until($fullhd)
      display: flex
      flex-direction: column

  .space-category-sidebar-column
    +until($fullhd)
      display: flex
      flex-direction: column
      width: 100%
      padding-left: 1.5rem
    +until($desktop)
      display: block
      padding-left: 1.5rem

  +mobile
    width: 100%
    overflow-x: hidden

    .section
      padding: 1.5rem

    .navbar
      #spacer-logo
        width: 66px
      .copy-to-clipboard-box-container
        padding: 1.4rem 0.5rem 0rem 1rem
      .send-results-button
        font-size: 0.66rem
        margin-right: 0 !important
        width: 5rem
        padding-right: 1.25rem
      .find-space-button
        font-size: 0.66rem
        width: 55%
        margin-top: 0.85rem

    .hero-section .container,
    .section .container
      padding-left: 0
      padding-right: 0

    .profile-hero
      .subtitle
        font-size: $size-6
      .profile-hero-name
        font-size: 2.25rem !important
      figure
        padding-top: 0
        width: 200%
        height: 200%
        margin-left: -50%

        img
          position: relative

    .profile-trait
      width: 100%

    .section-title-container
      margin-left: 0

    .find-space-button
      width: 80%
      height: 2rem
      margin-left: 10%

    .next-steps
      .title
        font-size: $size-4

  .test-fit
    padding: 2rem 0
    font-family: $family-sans-serif-medium

    .container,
    .hero-body
      background-color: $test-fit-beige !important

    .test-fit-info
      float: right
      margin-right: 2rem

    .title
      font-size: $size-4
      font-family: $family-sans-serif-semibold
      margin-bottom: 2rem
      line-height: 2rem

    .subtitle
      font-size: $size-6
      font-family: $family-sans-serif
      margin-bottom: 5rem

    .button
      padding: 0 3rem
      +mobile
        margin-left: 10%

    .test-fit-example-pair
      width: 80%
      margin-left: 2rem

    form
      border: 2px solid $test-fit-form-border
      height: 100%
      padding: 1rem 2rem
      position: relative
      width: 80%
      float: left
      +mobile
        width: 85%

    .form-title
      font-family: $family-sans-serif-medium
      font-size: $size-5
      margin-bottom: 1rem

    input[type="text"]
      height: 2rem
      border: none
      border-bottom: 2px solid $black
      width: 30%
      font-family: $family-sans-serif-medium
      font-size: 1rem
      +mobile
        width: 100%

    input[type="radio"]
      margin-right: 0.25rem

    .test-fit-radio-pair
      label
        &:first-child
          margin-right: 1rem

    .submit-test-fit-form-button
      width: 40%
      position: absolute
      bottom: 5%
      +mobile
        position: initial
        width: 100%
        margin-left: 0

    .email-success-error-text
      position: absolute
      bottom: 0

    span
      font-size: $size-7
      font-family: $family-sans-serif
      color: $grey

    .test-fit-terms-conditions
      position: absolute
      bottom: 17%
      left: 12%
      width: 80%
      +mobile
        position: initial
        margin: 1rem 0

    .checkbox.in-results:before
      +mobile
        left: 1em


    .completed-test-fit-form
      +mobile
        width: 100%

    .completed-test-fit-form-content
      span
        font-size: $size-5

    .close-test-fit-form-button
      background: url('/public/svg/icons/close-button-test-fit-spacer-by-cbre.svg')
      margin-left: 1rem
      width: 1.65rem
      height: 1.65rem
      border: none
      cursor: pointer


.flooredPlanrequestSection
    background-color: #F5F7F7
    justify-content: center
    margin-top: 30px
    display: flex
   
    .flooredPlan-heading
       text-align: center
       font-family: $family-sans-serif
       font-style: normal
       font-size: 26px
       line-height: 55px
       margin-top: 30px
       margin-bottom: 40px
       font-weight: 500
    
    .sampleText
       text-align: center
       font-family: $family-sans-serif
       font-size: 20px
       font-style: normal
       margin-top: 30px
       font-weight: 500
  
    
    .redefinedText
      width: 450px
      height: 38px
      left: 271px
      font-family: $family-sans-serif
      font-style: normal
      font-weight: 500
      font-size: 20px
      line-height: 28px
      text-align: left
      margin-bottom: 20px
      color: #272B2F

    .subtext
      font-family: $family-sans-serif
      font-style: normal
      font-weight: 500
      font-size: 14px
      width: 402px
      height: 42px
      left: 271px
      text-align: left
      margin-bottom: 13px
    
    .icons-floored
      margin-bottom: 220px

    .paragraph
      width: 402px
      height: 84px
      left: 271px
      font-family: $family-sans-serif
      font-style: normal
      font-weight: 300
      font-size: 14px
      line-height: 20px
      text-align: left
    
   
    .icons-floored
      .columns
        .column
          text-align: center
          .floored-icon
           width: 70px
 
          
.line-seperator
  height: 1px
  background-color: #DADADA
  width: 100%
  margin-top: 60px
 

.text-1
  font-family: $family-sans-serif
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 38px
  width: 100%
  text-align: center

.text-2
  font-family: $family-sans-serif
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 20px
 
.Flooredvideo
   width: 550px
   height: 200px
   margin-top: 40px
   margin-bottom: 41px

   
 
.set-center
  display: flex
  justify-content: center
  align-items: center

.topDiv-column
  display: block !important