$input-color: $grey-darker !default
$input-background-color: $white !default
$input-border-color: $grey-lighter !default
$input-shadow: inset 0 1px 2px rgba($black, 0.1) !default

$input-hover-color: $grey-darker !default
$input-hover-border-color: $grey-light !default

$input-focus-color: $grey-darker !default
$input-focus-border-color: $link !default
$input-focus-box-shadow-size: 0 0 0 0.125em !default
$input-focus-box-shadow-color: rgba($link, 0.25) !default

$input-disabled-color: $text-light !default
$input-disabled-background-color: $background !default
$input-disabled-border-color: $background !default

$input-arrow: $link !default

$input-icon-color: $grey-lighter !default
$input-icon-active-color: $grey !default

$input-radius: 18px !default

$file-border-color: $border !default
$file-radius: $radius !default

$file-cta-background-color: $white-ter !default
$file-cta-color: $grey-dark !default
$file-cta-hover-color: $grey-darker !default
$file-cta-active-color: $grey-darker !default

$file-name-border-color: $border !default
$file-name-border-style: solid !default
$file-name-border-width: 1px 1px 1px 0 !default
$file-name-max-width: 16em !default

$label-color: $grey-darker !default
$label-weight: $weight-bold !default

$custom-input-size: 1.25em
$custom-input-line-height: 1.25

$radio-spectrum-input-size: 34px

$help-size: $size-small !default

$underline-input-border-width: 2px !default
$underline-input-triangle-size: 7px !default
$underline-input-triangle-size-large: 9px !default

=input-large
  border-radius: 18px
  height: 3.5rem
  font-size: 1rem

=input
  +control
  background-color: $input-background-color
  border-color: $input-border-color
  color: $input-color
  +placeholder
    color: rgba($input-color, 0.3)
  &:hover,
  &.is-hovered
    border-color: $input-hover-border-color
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    border-color: $input-focus-border-color
    box-shadow: $input-focus-box-shadow-size $input-focus-box-shadow-color
  &[disabled]
    background-color: $input-disabled-background-color
    border-color: $input-disabled-border-color
    box-shadow: none
    color: $input-disabled-color
    +placeholder
      color: rgba($input-disabled-color, 0.3)

.input,
.textarea
  +input
  box-shadow: $input-shadow
  max-width: 100%
  width: 100%
  &[type="search"]
    border-radius: 290486px
  &[readonly]
    box-shadow: none
  // Colors
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    &.is-#{$name}
      border-color: $color
      &:focus,
      &.is-focused,
      &:active,
      &.is-active
        box-shadow: $input-focus-box-shadow-size rgba($color, 0.25)
  // Sizes
  &.is-small
    +control-small
  &.is-medium
    +control-medium
  &.is-large
    +control-large
    +input-large
  // Modifiers
  &.is-fullwidth
    display: block
    width: 100%
  &.is-inline
    display: inline
    width: auto

.input
  &.is-static
    background-color: transparent
    border-color: transparent
    box-shadow: none
    padding-left: 0
    padding-right: 0

.textarea
  display: block
  max-width: 100%
  min-width: 100%
  padding: 0.625em
  resize: vertical
  &:not([rows])
    max-height: 600px
    min-height: 120px
  &[rows]
    height: unset
  // Modifiers
  &.has-fixed-size
    resize: none

.checkbox,
.radio
  cursor: pointer
  display: inline-block
  line-height: $custom-input-line-height
  position: relative
  right: -1.5em
  max-width: calc(100% - 1.5rem)
  padding-left: 0.25em

  input
    cursor: pointer
    // instead of not displaying the native input, hide it behind the custom one so we can still get built-in validation alerts
    position: absolute
    top: 0.5rem
    left: -1.25rem
    z-index: 0

  &[disabled],
  &.is-disabled
    opacity: 0.75
    pointer-events: none

  &:before
    content: ''
    width: $custom-input-size
    height: $custom-input-size
    position: absolute
    left: -1.5em
    border: 2px solid $black
    background-color: $white
    z-index: 1

.checkbox:not(.in-quiz)
  &:before
    border-radius: $radius
    margin-top: 3px
  &.is-active
    &:before
      content: url('/public/svg/icons/checkmark-forest-green-icon-spacer-by-cbre.svg')
      display: flex
      justify-content: center
      align-items: center

.checkbox.in-quiz
  color: white
  &.input
    position: absolute
    opacity: 0
    cursor: pointer
  &:before
    width: 18px
    height: 18px
    border-radius: 7px
    border: solid 1px $white
    background: $primary
    margin-top: 3px
  &.is-active
    &:before
      content: url('/public/svg/icons/checkmark-white-icon-spacer-by-cbre.svg')
      display: flex
      justify-content: center
      align-items: center
  +until($widescreen)
    margin-left: 0

.checkbox.in-results
  &:before
    width: 18px
    height: 18px
    border-radius: 7px
    border-color: $grey
  &.is-active
    &:before
      content: url('/public/svg/icons/checkmark-forest-green-icon-spacer-by-cbre.svg')
      display: flex
      justify-content: center
      align-items: center
  &:focus-within
    &:before
      border: solid 2px $input-focus-border-color

.radio
  &:before
    border-radius: 50%

  &:after
    content: ''
    width: 0.625em
    height: 0.625em
    position: absolute
    left: -1.175em
    top: 0.3125em
    border-radius: 50%
    background-color: transparent

  &:active,
  &.is-active
    &:before
      border: 1px solid $secondary
    &:after
      background-color: $secondary

.radio-spectrum
  display: inline-flex
  position: relative
  cursor: pointer
  color: $primary-invert
  padding-top: calc(1em + #{$radio-spectrum-input-size})
  padding-left: 0.75em
  padding-right: 0.75em
  text-align: center
  justify-content: center
  font-size: 18px
  line-height: 1.33
  letter-spacing: 0.2px
  transition: color $standard-transition

  input
    cursor: pointer
    @extend .visually-hidden

  &:before
    content: ''
    width: $radio-spectrum-input-size
    height: $radio-spectrum-input-size
    position: absolute
    top: 0
    left: calc(50% - #{$radio-spectrum-input-size*0.5})
    border: 2px solid $primary-invert
    border-radius: 50%
    background-color: $primary
    z-index: 1
    transition: background-color $standard-transition


  &:not(:last-child):after
    content: ''
    height: 2px
    width: calc(100% + #{$radio-spectrum-input-size*0.5})
    position: absolute
    top: calc(#{$radio-spectrum-input-size*0.5})
    left: 50%
    border-top: 2px solid $primary-invert
    z-index: 0

  &:hover
    font-weight: inherit
    +from($tablet)
      &:before
        background-color: $teal-header

  &:active,
  &.is-active,
  &:focus
    color: $teal-header
    &:before
      border-color: $teal-header
      background-color: $teal-header

  +mobile
    min-height: 5rem
    flex-direction: column
    padding-left: calc(1em + #{$radio-spectrum-input-size})
    justify-content: center
    padding-top: 0
    text-align: left
    height: 20%

    &:before
      justify-self: center
      left: 0
      top: calc(50% - #{$radio-spectrum-input-size*0.5})

    &:not(:last-child):after
      border-top: none
      border-left: 2px solid $primary-invert
      height: calc(100% + #{$radio-spectrum-input-size*0.5})
      top: 50%
      left: calc(#{$radio-spectrum-input-size*0.5})

.select
  display: inline-block
  width: 100%
  max-width: 100%
  position: relative
  vertical-align: top
  &:not(.is-multiple)
    height: 3.5em
    &::after
      +arrow($input-arrow)
      margin-top: -0.375em
      right: 1.125em
      top: 50%
      z-index: 4
  select
    +input
    cursor: pointer
    display: block
    font-size: 1em
    width: 100%
    max-width: 100%
    outline: none
    &::-ms-expand
      display: none
    &[disabled]:hover
      border-color: $input-disabled-border-color
    &:not([multiple])
      padding-right: 2.5em
    &[multiple]
      height: unset
      padding: 0
      option
        padding: 0.5em 1em
  // States
  &:hover
    &::after
      border-color: $input-hover-color
  // Colors
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    &.is-#{$name} select
      border-color: $color
      &:focus,
      &.is-focused,
      &:active,
      &.is-active
        box-shadow: $input-focus-box-shadow-size rgba($color, 0.25)
  // Sizes
  &.is-small
    +control-small
  &.is-medium
    +control-medium
  &.is-large
    +control-large
    +input-large
    select
      +input-large
  // Modifiers
  &.is-disabled
    &::after
      border-color: $input-disabled-color
  &.is-fullwidth
    width: 100%
    select
      width: 100%
  &.is-loading
    &::after
      +loader
      margin-top: 0
      position: absolute
      right: 0.625em
      top: 0.625em
      transform: none
    &.is-small:after
      font-size: $size-small
    &.is-medium:after
      font-size: $size-medium
    &.is-large:after
      font-size: $size-large

.file
  +unselectable
  align-items: stretch
  display: flex
  justify-content: flex-start
  position: relative
  // Colors
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)
    &.is-#{$name}
      .file-cta
        background-color: $color
        border-color: transparent
        color: $color-invert
      &:hover,
      &.is-hovered
        .file-cta
          background-color: darken($color, 2.5%)
          border-color: transparent
          color: $color-invert
      &:focus,
      &.is-focused
        .file-cta
          border-color: transparent
          box-shadow: 0 0 0.5em rgba($color, 0.25)
          color: $color-invert
      &:active,
      &.is-active
        .file-cta
          background-color: darken($color, 5%)
          border-color: transparent
          color: $color-invert
  // Sizes
  &.is-small
    font-size: $size-small
  &.is-medium
    font-size: $size-medium
    .file-icon
      .fa
        font-size: 21px
  &.is-large
    font-size: $size-large
    .file-icon
      .fa
        font-size: 28px
  // Modifiers
  &.has-name
    .file-cta
      border-bottom-right-radius: 0
      border-top-right-radius: 0
    .file-name
      border-bottom-left-radius: 0
      border-top-left-radius: 0
  &.is-centered
    justify-content: center
  &.is-right
    justify-content: flex-end
  &.is-boxed
    .file-label
      flex-direction: column
    .file-cta
      flex-direction: column
      height: auto
      padding: 1em 3em
    .file-name
      border-width: 0 1px 1px
    .file-icon
      height: 1.5em
      width: 1.5em
      .fa
        font-size: 21px
    &.is-small
      .file-icon .fa
        font-size: 14px
    &.is-medium
      .file-icon .fa
        font-size: 28px
    &.is-large
      .file-icon .fa
        font-size: 35px
    &.has-name
      .file-cta
        border-radius: $file-radius $file-radius 0 0
      .file-name
        border-radius: 0 0 $file-radius $file-radius
        border-width: 0 1px 1px
  &.is-right
    .file-cta
      border-radius: 0 $file-radius $file-radius 0
    .file-name
      border-radius: $file-radius 0 0 $file-radius
      border-width: 1px 0 1px 1px
      order: -1
  &.is-fullwidth
    .file-label
      width: 100%
    .file-name
      flex-grow: 1
      max-width: none

.file-label
  align-items: stretch
  display: flex
  cursor: pointer
  justify-content: flex-start
  overflow: hidden
  position: relative
  &:hover
    .file-cta
      background-color: darken($file-cta-background-color, 2.5%)
      color: $file-cta-hover-color
    .file-name
      border-color: darken($file-name-border-color, 2.5%)
  &:active
    .file-cta
      background-color: darken($file-cta-background-color, 5%)
      color: $file-cta-active-color
    .file-name
      border-color: darken($file-name-border-color, 5%)

.file-input
  height: 0.01em
  left: 0
  outline: none
  position: absolute
  top: 0
  width: 0.01em

.file-cta,
.file-name
  +control
  border-color: $file-border-color
  border-radius: $file-radius
  font-size: 1em
  padding-left: 1em
  padding-right: 1em
  white-space: nowrap

.file-cta
  background-color: $file-cta-background-color
  color: $file-cta-color

.file-name
  border-color: $file-name-border-color
  border-style: $file-name-border-style
  border-width: $file-name-border-width
  display: block
  max-width: $file-name-max-width
  overflow: hidden
  text-align: left
  text-overflow: ellipsis

.file-icon
  align-items: center
  display: flex
  height: 1em
  justify-content: center
  margin-right: 0.5em
  width: 1em
  .fa
    font-size: 14px

.label
  color: $label-color
  display: block
  font-size: $size-normal
  font-weight: $label-weight
  &:not(:last-child)
    margin-bottom: 0.5em
  // Sizes
  &.is-small
    font-size: $size-small
  &.is-medium
    font-size: $size-medium
  &.is-large
    font-size: $size-large

.help
  display: block
  font-size: $help-size
  margin-top: 0.25rem
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    &.is-#{$name}
      color: $color

// Containers

.field
  &:not(:last-child)
    margin-bottom: 0.75rem
  // Modifiers
  &.has-addons
    display: flex
    justify-content: flex-start
    .control
      &:not(:last-child)
        margin-right: -1px
      &:first-child
        .button,
        .input,
        .select select
          border-bottom-left-radius: $input-radius
          border-top-left-radius: $input-radius
      &:last-child
        .button,
        .input,
        .select select
          border-bottom-right-radius: $input-radius
          border-top-right-radius: $input-radius
      .button,
      .input,
      .select select
        border-radius: 0
        &:hover,
        &.is-hovered
          z-index: 2
        &:focus,
        &.is-focused,
        &:active,
        &.is-active
          z-index: 3
          &:hover
            z-index: 4
      &.is-expanded
        flex-grow: 1
    &.has-addons-centered
      justify-content: center
    &.has-addons-right
      justify-content: flex-end
    &.has-addons-fullwidth
      .control
        flex-grow: 1
        flex-shrink: 0
  &.is-grouped
    display: flex
    justify-content: flex-start
    & > .control
      flex-shrink: 0
      &:not(:last-child)
        margin-bottom: 0
        margin-right: 0.75rem
      &.is-expanded
        flex-grow: 1
        flex-shrink: 1
    &.is-grouped-centered
      justify-content: center
    &.is-grouped-right
      justify-content: flex-end
    &.is-grouped-multiline
      flex-wrap: wrap
      & > .control
        &:last-child,
        &:not(:last-child)
          margin-bottom: 0.75rem
      &:last-child
        margin-bottom: -0.75rem
      &:not(:last-child)
        margin-bottom: 0

  &.is-horizontal
    +tablet
      display: flex

.field-label
  .label
    font-size: inherit
  +mobile
    margin-bottom: 0.5rem
  +tablet
    flex-basis: 0
    flex-grow: 1
    flex-shrink: 0
    margin-right: 1.5rem
    text-align: right
    &.is-small
      font-size: $size-small
      padding-top: 0.375em
    &.is-normal
      padding-top: 0.375em
    &.is-medium
      font-size: $size-medium
      padding-top: 0.375em
    &.is-large
      font-size: $size-large
      padding-top: 0.375em

.field-body
  .field .field
    margin-bottom: 0
  +tablet
    display: flex
    flex-basis: 0
    flex-grow: 5
    flex-shrink: 1
    .field
      margin-bottom: 0
    & > .field
      flex-shrink: 1
      &:not(.is-narrow)
        flex-grow: 1
      &:not(:last-child)
        margin-right: 0.75rem

.control
  font-size: $size-normal
  position: relative
  text-align: left
  // Modifiers
  // DEPRECATED
  &.has-icon
    .icon
      color: $input-icon-color
      height: 2.25em
      pointer-events: none
      position: absolute
      top: 0
      width: 2.25em
      z-index: 4
    .input
      &:focus
        & + .icon
          color: $input-icon-active-color
      &.is-small
        & + .icon
          font-size: $size-small
      &.is-medium
        & + .icon
          font-size: $size-medium
      &.is-large
        & + .icon
          font-size: $size-large
    &:not(.has-icon-right)
      .icon
        left: 0
      .input
        padding-left: 2.25em
    &.has-icon-right
      .icon
        right: 0
      .input
        padding-right: 2.25em
  &.has-icons-left,
  &.has-icons-right
    .input,
    .select
      &:focus
        & ~ .icon
          color: $input-icon-active-color
      &.is-small ~ .icon
        font-size: $size-small
      &.is-medium ~ .icon
        font-size: $size-medium
      &.is-large ~ .icon
        font-size: $size-large
    .icon
      color: $input-icon-color
      height: 2.25em
      pointer-events: none
      position: absolute
      top: 0
      width: 2.25em
      z-index: 4
  &.has-icons-left
    .input,
    .select select
      padding-left: 2.25em
    .icon.is-left
      left: 0
  &.has-icons-right
    .input,
    .select select
      padding-right: 2.25em
    .icon.is-right
      right: 0
  &.is-loading
    &::after
      +loader
      position: absolute !important
      right: 0.625em
      top: 0.625em
    &.is-small:after
      font-size: $size-small
    &.is-medium:after
      font-size: $size-medium
    &.is-large:after
      font-size: $size-large

.is-loss-factor-styles
  position: absolute
  left: 20px

.underline-input
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
  padding: 0 0.325rem
  border-bottom: $underline-input-border-width solid $primary
  color: $primary
  width: inherit
  &:hover
    color: $teal-header
  .input
    &:hover
      color: $teal-header

  &.large-arrow-underline
    border-bottom: none !important
    background: linear-gradient(to right, transparent 5%, $primary 5%, $primary 95%, transparent 95%) 0 75% / 50% 2px no-repeat !important

  &.shorter-underline-width
    width: 4rem
  &.is-loss-factor
    position: relative
  .input
    +control
    border: none
    border-radius: 0
    background-color: transparent
    color: $primary
    padding: 0
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    cursor: inherit
    user-select: none
    -webkit-user-select: unset

  input[type="number"]
    max-width: 2.5rem
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
      -webkit-appearance: none
      margin: 0
    -moz-appearance: textfield

  .dropdown-triangle
    margin-left: 0.75rem
    +triangle-down(null, $underline-input-triangle-size, $underline-input-triangle-size)
    position: relative

    //Expands the clickable area
    &::after
      content: ''
      position: absolute
      top: -12px
      bottom: -8px
      left: -15px
      right: -15px

  .dropdown-triangle-up
    margin-bottom: 0.5rem
    margin-left: 0.75rem
    +triangle-up(null, $underline-input-triangle-size, $underline-input-triangle-size)
    position: relative

    //Expands the clickable area
    &::after
      content: ''
      position: absolute
      top: -8px
      bottom: -12px
      left: -15px
      right: -15px

  .dropdown-triangle-xlarge
    margin-bottom: 0.5rem
    margin-top: 0.2rem
    margin-left: 0.75rem
    +triangle-down(null, 10px, 11px)
    position: relative

  .dropdown-triangle-up-xlarge
    margin-bottom: 0.7rem
    margin-left: 0.75rem
    +triangle-up(null, 10px, 11px)
    position: relative

  .number-arrow-icons
    display: flex
    flex-direction: column
    margin-right: 0.25rem

    .icon:first-of-type
      -webkit-transform: rotate(180deg)
      -moz-transform: rotate(180deg)
      -o-transform: rotate(180deg)
      -ms-transform: rotate(180deg)
      transform: rotate(180deg)

  &:focus,
  &:active
    outline: none

  +mobile
    max-width: 300px

    .input
      min-height: 2.25rem
      height: auto
      white-space: pre-wrap

.underline-input.is-disabled
  pointer-events: none !important
  input[type="number"]
    text-align: center


.underline-input.has-placeholder
  .input
    min-width: 2.5rem


.underline-input.is-primary
  border-bottom: $underline-input-border-width solid $primary-invert
  &.has-dashed-border
    border: 1px dashed $primary-invert
    border-radius: $radius-small
  .input
    background-color: transparent
    color: $primary-invert

  .dropdown-triangle
    +triangle-down($active-on-primary-background, $underline-input-triangle-size, $underline-input-triangle-size)

  .dropdown-triangle-up
    +triangle-up($primary-invert, $underline-input-triangle-size, $underline-input-triangle-size)


.underline-input.is-primary.has-placeholder
  .input
    color: $unselected-on-primary-background


.underline-input.is-primary.is-large
  .dropdown-triangle
    margin-top: 0.25rem
    +triangle-down($primary-invert, $underline-input-triangle-size-large, $underline-input-triangle-size-large)

  .dropdown-triangle-up
    +triangle-up($primary-invert, $underline-input-triangle-size-large, $underline-input-triangle-size-large)


.underline-input.is-primary.is-disabled,
.underline-input.is-primary.is-inactive
  border-bottom: $underline-input-border-width solid $inactive-on-primary-background
  &.has-dashed-border
    border: 1px dashed $inactive-on-primary-background
    border-radius: $radius-small

  *
    color: $inactive-on-primary-background

  .dropdown-triangle
    +triangle-down($inactive-on-primary-background, $underline-input-triangle-size, $underline-input-triangle-size)

  .dropdown-triangle-up
    +triangle-up($inactive-on-primary-background, $underline-input-triangle-size, $underline-input-triangle-size)

.form
  background-color: $white

  .checkbox
    margin-top: 1.5rem

  .underline-input-with-label
    margin-top: 0.75rem
    display: flex
    flex-direction: column
    justify-content: flex-end
    &.first-name,
    &.last-name
      display: inline-flex
      min-width: 48%
    &.first-name
      margin-right: 2%
    &.last-name
      margin-left: 2%

  .underline-input-label
    color: rgba($primary, 0.5)
    display: block
    font-size: 0.75rem
    height: 1.25rem
    line-height: 1.25rem

  .underline-input
    border-bottom: 2px solid $primary !important
    padding: 0 0 0.75rem 0
    .input,
    .input::placeholder
      width: 100%
      font-family: $family-calibre !important
      letter-spacing: $letter-spacing-small
    .input
      color: $primary
      font-weight: $weight-semibold
    .input::placeholder
      font-weight: $weight-medium
      color: rgba($primary, 0.5)

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
      -webkit-text-fill-color: $primary
      -webkit-box-shadow: 0 0 0px 1000px $white inset

  .form-checkbox.is-small
    font-size: $size-7
    span
      a
        text-decoration: underline !important

  +mobile
    .underline-input .input
      min-height: unset
      font-size: $size-7
    .form-checkbox:not(.is-small)
      font-size: 0.9rem

.radio-control
  input
    display: none

  label
    cursor: pointer
    height: 28px
    font-size: 14px
    display: flex
    align-items: center
    &:before
      content: ''
      display: inline-block
      width: 10px
      height: 10px
      border: 1px solid $primary
      border-radius: 50%
      margin-right: 5px

    &:after
      content: ''
      width: 6px
      height: 6px
      position: absolute
      left: 2px
      top: 11px
      border-radius: 50%
      background-color: transparent

    &.is-active
      &:after
        background-color: $primary
