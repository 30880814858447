@media print
  #spacer-logo
    filter: invert(0%)

  .results

    // Removing components not in print
    .profile-hero .image,
    .profile-description-section,
    .space-summary,
    .section-title,
    .next-steps,
    .footer,
    .assumptions-controls,
    .your-details-workspace .program-bar-chart,
    .section-subtitle,
    .save-changes-button,
    .program-reset-button,
    .visible-breakdown,
    .bar-chart-to-breakdown-divider,
    .spacer-logo-for-screen,
    .number-arrow-icons,
    .navbar-end,
    .amenity-unit-adder-opener,
    .amenity-unit-adder
      display: none !important

    // Fixing overall layout
    .section .container,
    .hero-section .container
      padding-left: 1rem

    // General Color Correction
    .program-bar-chart,
    .program-hero-for-print .rsf,
    .program-hero-for-print .usf,
    .program-hero-for-print .nsf,
    .program-stats
      -webkit-print-color-adjust: exact !important
      color-adjust: exact !important

    // Header
    .navbar
      position: absolute

    // Profile Hero
    .profile-hero
      margin-top: -1.5rem
    .profile-hero-subtitle
      font-size: 0.8rem
    .profile-hero-name
      font-size: 2rem
      margin-top: -1.25rem !important
      margin-bottom: 0.5rem

    // Profile Information
    .results-profile
      .space-summary-section
        width: 100%

    // Program Hero
    .program-hero-for-print
      padding: 0
      .loss-factor
        font-size: 0.8rem
      .rsf,
      .usf,
      .nsf
        text-align: center
        background-color: rgba($background-accent, 0.4)
        margin-left: 0.5rem
        align-self: center
      .title
        margin-bottom: 0
      .label
        font-size: 0.6rem

    // Bar Chart
    .office-breakdown-barchart
      background-color: white
      margin-bottom: -4.5rem

    // Stats
    .program-stats,
    .program-stat-content
      padding: 1rem !important
      .program-stat-title
        font-size: 0.8rem
      .program-stat-value-percent
        font-size: 0.8rem
      .program-stat-value
        font-size: 1rem
      .program-stat-subtitle
        font-size: 0.5rem

    // Disclaimer
    .print-disclaimer
      position: absolute
      bottom: 5px
      font-size: 0.6rem
      color: $red


    //Details

    .your-details
      .your-details-border
        border: none

    .your-details-workspace
      border: none !important
      page-break-before: always

    .all-breakdowns-for-print

      .results-breakdown-padding
        padding: 0
      .category-title-print
        margin-top: 0
      .space-unit-breakdown-container
        margin-top: 0.5rem
      .space-category-breakdown-container
        flex-wrap: wrap
      .space-category-sidebar-column
        width: 100%
        .subtitle
          margin-bottom: 0.5rem
      .space-category-breakdown-container
        page-break-after: always
      .underline-input
        border-bottom: none
      .space-category-breakdown-row
        width: 200%
        .column
          padding: 0
        .space-category-breakdown-row-name
          font-size: 0.8rem
          width: 20%
      .space-unit-breakdown-totals
        width: 200%
        .is-9-desktop
          flex: none
          width: 75%
        .is-3-desktop
          flex: none
          width: 25%
        .loss-factor-container
          position: relative
        


