.is-firefox

  .email-wall-envelope-icon
    background-size: auto auto !important
    background-position: 100% 60% !important

  .copy-to-clipboard-box-clipboard
    background-color: $white !important
    &:hover
      background-color: rgba($grey, 0.1) !important

