$dropdown-content-background-color: $teal-dark !default
$dropdown-content-arrow: $link !default
$dropdown-content-offset: 4px !default
$dropdown-content-radius: $radius-small !default
$dropdown-content-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15) !default

$dropdown-item-color: $white !default
$dropdown-item-hover-color: $black !default
$dropdown-item-hover-background-color: $teal !default
$dropdown-item-active-color: $link-invert !default
$dropdown-item-active-background-color: $link !default

$dropdown-divider-background-color: $border !default

.dropdown
  display: inline-flex
  position: relative
  vertical-align: top
  &.is-active,
  &.is-hoverable:hover
    .dropdown-menu
      visibility: visible
  &.is-right
    .dropdown-menu
      left: auto
      right: 0
  &.is-up
    .dropdown-menu
      bottom: 100%
      padding-bottom: $dropdown-content-offset
      padding-top: unset
      top: auto
  &:focus
    outline: none
  +mobile
    position: static
    margin: 0
    .dropdown-menu
      width: 300px
    .dropdown-item
      min-height: 50px
      height: auto
      white-space: normal

.dropdown-menu
  visibility: hidden
  background-color: white
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)
  left: 0
  min-width: 5rem
  position: absolute
  top: calc(100% + #{$dropdown-content-offset})
  z-index: $dropdown-content-z
  +primary-bg-scrollbar
  +mobile
    max-width: none !important
    width: 90vw  !important
    left: 20%  !important
    margin-left: -20vw

.dropdown-content
  background-color: $dropdown-item-color
  border-radius: $dropdown-content-radius
  box-shadow: $dropdown-content-shadow
  overflow: auto

.dropdown-item
  font-family: $family-calibre
  color: $green-forest-dark
  line-height: 1.88rem
  display: block
  padding: 0.625rem 0.75rem
  position: relative
  cursor: pointer
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

  &.hover
    background-color: $green-forest-dark
    color: $dropdown-item-color
    &.is-active
      background-color: $green-forest-dark
      color: $dropdown-item-color

  &.is-active
    background-color: $dropdown-item-color
    color: $green-forest-dark
    font-weight: $weight-bold

  &:focus,
  &:active
    outline: none

  +mobile
    line-height: 1

a.dropdown-item
  padding-right: 3rem
  white-space: nowrap
  &:hover
    background-color: $dropdown-item-hover-background-color
    color: $dropdown-item-hover-color
  &.is-active
    background-color: $dropdown-item-active-background-color
    color: $dropdown-item-active-color

.dropdown-item-quiz
  font-family: $family-calibre
  color: $green-forest-dark
  line-height: 1.2rem
  display: block
  padding: 0.625rem 0.75rem
  position: relative
  cursor: pointer
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: 18px


  &.hover
    background-color: $teal-dark-new
    color: $dropdown-item-color
    &.is-active
      background-color: $teal-dark-new
      color: $dropdown-item-color

  &.is-active
    background-color: $dropdown-item-color
    color: $green-forest-dark
    font-weight: $weight-bold

  &:focus,
  &:active
    outline: none

  +mobile
    line-height: 1

a.dropdown-item-quiz
  padding-right: 3rem
  white-space: nowrap
  &:hover
    background-color: $dropdown-item-hover-background-color
    color: $dropdown-item-hover-color
  &.is-active
    background-color: $dropdown-item-active-background-color
    color: $dropdown-item-active-color

.dropdown-divider
  background-color: $dropdown-divider-background-color
  border: none
  display: block
  height: 1px
  margin: 0.5rem 0