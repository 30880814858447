$button-color: $grey-darker !default
$button-background-color: $white !default
$button-border-color: $grey-lighter !default

$button-hover-color: $link-hover !default
$button-hover-border-color: $link-hover-border !default

$button-focus-border-color: $link-focus-border !default
$button-focus-box-shadow-size: 0 0 0 0.125em !default
$button-focus-box-shadow-color: rgba($link, 0.25) !default

$button-active-color: $link-active !default
$button-active-border-color: $link-active-border !default

$button-text-color: $text !default
$button-text-hover-background-color: $background !default

$button-disabled-background-color: $white !default
$button-disabled-border-color: $grey-lighter !default
$button-disabled-shadow: none !default
$button-disabled-opacity: 0.5 !default

$button-static-color: $grey !default
$button-static-background-color: $white-ter !default
$button-static-border-color: $grey-lighter !default

$button-min-width-narrow: 130px !default
$button-min-width-wide: 160px !default

// The button sizes use mixins so they can be used at different breakpoints
=button-small
  border-radius: $radius-small
  font-size: $size-small
  line-height: $size-small
=button-medium
  font-size: $size-medium
=button-large
  font-size: $size-large

.button
  +control
  +unselectable
  background-color: $button-background-color
  border-color: $button-border-color
  color: $button-color
  cursor: pointer
  border-radius: $radius !important
  font-family: $family-sans-serif-semibold
  justify-content: center
  padding-left: 0.75em
  padding-right: 0.75em
  text-align: center
  white-space: nowrap
  line-height: 1
  transition: all $standard-transition
  strong
    color: inherit
  .icon
    &:first-child:not(:last-child)
      margin-left: calc(-0.375em - 1px)
      margin-right: 0.1875em
    &:last-child:not(:first-child)
      margin-left: 0.1875em
      margin-right: calc(-0.375em - 1px)
    &:first-child:last-child
      margin-left: calc(-0.375em - 1px)
      margin-right: calc(-0.375em - 1px)

  &.has-icon-after::after
    padding-left: 0.75em
    line-height: 1.5

  // States
  &:hover,
  &.is-hovered
    border-color: $button-hover-border-color
    color: $button-hover-color
  &:focus,
  &.is-focused
    &:not(.no-focus-style)
      border-color: $button-focus-border-color
      &:not(:active)
        box-shadow: $button-focus-box-shadow-size $button-focus-box-shadow-color
  &:active,
  &.is-active
    border-color: $button-active-border-color
    color: $button-active-color

  &.is-text
    background-color: transparent
    border-color: transparent
    color: $button-text-color
    text-decoration: underline
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused
      background-color: $button-text-hover-background-color
    &:active,
    &.is-active
      background-color: darken($button-text-hover-background-color, 5%)
    &[disabled]
      background-color: transparent
      border-color: transparent
      box-shadow: none
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)
    &.is-#{$name}
      background-color: $color
      border-color: transparent
      color: $color-invert
      &:hover,
      &.is-hovered
        background-color: darken($color, 2.5%)
        border-color: transparent
        color: $color-invert
      &:focus,
      &.is-focused
        &:not(.no-focus-style)
          border-color: transparent
          color: $color-invert
          &:not(:active)
            box-shadow: $button-focus-box-shadow-size rgba($color, 0.25)
      &:active,
      &.is-active
        background-color: darken($color, 5%)
        border-color: transparent
        color: $color-invert
      &[disabled]
        background-color: $color
        border-color: transparent
        box-shadow: none
      &.is-inverted
        background-color: $color-invert
        color: $color
        &:hover
          background-color: darken($color-invert, 5%)
        &[disabled]
          background-color: $color-invert
          border-color: transparent
          box-shadow: none
          color: $color
      &.is-loading
        &:after
          border-color: transparent transparent $color-invert $color-invert !important
      &.is-outlined
        background-color: transparent
        border-color: $color
        color: $color
        &:hover,
        &:focus
          background-color: $color
          border-color: $color
          color: $color-invert
        &.is-loading
          &:after
            border-color: transparent transparent $color $color !important
        &[disabled]
          background-color: transparent
          border-color: $color
          box-shadow: none
          color: $color
      &.is-inverted.is-outlined
        background-color: transparent
        border-color: $color-invert
        color: $color-invert
        &:hover,
        &:focus
          background-color: $color-invert
          color: $color
        &[disabled]
          background-color: transparent
          border-color: $color-invert
          box-shadow: none
          color: $color-invert

  // Sizes
  &.is-small
    +button-small
  &.is-medium
    +button-medium
  &.is-large
    +button-large

  // Modifiers
  &[disabled]
    background-color: $button-disabled-background-color
    border-color: $button-disabled-border-color
    box-shadow: $button-disabled-shadow
    opacity: $button-disabled-opacity
  &.is-fullwidth
    display: flex
    width: 100%
  &.is-loading
    color: transparent !important
    pointer-events: none
    &:after
      +loader
      +center(1em)
      position: absolute !important
  &.is-static
    background-color: $button-static-background-color
    border-color: $button-static-border-color
    color: $button-static-color
    box-shadow: none
    pointer-events: none

  &.is-primary-filled
    background-color: $primary-new
    border-color: transparent
    border-radius: $radius-small
    color: $white
    &:hover,
    &.success
      background-color: $primary-hover
      border-color: transparent
      border-radius: $radius-small
    &:focus,
    &:active
      color: $white
    &.has-dark-success-color
      background-color: $green-forest-dark
      &:hover
        background-color: $teal-button  
        border-radius: 5px
    &.has-save-icon:after
      content: url('/public/svg/icons/save-white-icon-spacer-by-cbre.svg')
    &.has-checkmark-icon-before:before
      content: url('/public/svg/icons/check-mark-teal-icon-in-white-circle-spacer-by-cbre.svg')
      margin-right: 0.5rem

  &.is-primary-outlined
    background-color: transparent
    border-color: $primary
    border-width: 2px
    color: $primary
    &:hover
      border-color: $primary-hover
      color: $primary-hover

  &.is-tertiary-filled
    background-color: $tertiary
    border-color: transparent
    border-radius: $radius-small
    color: $white
    &:hover
      background-color: $teal-dark-new
      border-color: transparent
      border-radius: $radius-small
      color: $white
    &.is-disabled
      opacity: 0.2
    &:focus
      color: $white

  &.is-tertiary-filled-modal
    background-color: $green-forest-dark
    border-color: transparent
    border-radius: $radius-small
    color: $white
    &:hover
      background-color: $teal-button
      border-color: transparent
      border-radius: $radius-small
      color: $white
    &.is-disabled
      opacity: 0.2
    &:focus
      color: $white

  &.is-tertiary-outlined
    background-color: transparent
    border-color: $green-forest-dark
    border-radius: $radius-small
    border-width: 2px
    color: $white
    &:hover
      border-color: $tertiary-hover
      color: $white
    &:disabled,
    &.is-disabled
      opacity: 0.2
    &:focus
      color: $white
    &.has-tertiary-text
      color: $green-forest-dark
      &:hover
        color: $tertiary-hover
    &.is-filled-on-hover
      &:hover
        background-color: $tertiary
        border-color: $tertiary
        color: $white

  &.is-tertiary-outlined-modal
    background-color: transparent
    border-color: $green-forest-dark
    border-radius: $radius-small
    border-width: 2px
    color: $white
    &:hover
      border-color: $teal-button
      color: $white
    &:disabled,
    &.is-disabled
      opacity: 0.2
    &:focus
      color: $white
    &.has-tertiary-text
      color: $green-forest-dark
      &:hover
        color: $teal-button
    &.is-filled-on-hover
      &:hover
        background-color: $tertiary
        border-color: $tertiary
        color: $white

  &.has-min-width-narrow
    min-width: $button-min-width-narrow

  &.has-min-width-wide
    min-width: $button-min-width-wide

  &.back-button
    background-image: url('/public/svg/icons/back-arrow-white-icon-spacer-by-cbre.svg')
    background-position: 8px
    background-repeat: no-repeat
    background-size: 12px 12px
    padding-left: 1.5rem
     
  &.flooredPlanButton
     border-radius: 5px
     width: 500px
     height: 45px
     margin-bottom: 60px
   
     

.button-pair
  display: flex
  flex-wrap: wrap
  .button
    min-width: 45%
    margin-top: 0.2rem
    &:first-of-type
      margin-right: 10%

    +mobile
      font-size: $size-7
      &:first-of-type
        margin-right: 1rem
