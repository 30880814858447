.hero
  white-space: pre-wrap // to get to force a linebreak with \n

.about-page-content-illustration
  max-height: 800px

.generic-info-page-image
  +from($tablet)
    position: sticky
    top: 25%

.terms-of-service
  padding-left: $size-1
  padding-right: $size-1
  padding-bottom: $size-1

  .main-list > li
    margin: $size-6 0 0 $size-6
    padding-left: $size-6
    font-weight: $weight-bold

    ol,
    div
      font-weight: normal
      padding-left: $size-4
      li
        padding-left: $size-6

.hero-body .title
  margin-bottom: 0rem
  width: 75%
  +mobile
    width: 100%
    font-size: 30px

.landing-page,
.about-page,
.blog-article
  .cta-button
    padding: $size-4 $size-3

.landing-page

  .hero-question
    .question-text
      font-weight: 400
      line-height: 1.5
      font-family: $family-calibre
      margin-bottom: 0
      font-size: 1rem
      padding-top: $size-5
      padding-left: $size-3
    .radio-spectrum-container
      margin-top: 0


    .radio-spectrum
      font-size: 15px
      font-weight: 500
      font-family: $family-sans-serif-medium
      color: $black
      padding-left: 1.75rem
      &:before
        border: 2px solid $black
        background-color: $pale-green
        height: 21px
        width: 21px
        left: 50%
        top: 8px
      &:after
        border-top: 2px solid $black
      &:hover
        font-weight: inherit
        +from($tablet)
          &:before
            background-color: $teal-header
      &:active,
      &.is-active
        &:before
          border-color: $teal-header
          background-color: $teal-header




  .steps-summary
    margin-right: 0
    .image
      height: 158px
      width: 158px

  .secondary-hero
    .column
      padding: 0
    .title
      font-size: 2.3rem

  +mobile
    .hero-body
      padding-top: 1rem

      .subtitle
        margin-bottom: 0

    .hero-question
      .radio-spectrum
        padding-left: calc(1em + #{$radio-spectrum-input-size})
        padding-top: 1.5rem
        min-height: 3rem
        font-size: 16px
        color: $primary

        &:before
          border: 1px solid $black
          height: 26px
          width: 26px
          left: 8px
          top: 50%

        &:not(:last-child):after
          left: 20px
          border-top: none
          border-left: 1px solid $black
          width: 100%

      .question-text
        padding-left: 0
        font-size: 20px

      .question-answer
        padding-left: 1rem

    .secondary-hero .title
      font-size: 30px

    .cta-button
      padding: $size-6 2rem

.about-page-video
  width: 100%
  max-width: 750px

.iframe-container
  overflow: hidden
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%
  position: relative

  iframe
    border: 0
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%

.video-thumbnail
  borderRadius: 4
  boxShadow: 0px 4px 5px 1px #ccc
  objectFit: cover
