.program-bar-chart
  padding-bottom: $size-3
  padding-top: $size-3

  .title, .subtitle
    font-family: $family-sans-serif-semibold

  .program-bar-chart-segment-amount, .program-bar-chart-segment-percentage, .program-bar-chart-segment-subtitle
    white-space: nowrap

  .program-bar-chart-segment-percentage
    font-size: 2rem
    +mobile
      font-size: 1.8rem

  .program-bar-chart-segment-amount
    margin-bottom: 0.6rem


  .program-bar-chart-bar
    height: $size-3
    width: 100%

  .program-bar-chart-segment-subtitle
    max-width: 150px
    margin-left: auto
    margin-right: auto
    margin-top: 2rem
    overflow: hidden
    text-overflow: ellipsis

  .program-bar-chart-segment-amount,
  .program-bar-chart-segment-explanation
    font-weight: 100

  .group-space-small
    margin-left: 15px

  .group-space-xs
    margin-left: 35px

  .not-group-space
    margin-left: 40px

  .not-group-space-small
    margin-right: 15px

  .not-group-space-xs
    margin-right: 35px

.program-bar-chart-empty
  background-color: #ecf5f1
  border: 1px solid #aab7b2
  padding: 0 25%
  display: flex
  align-items: center
  margin: 1rem 0
  justify-content: center

