.space-unit-breakdown-container
  margin-bottom: 2.5rem
  +until($fullhd)
    width: 100% !important

.sidebar-description
  font-size: 0.8rem
  width: 80%

.space-unit-breakdown-totals
  align-items: flex-end
  margin-top: 1.5rem

  .grand-total
    font-size: 1.3rem

.open-amenity-adder
  +plus($amenity, transparent, true, rgba($amenity, 0.2))
  margin-right: 0.25rem

.space-unit-row .delete-unit-row
  +delete($black, transparent, false, rgba($black, 0.2))
  margin: 0 2px

.amenity-unit-adder .space-category-breakdown-row
  border: 1px solid $tertiary

  .grand-total, .space-category-quantity .underline-input input, .space-category-area .underline-input input
    color: $tertiary !important

  .underline-input
    border-bottom-color: $tertiary !important

    .dropdown-triangle
      border-top-color: $tertiary
    .dropdown-triangle-up
      border-bottom-color: $tertiary

  .delete-unit-row
    +delete($primary, transparent, false, rgba($primary, 0.2))
    margin: 0 2px

.space-category-breakdown-row
  align-items: center
  color: $black
  font-family: $family-sans-serif-medium
  font-size: 1rem

  .add-icon
    background-color: $tertiary
    background-image: url('/public/svg/icons/checkmark-white-icon-spacer-by-cbre.svg')
    background-position: center
    background-repeat: no-repeat
    border-radius: 50%
    width: 1.2rem
    height: 1.2rem
    padding: 0.6em

    &.is-inactive
      background-color: rgba($tertiary, 0.3)
      pointer-events: none

    &:hover:not(.is-inactive)
      background-color: $teal

  .space-category-breakdown-input-container
    display: flex
    justify-content: space-around
    align-items: center

    .underline-input
      width: 3.5rem

    color: $black
    font-family: $family-sans-serif-medium
    font-size: 1rem

  .space-category-units-container
    display: flex
    align-items: center

    .space-category-unit
      margin-left: 0.5rem
      overflow: hidden
      text-overflow: ellipsis
      width: 71px


      .underline-input
        width: 100%

  .underline-input
    position: relative

    .number-arrow-icons
      position: absolute
      right: 0.25rem
      bottom: 0.25rem

@each $name, $color in $spaceTypeColors
  .accent-text-#{$name}
    .grand-total, .space-category-quantity .underline-input input, .space-category-area .underline-input input, .space-type-color-input input, .space-type-color-text
      color: $color !important

    .underline-input
      border-bottom-color: $color !important

      .dropdown-triangle
        border-top-color: $color
      .dropdown-triangle-up
        border-bottom-color: $color

.space-unit-breakdown-totals,
.space-category-breakdown-row

  .space-category-breakdown-row-name
    display: flex
    justify-content: space-between
    align-items: center
    word-break: break-word

    .name
      width: 90%

    input
      color: $black
      font-family: $family-sans-serif-medium
      font-size: 1rem
      &::placeholder
        color: rgba($tertiary, 0.4)

    .dropdown-content
      max-height: 333px

  .program-delta-label
    font-size: 0.7rem
    position: absolute

+mobile
  .space-unit-breakdown-container
      margin-top: 0

  .space-category-breakdown-row

    .column
      padding-bottom: 0

    .grand-total
      display: inline

    .program-delta-label
      position: relative
      padding-left: 0.5rem
      display: block

  .total-unit
    margin-left: 0.5rem

  .space-category-mobile-summary
    background-color: rgba($grey-lighter, 0.1)

  .space-category-button
    background-size: $size-6 $size-6
    background-position: 100% 50%
    background-repeat: no-repeat
    background-color: transparent
    border: none
    outline: none
    margin-left: auto
    margin-right: 1rem
    padding-left: 1rem

  .space-category-button-me
    background-image: url('/public/svg/icons/down-arrow-green-icon-spacer-by-cbre.svg')

  .space-category-button-we
    background-image: url('/public/svg/icons/down-arrow-blue-icon-spacer-by-cbre.svg')

  .space-category-button-amenity
    background-image: url('/public/svg/icons/down-arrow-purple-icon-spacer-by-cbre.svg')

  .small-title
    font-size: 0.8rem

  .space-category-section-content
    font-size: $size-7

  .space-category-x
    padding-bottom: 0.1rem !important

  .custom-x-style
    width: 12%

