$tabs-border-bottom-color: $border !default
$tabs-border-bottom-style: solid !default
$tabs-border-bottom-width: 1px !default
$tabs-link-color: $text !default
$tabs-link-hover-border-bottom-color: $text-strong !default
$tabs-link-hover-color: $text-strong !default
$tabs-link-active-border-bottom-color: $link !default
$tabs-link-active-color: $link !default
$tabs-link-padding: 0.5em 1em !default

$tabs-boxed-link-radius: $radius !default
$tabs-boxed-link-hover-background-color: $background !default
$tabs-boxed-link-hover-border-bottom-color: $border !default

$tabs-boxed-link-active-background-color: $white !default
$tabs-boxed-link-active-border-color: $border !default
$tabs-boxed-link-active-border-bottom-color: transparent !default

$tabs-toggle-link-border-color: $border !default
$tabs-toggle-link-border-style: solid !default
$tabs-toggle-link-border-width: 1px !default
$tabs-toggle-link-hover-background-color: $background !default
$tabs-toggle-link-hover-border-color: $border-hover !default
$tabs-toggle-link-radius: $radius !default
$tabs-toggle-link-active-background-color: $link !default
$tabs-toggle-link-active-border-color: $link !default
$tabs-toggle-link-active-color: $link-invert !default

.tabs
  +block
  +overflow-touch
  +unselectable
  align-items: stretch
  display: flex
  font-size: $size-normal
  justify-content: space-between
  overflow: hidden
  overflow-x: auto
  white-space: nowrap
  a
    align-items: center
    border-bottom-color: $tabs-border-bottom-color
    border-bottom-style: $tabs-border-bottom-style
    border-bottom-width: $tabs-border-bottom-width
    color: $tabs-link-color
    display: flex
    justify-content: center
    margin-bottom: -#{$tabs-border-bottom-width}
    padding: $tabs-link-padding
    vertical-align: top
    &:hover
      border-bottom-color: $tabs-link-hover-border-bottom-color
      color: $tabs-link-hover-color
  li
    display: block
    &.is-active
      a
        border-bottom-color: $tabs-link-active-border-bottom-color
        color: $tabs-link-active-color
  ul
    align-items: center
    border-bottom-color: $tabs-border-bottom-color
    border-bottom-style: $tabs-border-bottom-style
    border-bottom-width: $tabs-border-bottom-width
    display: flex
    flex-grow: 1
    flex-shrink: 0
    justify-content: flex-start
    &.is-left
      padding-right: 0.75em
    &.is-center
      flex: none
      justify-content: center
      padding-left: 0.75em
      padding-right: 0.75em
    &.is-right
      justify-content: flex-end
      padding-left: 0.75em
  .icon
    &:first-child
      margin-right: 0.5em
    &:last-child
      margin-left: 0.5em
  // Alignment
  &.is-centered
    ul
      justify-content: center
  &.is-right
    ul
      justify-content: flex-end
  // Styles
  &.is-boxed
    a
      border: 1px solid transparent
      border-radius: $tabs-boxed-link-radius $tabs-boxed-link-radius 0 0
      &:hover
        background-color: $tabs-boxed-link-hover-background-color
        border-bottom-color: $tabs-boxed-link-hover-border-bottom-color
    li
      &.is-active
        a
          background-color: $tabs-boxed-link-active-background-color
          border-color: $tabs-boxed-link-active-border-color
          border-bottom-color: $tabs-boxed-link-active-border-bottom-color !important
  &.is-fullwidth
    li
      flex-grow: 1
      flex-shrink: 0
  &.is-toggle
    a
      border-color: $tabs-toggle-link-border-color
      border-style: $tabs-toggle-link-border-style
      border-width: $tabs-toggle-link-border-width
      margin-bottom: 0
      position: relative
      &:hover
        background-color: $tabs-toggle-link-hover-background-color
        border-color: $tabs-toggle-link-hover-border-color
        z-index: 2
    li
      & + li
        margin-left: -#{$tabs-toggle-link-border-width}
      &:first-child a
        border-radius: $tabs-toggle-link-radius 0 0 $tabs-toggle-link-radius
      &:last-child a
        border-radius: 0 $tabs-toggle-link-radius $tabs-toggle-link-radius 0
      &.is-active
        a
          background-color: $tabs-toggle-link-active-background-color
          border-color: $tabs-toggle-link-active-border-color
          color: $tabs-toggle-link-active-color
          z-index: 1
    ul
      border-bottom: none
  // Sizes
  &.is-small
    font-size: $size-small
  &.is-medium
    font-size: $size-medium
  &.is-large
    font-size: $size-large
