.is-ie

  .email-wall-envelope-icon
    background-size: auto !important
    background-position: 100% 80% !important

  .email-wall-checkbox
    border-radius: 0 !important
    outline-style: solid !important
    outline-width: 1px !important
    outline-color: $pale-green !important

  .copy-to-clipboard-box-clipboard
    background-color: $white !important
    &:hover
      background-color: rgba($grey, 0.1) !important

  .slider-custom
    height: 35px !important
    width: 587px !important
    cursor: pointer !important //for some reason cursor pointer is not working in just the thumb
    border-color: transparent !important
    border-width: 35px 0 !important
    color: transparent !important
    background: transparent !important
    opacity: 1 !important
    outline: none !important
    padding: 0 !important
    border: none !important
    border-radius: 0 !important

    &::-ms-fill-lower
      background: $blue-light !important
      border: 5px solid $blue-light !important

    &::-ms-fill-upper
      background: $blue-light !important
      border: 5px solid $blue-light !important

    &::-ms-thumb
      border: none !important
      height: 35px !important
      width: 35px !important
      background: url('/public/svg/form-elements/headcount-slider-icon-spacer-by-cbre.svg') !important

    &:focus::-ms-fill-lower
      background: $blue-light !important

    &:focus::-ms-fill-upper
      background: $blue-light !important

  .slider-text
    padding-top: 5px !important

  .slider-right-text
    padding-left: 6%

  .quiz
    // IE cant handle vertically centering flex items
    // for now we just add a little padding or set heights to push elements down
    .quiz-exit-slide
      padding-top: $navbar-height !important
      padding-bottom: calc(#{$navbar-height} / 2) !important

    .spectrum-question
      .radio-spectrum-container
        margin-top: 7% !important
      .question-answer
        height: 13rem !important

    .grouped-questions
      min-height: 22rem !important

    .question-answer-helper-text
      margin-top: $size-7 !important

  .quiz-content > .column
    margin-top: auto !important
    width: 55% !important

  .quiz-question
    .tags
      margin-top: -5rem

  .space-category-units-container

    .space-category-unit
        margin-left: 1rem !important

  .profile-summary
    padding-bottom: 8.5rem !important

  .profile-traits
    flex: 0 0 auto !important

  .days-per-week-assumption-separator
    margin-right: 0 !important

  .days-per-week-assumption-text
    margin-left: 1rem !important

  .collaboration-assumption-separator
    padding-left: 0.1rem !important

  ::-ms-clear
    display: none

  .referral-sign-up .form-header span
    width: 18.4rem !important

.clipboard-copy-btn
  width: 300px !important

.sentence-complete-answer .dropdown-menu
  right: auto
  left: 0

.cbre-spacer-logo
  font-size: 32px
  font-weight: 500
  color: $green-forest-dark
  &:hover
    color: $green-forest-dark !important
  &:focus
    background-color: none

.navbar.is-tertiary .cbre-spacer-logo
  &:hover
    color: white !important