$lng-selector-dropdown-active-background: #c3d4cf
$lng-selector-active-item: #a8beb7
$lng-selector-dropdown-hover: #bdcdc8
$lng-selector-dropdown-item: #004b35
$lng-selector-underline-input: #adadad
$dropdown-item-color: $white !default

.footer
  background-color: $pale-green-new
  color: $primary
  font-family: $family-sans-serif-semibold
  height: 518px
  padding: $size-3 $size-1

  .footer-header
    color: $grey-darker
    font-family: $family-sans-serif
    height: 25px

  .footer-button,
  .footer-link
    color: $primary
    padding: 0.3rem 0
    display: block
    transition: color $standard-transition
    &:hover
      color: $primary-hover
    &:focus
      outline: none

  .footer-cookie-link
    color: $primary !important
    padding: 0.3rem 0 !important
    display: block !important
    font-size: 0.75rem !important
    transition: color $standard-transition !important
    border: none !important
    &:hover
      color: $primary-hover !important
      background: none !important
    &:focus
      outline: none !important

  .dropdown
    position: relative

  .dropdown.is-active
    .underline-input
      background-color: $lng-selector-dropdown-active-background
    .dropdown-button-wrapper
      outline: none

  .dropdown-content
    background-color: $dropdown-item-color

  .dropdown-menu
    left: 0 !important
    margin-left: 0

  .dropdown-item
    font-family: $family-sans-serif !important
    padding: 0 0.3rem
    color: $green-forest-dark
    &.hover
      background-color: $green-forest-dark
      color: $dropdown-item-color
      &.is-active
        background-color: $green-forest-dark
        color: $dropdown-item-color
    &.is-active
      background-color: $dropdown-item-color
      color: $green-forest-dark
      font-weight: $weight-bold

  .underline-input
    border-bottom: 1px solid $lng-selector-underline-input
    padding: 0.3rem
    &:hover
      background-color: $lng-selector-dropdown-active-background

  .dropdown-triangle, .dropdown-triangle-up
    margin: 0
    margin-left: 0.5rem
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    &:after
      all: initial

  .dropdown-triangle
    border-top: 5px solid

  .dropdown-triangle-up
    border-bottom: 5px solid

  +mobile
    height: auto
    padding: $size-4

    .social-media-icons
      margin-top: $size-6

    .terms-and-privacy-footer-section
      margin-top: -40px

    .dropdown-item
      min-height: 0
      line-height: 1.75rem

    .dropdown-menu
      top: auto
      max-height: none
      width: 90px !important
      bottom: calc(100% + 4px)

  +tablet-only
    height: auto
  
.footer .dropdown-menu, .footer .dropdown-content
  max-height: 150px !important