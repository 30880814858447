.example-plans-preview
  background-color: $light-gray
  padding: $size-3 0

.example-plan-column
  text-align: center

  .tooltip
    padding-right: 0
    margin-top: 1rem

.example-plan-image
  width: 60%

.example-plan-label
  margin-top: 1rem
  margin-bottom: 2rem
  font-size: $size-6
  color: $grey-darker

.example-plan-button
  height: 3rem