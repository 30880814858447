$modal-background-background-color: rgba($black, 0.4) !default

$modal-content-width: 640px !default
$modal-content-width-wide: 900px !default
$modal-content-padding: 2.5rem !default
$modal-content-margin-mobile: 20px !default
$modal-content-spacing-mobile: 160px !default
$modal-content-spacing-tablet: 40px !default
$modal-content-background-color: $white !default

$modal-close-dimensions: 40px !default
$modal-close-right: 2rem !default
$modal-close-top: 2rem !default

$modal-card-spacing: 40px !default

$modal-card-head-background-color: $background !default
$modal-card-head-border-bottom: 1px solid $border !default
$modal-card-head-padding: 20px !default
$modal-card-head-radius: $radius-large !default

$modal-card-title-color: $text-strong !default
$modal-card-title-line-height: 1 !default
$modal-card-title-size: $size-4 !default

$modal-card-foot-radius: $radius-large !default
$modal-card-foot-border-top: 1px solid $border !default

$modal-card-body-background-color: $white !default
$modal-card-body-padding: 20px !default

.modal
  +overlay
  align-items: center
  display: none
  justify-content: center
  overflow: hidden
  position: fixed
  z-index: $modal-z
  // Modifiers
  &.is-active
    display: flex

.modal-background
  +overlay
  background-color: $modal-background-background-color

.modal-content
  padding: $modal-content-padding

.modal-content,
.modal-card
  background-color: $modal-content-background-color
  margin: 0 $modal-content-margin-mobile
  max-height: calc(100vh - #{$modal-content-spacing-mobile})
  overflow: auto
  position: relative
  min-width: 325px
  min-height: 200px
  max-width: $modal-content-width
  button[type="submit"]
    margin-bottom: 1rem

  // Responsiveness
  +tablet
    max-height: calc(100vh - #{$modal-content-spacing-tablet})

    &.is-wide
      max-width: $modal-content-width-wide

.modal-close
  +delete($primary, transparent, false, rgba($primary, 0.2))
  height: $modal-close-dimensions
  position: absolute
  right: $modal-close-right
  top: $modal-close-top
  width: $modal-close-dimensions

.modal-card
  display: flex
  flex-direction: column
  max-height: calc(100vh - #{$modal-card-spacing})
  overflow: hidden

.modal-card-head,
.modal-card-foot
  align-items: center
  background-color: $modal-card-head-background-color
  display: flex
  flex-shrink: 0
  justify-content: flex-start
  padding: $modal-card-head-padding
  position: relative

.modal-card-head
  border-bottom: $modal-card-head-border-bottom
  border-top-left-radius: $modal-card-head-radius
  border-top-right-radius: $modal-card-head-radius

.modal-card-title
  color: $modal-card-title-color
  flex-grow: 1
  flex-shrink: 0
  font-size: $modal-card-title-size
  line-height: $modal-card-title-line-height

.modal-card-foot
  border-bottom-left-radius: $modal-card-foot-radius
  border-bottom-right-radius: $modal-card-foot-radius
  border-top: $modal-card-foot-border-top
  .button
    &:not(:last-child)
      margin-right: 10px

.modal-card-body
  +overflow-touch
  background-color: $modal-card-body-background-color
  flex-grow: 1
  flex-shrink: 1
  overflow: auto
  padding: $modal-card-body-padding

.success-modal
  // hacky way to make this modal cover up the close button
  position: absolute
  text-align: center
  padding: $modal-content-padding
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: $modal-content-background-color
  z-index: calc(#{$modal-z} + 10)

+mobile
  .modal
    align-items: unset

  .modal-content
    max-width: none
    max-height: none
    padding: 2.5rem 1.5rem
    margin: 0

