$email-wall-input-border-bottom: 2px solid $grey-light

.email-wall-section
  background-color: #F5F7F7

  .email-wall-section-title
    font-size: $size-3
    font-family: $family-calibre
    font-stretch: normal
    letter-spacing: 0.5px
    color: $green-forest-dark
    display: block
    padding-bottom: $size-8

  .email-wall-text
    font-family: $family-calibre
    font-weight: $weight-semibold
    letter-spacing: $letter-spacing-medium
    color: $grey-dark

  .email-wall-section-subtitle
    width: 80%
    +mobile
      width: 100%

  .email-wall-label
    color: $grey-light
    font-size: $size-normal
    margin-top: 1.5rem

  .email-wall-section-input
    border-style: none
    border-bottom: $email-wall-input-border-bottom
    background-color: transparent
    width: 80%
    outline: none
    font-size: $size-4
    overflow: hidden
    text-overflow: ellipsis
    box-shadow: none
    border-radius: 0

    &:read-only
      color: $grey-light

    +mobile
      width: 15rem

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
    -webkit-text-fill-color: $grey-dark
    box-shadow: 0 0 0px 1000px $grey-lightest inset
    border-bottom: $email-wall-input-border-bottom

  .email-wall-name-input-container
    display: flex
    +mobile
      flex-direction: column

  .email-wall-envelope-icon
    background-image: url('/public/svg/icons/email-envelope-green-icon-spacer-by-cbre.svg')
    background-size: $size-4 $size-3
    background-position: 100% 0%
    background-repeat: no-repeat
    height: 1.9rem
    width: $size-4
    padding: $size-5
    border-bottom: $email-wall-input-border-bottom

  .email-wall-name
    width: 42%
    &:first-of-type
      margin-right: 1rem

    .email-wall-section-input
      width: 100%

  .email-wall-checkbox-container
    display: flex
    flex-direction: row
    width: 100%
    margin-top: $size-8
    margin-left: -0.5rem

  .email-wall-checkbox-label
    width: 90%
    font-size: $size-7
    +mobile
      width: 100%
    span
      a
        text-decoration: underline !important

  .email-wall-contact-notice
    width: 90%
    font-size: $size-7
    margin: $size-6 0 0 $size-4
    +mobile
      width: 100%

  .email-wall-checkbox
    margin: $size-8 $size-8 0 $size-8
    cursor: pointer
    vertical-align: middle
    background-color: $white
    user-select: none
    appearance: none
    width: 1.125rem
    height: 1.125rem
    border-radius: $radius
    border: solid 1px $pale-green
    outline: none
    &::-ms-check
      display: none
    &:checked
      background: url('/public/svg/icons/checkmark-forest-green-icon-spacer-by-cbre.svg') no-repeat
      background-position: center
    +mobile
      margin-top: $size-6

  .email-wall-submit-button
    min-width: 11.81rem
    height: 3.125rem
    margin-top: 2rem
    color: $white
    background-color: $green-forest-dark
    border: none
    &:hover
      background-color: $teal-button

+mobile
  .email-wall-name
    width: 100% !important
