$hbar-background-color: $white-ter !default
$hbar-segment-background-color: $secondary !default
$hbar-value-text-color: $text-invert !default
$hbar-placeholder-background-color: $grey-lighter !default

.hbar
  +block
  display: flex
  height: $size-4
  font-size: $size-6
  line-height: $size-4
  background-color: $hbar-background-color
  border-radius: $size-7
  overflow: hidden

  &.is-radiusless
    overflow: visible
  // Sizes
  &.is-small
    height: $size-5
    font-size: $size-7
    line-height: $size-5
    border-radius: $size-8
  &.is-large
    height: 11rem
    border-radius: 1.5rem
    font-size: 4rem
    .hbar-segment
      display: flex
      align-items: center
      justify-content: space-evenly
    .hbar-value
      text-align: center
      padding: 0


.hbar-segment
  background-color: $hbar-segment-background-color
  color: $hbar-value-text-color
  &.is-placeholder
    background-color: $hbar-placeholder-background-color
    border-color: $white
    border-width: 0 2px
    border-style: solid
    &:first-child
      border-left: 0
    &:last-child
      border-right: 0
  // Colors
  @each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)
    &.is-#{$name}
      background-color: $color
      color: $color-invert
.is-placeholder + .is-placeholder
  border-left: 0
.hbar-value
  text-align: right
  padding-right: 0.5rem
  cursor: default